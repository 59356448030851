import axiosRetry from 'axios-retry';
import config from './config.api';
import { axiosWithHeaders, getService } from '@innovyze/lib_get_service';
import { formatUrlTrailing } from '@Utils/urlFormatting';
import { getApiEnvironment } from '@innovyze/stylovyze';

const riskManagementUrl = formatUrlTrailing(
	getService('amRisk', config, getApiEnvironment()),
);
const service = axiosWithHeaders({
	service: 'amRisk',
	config,
	axiosCreateOpts: {
		baseURL: riskManagementUrl,
		timeout: 10000,
	},
});

axiosRetry(service, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
});

export default service;
