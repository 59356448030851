import { FeatureItem, ItemDetailsSelector } from '@Utils/types';

import { useSelector } from 'react-redux';

type UseItemDetailsItem = FeatureItem | null | undefined;
export const useItemDetails = <T extends UseItemDetailsItem>(
	item: T,
	getItemDetailsSelector?: ItemDetailsSelector,
): T => {
	const extraDetails = useSelector(state =>
		getItemDetailsSelector?.(state, item?.id ?? null),
	);

	if (extraDetails) {
		return { ...item, ...extraDetails };
	}
	return item;
};
