import { InputText } from '../../../components';
import { FormControl } from '@mui/material';
import { useGlobalization } from '../../../contexts';
import {
	CurveManagerDialogs,
	CurveManagerForm,
	CurveType,
} from '../../../types/curveManager.types';
import { FormikProps } from 'formik';
import React from 'react';
import { CurveDrawerForm } from './CurveManagerDrawerForm.styles';
import { TFunc } from '../../../types/i18nextType';
import { TFunction } from 'i18next';
import { FieldDropDown } from './components/FieldDropDown';
import { FormLayoutRow, FormLayoutColumn } from './components/FormLayout';
import { SENSOR_TYPE_UNITS_MAP } from '@innovyze/shared-utils';
import { useIsFeatureEnabled } from '../../../utils';
import { useSelectCurveManager } from '../../../selectors';

interface FieldDropDownOption<Value extends string | number = string> {
	value: Value;
	label: string;
}

const getCurveInputName = (t: TFunc, curveType?: CurveType) => {
	if (curveType === CurveType.PRESSURE_CURVE) {
		return t('Pump Curve Name');
	} else if (curveType === CurveType.TANK_CURVE) {
		return t('Tank Curve Name');
	}
	return t('Curve Name');
};

export interface CurveManagerDrawerFormProps {
	curveType?: CurveType;
	formikProps: FormikProps<CurveManagerForm>;
}

const CurveManagerDrawerForm = ({
	curveType,
	formikProps,
}: CurveManagerDrawerFormProps) => {
	const { t } = useGlobalization();
	const isCurveUnitsEnabled = useIsFeatureEnabled(
		'info-360-analytics-manufacture-curve-units',
	);
	const { dialog } = useSelectCurveManager();

	const isEdit = dialog === CurveManagerDialogs.EditCurve;
	const [yAxisType, setYAxisType] = React.useState(
		formikProps.values.ySeries?.type ?? 'Pressure',
	);

	const curveTypeOptions: (t: TFunction) => FieldDropDownOption[] = t => [
		{ value: 'Pressure', label: t('Pressure') },
		{ value: 'Power', label: t('Power') },
		{ value: 'Efficiency', label: t('Efficiency') },
	];

	const typeUnitOptions: (t: TFunction) => FieldDropDownOption[] = t => {
		if (yAxisType === 'Efficiency')
			return [{ value: 'Percent', label: '%' }];
		const options = SENSOR_TYPE_UNITS_MAP[
			yAxisType as keyof typeof SENSOR_TYPE_UNITS_MAP
		]
			?.filter(unit => unit !== 'No Units')
			?.filter(unit => unit !== 'm')
			?.map(unit => ({
				value: unit,
				label: t(unit),
			}));
		return options;
	};

	React.useEffect(() => {
		const unit =
			typeUnitOptions(t).find(
				opt => opt.value === formikProps.values.ySeries?.unit,
			)?.value ??
			typeUnitOptions(t)[0].value ??
			'';
		formikProps.setFieldValue('curveData.ySeries.unit', unit);
	}, [yAxisType]);

	return (
		<CurveDrawerForm onSubmit={formikProps.handleSubmit}>
			<FormControl variant="outlined" margin="normal" fullWidth>
				<FormLayoutRow>
					<InputText
						textField={{
							required: true,
							error: !!formikProps?.errors?.curveName,
							helperText: formikProps?.errors?.curveName,
							variant: 'outlined',
							label: getCurveInputName(t, curveType),
							disabled: isEdit,
						}}
						fieldAttrs={{
							name: 'curveName',
						}}
					/>
				</FormLayoutRow>
				{curveType === CurveType.PRESSURE_CURVE && isCurveUnitsEnabled && (
					<>
						<FormLayoutRow>
							<FormLayoutColumn>
								<InputText
									textField={{
										margin: 'dense',
										required: true,
										variant: 'outlined',
										label: t('X Sensor Type'),
										disabled: true,
										value: 'Flow Rate',
									}}
									fieldAttrs={{
										name: 'curveData.xSeries.type',
									}}
								/>
							</FormLayoutColumn>
							<FormLayoutColumn>
								<FieldDropDown
									dataCy="x-field-manufacturerCurve-unit"
									label={t('X Unit')}
									name="curveData.xSeries.unit"
									options={SENSOR_TYPE_UNITS_MAP['Flow Rate']
										?.filter(unit => unit !== 'No Units')
										?.map(unit => ({
											value: unit,
											label: unit,
										}))}
									style={{ flex: 1 }}
									required
									margin="dense"
								/>
							</FormLayoutColumn>
						</FormLayoutRow>
						<FormLayoutRow>
							<FormLayoutColumn>
								<FieldDropDown
									dataCy="field-manufacturerCurve-type"
									label={t('Y Sensor Type')}
									name="curveData.ySeries.type"
									options={curveTypeOptions(t)}
									onChange={value => {
										setYAxisType(value?.value ?? '');
									}}
									style={{ flex: 1 }}
									required
								/>
							</FormLayoutColumn>
							<FormLayoutColumn>
								<FieldDropDown
									dataCy="y-field-manufacturerCurve-unit"
									label={t('Y Unit')}
									name="curveData.ySeries.unit"
									options={typeUnitOptions(t)}
									style={{ flex: 1 }}
									required
								/>
							</FormLayoutColumn>
						</FormLayoutRow>
					</>
				)}
			</FormControl>
		</CurveDrawerForm>
	);
};

export default CurveManagerDrawerForm;
