import styled, { css } from 'styled-components';
import { Box } from '@mui/material';
import { rem } from '../../utils/styles';

interface WrapperProps {
	$fullHeight?: boolean;
	$applyMarginBottom?: boolean;
	$minHeight?: number | string;
}

interface BodyProps {
	applyBodyPadding?: boolean;
}

export const Wrapper = styled(Box)<WrapperProps>`
	${({ $fullHeight }) =>
		$fullHeight &&
		css`
			> div {
				height: 100%;
			}
		`}
	${({ $applyMarginBottom }) =>
		$applyMarginBottom &&
		css`
			margin-bottom: ${rem(5)};
		`}
	.dropdown-menu {
		margin: ${rem(-12)} 0;
	}
	min-height: ${({ $minHeight }) =>
		typeof $minHeight === 'number' ? rem($minHeight) : $minHeight};

	> div {
		min-height: ${({ $minHeight }) => $minHeight};
	}
`;

export const Body = styled.div<BodyProps>`
	padding: ${({ applyBodyPadding }) => (applyBodyPadding ? rem(24) : '0')};
`;

export const BodyWrapper = styled.div<{ $minHeight?: number | string }>`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	justify-content: space-between;
	min-height: ${({ $minHeight }) =>
		typeof $minHeight === 'number' ? rem($minHeight) : $minHeight};
`;
