import { getAssetTypePrefixBySystemType } from '@innovyze/lib_asset_schema';
import { MapRehabLayer } from '@Types/map.types';
import { RehabDecisionTree, RehabAction } from '@Types/rehab.types';
import { camelCase, upperFirst } from 'lodash';

/**
 * TODO: Fix backend response @AssetSchemaMigration
 * @see RehabTreeCard component in app-am-rehab
 *      {@link https://git.autodesk.com/aec-water/app-am-rehab/blob/812a302a53f1f0db0c4e1f52a39ee93557cf225a/src/components/RehabTreeList/RehabTreeCard.component.tsx#L226-L277}
 *
 * Note: The backend seems to be returning 'pipe' by default for 'Sanitary Sewer' systems.
 * Also, in some rehab models, the asset type is returned as 'PIPE'.
 */
const correctAssetType = (assetType: string, systemType: string): string => {
	// Remove spaces to convert display name to System Type ID.
	const normalizedSystemType = systemType.split(' ').join('');
	const assetTypePrefix =
		getAssetTypePrefixBySystemType(normalizedSystemType);

	if (assetTypePrefix && !assetType.startsWith(assetTypePrefix)) {
		return `${assetTypePrefix}${upperFirst(camelCase(assetType))}`;
	}

	return assetType;
};

export function correctRehabDetails(rehab: RehabDecisionTree): MapRehabLayer {
	const systemType = rehab.systemType ?? 'Sanitary Sewer';
	const assetType = correctAssetType(rehab.assetType ?? 'wwPipe', systemType);

	return {
		layerName: rehab.name,
		id: rehab._id,
		lastRun: rehab.lastRun ?? '',
		assetType,
		systemType,
		layers: rehab.actions?.map((a: RehabAction) => ({
			layerName: a.actionId,
			id: a._id,
		})),
	};
}
