import * as uuid from 'uuid';
import {
	getRehabTrees,
	getRehabTreesResolved,
	getRehabTreesRejected,
	getRehabTreeResolved,
	getRehabTreeRejected,
	getRehabTree,
	addRehabTreeRejected,
	addRehabTree,
	addRehabTreeResolved,
	saveRehabTree,
	deleteRehabTree,
	deleteRehabTreeResolved,
	deleteRehabTreeRejected,
	getRehabActionsResolved,
	getRehabActionsRejected,
	getRehabActions,
	deleteRehabAction,
	deleteRehabActionRejected,
	deleteRehabActionResolved,
	addRehabActionResolved,
	addRehabActionRejected,
	saveRehabActionResolved,
	saveRehabActionRejected,
	addRehabAction,
	saveRehabAction,
	validateRehabTree,
	validateRehabTreeRejected,
	runRehabTreeRejected,
	runRehabTree,
	setRehabResultIsBusy,
	getRehabResultResolved,
	getRehabResultRejected,
	getRehabResult,
	reloadRehabResult,
	changeFilterSelect,
	changeSort,
	changePage,
	updateColumnFilterSelectOptions,
	addRehabTreeValidationResult,
	editActionResolved,
	editActionRejected,
	lockAction,
	lockActionResolved,
	lockActionRejected,
	editAction,
	duplicateRehabTreeRejected,
	duplicateRehabTree,
	setStartNodeId,
	getRehabCostResolved,
	getRehabCostRejected,
	getRehabCost,
	setRehabTreesIsBusy,
	getRehabTreesListResolved,
	getRehabTreesListRejected,
	getRehabTreesList,
	reloadRehabTrees,
	changeRehabTreesPage,
	changeRehabTreesSlice,
	changeRehabTreesSort,
	duplicateRehabTreeResolved,
	setRefreshList,
	renameRehabTree,
	setRehabTreesIsRenaming,
	setRehabTreeNameError,
	addNewRehabActionToTree,
	getRehabTreeStatusRejected,
	getRehabTreeStatusResolved,
	getRehabTreeStatus,
	getRehabTreeValidationStatus,
	getRehabTreeValidationStatusResolved,
	getRehabTreeValidationStatusRejected,
	changePagination,
	changeFiltersSelect,
} from '@Actions/Rehab.actions';
import {
	put,
	retry,
	takeLatest,
	select,
	call,
	all,
} from '@redux-saga/core/effects';

import { addRouteNamespace } from '@Utils/actions';

import {
	ReactFlowDecisionTree,
	ReactFlowSchema,
	RehabTreeResolvedResponse,
	NewDecisionTree,
	RehabDecisionTree,
	RehabActionsResolvedResponse,
	RehabAction,
	AddRehabTreePayload,
	RehabResults,
	RehabCostResolvedResponse,
	RiskConfig,
	RehabTreeStatusResolvedResponse,
	RehabTreesAndIdsResponse,
} from '@Types/rehab.types';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
	getRehabTreesApi,
	getRehabTreeApi,
	addRehabTreeApi,
	updateRehabTreeApi,
	deleteRehabTreeApi,
	getRehabActionsApi,
	deleteRehabActionApi,
	addRehabActionApi,
	saveRehabActionApi,
	validateRehabTreeApi,
	runRehabTreeApi,
	getRehabResultApi,
	lockRehabActionApi,
	editRehabActionApi,
	updateRehabStatus,
	duplicateRehabTreeApi,
	getRehabCostApi,
	renameRehabTreeApi,
	getRehabTreeStatusApi,
	getRehabTreeValidationStatusApi,
} from '@Services/Rehab/Rehab.services';
import {
	convertTreeToReactFlowObject,
	convertReactFlowObjectToTree,
	getMainTreeNodesIds,
	extractDraftNodes,
	getStartNodeId,
	extractMainTreeAndCreateTreeObject,
	checkIfActionCostChange,
	updateTreeActionsWithUpdatedCost,
	getMissingMultiRiskModelError,
	TREE_STATES,
} from '@Utils/TreeUtils';
import { AnyAction } from 'redux';
import { success, error } from '@innovyze/stylovyze';
import {
	currentStartNodeIdSelector,
	CurrentTreeSelector,
	rehabResultQuerySelector,
	selectorNewTreeQuery,
	getRehabActionsSelector,
	getReactFlowTreeSelector,
	selectorRehabTreesPaginationQuery,
	selectorAllRiskConfigsIds,
} from '@Selectors/Rehab.selectors';
import { ResolvedResponse, Row } from '@innovyze/lib-am-common';
import { t } from 'i18next';
import _ from 'lodash-es';

function* getRehabTreesSaga() {
	try {
		yield put(setRehabTreesIsBusy(true));

		const query: string = yield select(selectorRehabTreesPaginationQuery);

		const payload: AxiosResponse<ResolvedResponse> = yield retry(
			5,
			15000,
			getRehabTreesApi,
			query,
		);

		yield put(
			getRehabTreesResolved({
				pagination: payload.data.pagination,
				data: payload.data.data,
			}),
		);
		yield put(setRehabTreesIsBusy(false));
	} catch (e) {
		const err = e as AxiosError;
		const rejection = {
			code: err?.response?.status ?? -1,
			text:
				err?.response?.statusText ??
				t('No additional information') ??
				'',
		};

		yield put(getRehabTreesRejected(rejection));
		yield put(setRehabTreesIsBusy(false));
	}
}

function* watchGetRehabTrees() {
	yield takeLatest(
		[
			getRehabTrees,
			reloadRehabTrees,
			changeRehabTreesPage,
			changeRehabTreesSlice,
			changeRehabTreesSort,
		],
		getRehabTreesSaga,
	);
}

//use input action to check if need to load trees for default page
function* getRehabTreesListSaga() {
	try {
		yield put(setRehabTreesIsBusy(true));

		const payload: AxiosResponse<RehabTreesAndIdsResponse> = yield retry(
			5,
			15000,
			getRehabTreesApi,
			'?listAllAndTrees=true',
		);

		yield put(getRehabTreesListResolved(payload.data.allTreeIds));

		yield put(
			getRehabTreesResolved({
				pagination: payload.data.pagination,
				data: payload.data.data,
			}),
		);

		yield put(setRehabTreesIsBusy(false));
	} catch (e) {
		const err = e as AxiosError;
		const rejection = {
			code: err?.response?.status ?? -1,
			text:
				err?.response?.statusText ??
				t('No additional information') ??
				'',
		};

		yield put(getRehabTreesListRejected(rejection));
		yield put(setRehabTreesIsBusy(false));
	}
}

function* watchGetRehabTreesList() {
	yield takeLatest([getRehabTreesList], getRehabTreesListSaga);
}

function* getRehabTreeSaga(action: AnyAction) {
	try {
		const payload: AxiosResponse<RehabTreeResolvedResponse> = yield retry(
			5,
			1500,
			getRehabTreeApi,
			action.payload.Id,
		);
		let reactFlowDecisionTree: ReactFlowDecisionTree;

		if (payload.data.data) {
			const treeNodes: ReactFlowSchema[] = convertTreeToReactFlowObject(
				payload.data.data.treeNodes,
			);

			if (
				payload.data.data.draftNodes &&
				payload.data.data.draftNodes?.length > 0
			) {
				payload.data.data.draftNodes.forEach(draft => {
					const draftNode = draft;
					if (draftNode.data) {
						draftNode.data.nodeData.assetsCount = {};
					}
					if (draft.source && draft.label) {
						draftNode.label = draft.label?.split(' ')[0];
					}

					treeNodes.push(draftNode);
				});
				const startNodeId = getStartNodeId(
					payload.data.data.draftNodes,
				);

				yield put(setStartNodeId(startNodeId));
			}

			const startNodeCheck: any = getStartNodeId(treeNodes);

			//implement start node new feature for existing trees
			if (
				treeNodes.length > 0 &&
				payload.data.data.draftNodes &&
				payload.data.data.draftNodes.length === 0 &&
				!startNodeCheck
			) {
				const startNode = {
					id: 'idxStart_' + new Date().getTime().toString(),
					// Cant translate as the label is used for code :(
					data: { label: 'Start' },
					style: {
						border: '1px solid #5F60FF',
						padding: 10,
						background: '#5F60FF',
						borderRadius: '1rem',
						color: 'white',
						fontSize: '1.2rem',
					},
					type: 'input',
					position: { x: 700, y: 50 },
				};
				treeNodes.push(startNode as any);
				yield put(setStartNodeId(startNode.id));
			}

			reactFlowDecisionTree = {
				_id: payload.data.data._id ?? '',
				name: payload.data.data.name,
				thumbnail: payload.data.data.thumbnail,
				nodes: treeNodes,
				status: payload.data.data.status,
				runBy: payload.data.data.runBy,
				lastRun: payload.data.data.lastRun,
				systemType: payload.data.data.systemType,
				assetType: payload.data.data.assetType,
				assetCount: payload.data.data.assetCount,
				nodeCount: payload.data.data.nodeCount,
				inspectionStandard:
					payload.data.data.inspectionStandard ?? 'PACP',
			};

			yield put(
				getRehabActions({
					Id: action.payload.Id,
				}),
			);
			yield put(getRehabTreeResolved(reactFlowDecisionTree));

			if (
				payload.data.data.validationData &&
				payload.data.data.validationData.length > 0
			) {
				yield put(
					addRehabTreeValidationResult({
						treeId: payload.data.data._id ?? '',
						isValid: false,
						validationMessages: payload.data.data.validationData,
					}),
				);
			}
		}
	} catch (e) {
		yield put(getRehabTreeRejected());
	}
}

function* watchgetRehabTree() {
	yield takeLatest(getRehabTree, getRehabTreeSaga);
}

function* addRehabTreeSaga(action: AnyAction) {
	try {
		const tree: NewDecisionTree = yield select(selectorNewTreeQuery);
		const nodes: any = null;

		if (tree.treeNodes.length === 0) {
			//nodes = convertReactFlowObjectToTree(tree.treeNodes);
		}

		const rehabTree: RehabDecisionTree = {
			_id: uuid.v4(),
			name: tree.name,
			systemType: tree.systemType,
			assetType: tree.assetType,
			thumbnail: '',
			treeNodes: nodes ? nodes.treeNodes : null,
			inspectionStandard: tree.inspectionStandard,
		};
		const payload: AddRehabTreePayload = yield call(
			addRehabTreeApi,
			rehabTree,
		);

		action.payload.history.push(
			addRouteNamespace(`details/${payload.data.treeId}`),
		);
		yield put(setRefreshList(true));
	} catch (e) {
		if (e instanceof AxiosError) {
			if (e.response?.status == 400) {
				const errorMess =
					e?.response?.data?.errorMessage.replace('[400] ', '') ?? '';

				const errMessagesArr = JSON.parse(errorMess);
				yield all(
					errMessagesArr.message.map((errorMsg: string) =>
						put(error(errorMsg)),
					),
				);
			}
		} else {
			yield put(error(t('The process has failed')));
		}

		yield put(addRehabTreeRejected());
	}
}

function* watchAddRehabTreeSaga() {
	yield takeLatest(addRehabTree, addRehabTreeSaga);
}
function* saveRehabTreeSaga(action: AnyAction) {
	// yield put(setFullInspectionIsWaiting(true));
	try {
		const tree: NewDecisionTree = yield select(selectorNewTreeQuery);
		const riskConfigModels: RiskConfig[] = yield select(
			selectorAllRiskConfigsIds,
		);
		//const riskConfigModels: any[] = [];

		const startNodeId: string = yield select(currentStartNodeIdSelector);
		const mainTreeIds = getMainTreeNodesIds(tree.treeNodes, startNodeId);

		const allTreeNodes = mainTreeIds
			? extractDraftNodes(tree.treeNodes, mainTreeIds as any)
			: extractDraftNodes(tree.treeNodes, [] as any);

		let nodes: any = null;
		if (allTreeNodes?.mainNodes.length > 0) {
			let rootId = '';
			tree.treeNodes.forEach(node => {
				if (node.source && node.source === startNodeId && node.target) {
					rootId = node.target as string;
				}
			});
			nodes = convertReactFlowObjectToTree(
				allTreeNodes?.mainNodes,
				rootId,
			);
		}
		const riskConfigModelsIds: string[] = [];
		riskConfigModels.map((config: { configId: string; name: string }) => {
			riskConfigModelsIds.push(config.configId);
		});

		const configModelsValidationData = getMissingMultiRiskModelError(
			allTreeNodes?.mainNodes,
			riskConfigModelsIds,
		);

		const rehabTree: RehabDecisionTree = {
			_id: action.payload.id,
			name: tree.name,
			thumbnail: '',
			treeNodes: nodes ? nodes.treeNodes : {},
			draftNodes: allTreeNodes?.draftNodes
				? allTreeNodes?.draftNodes
				: [],
			configModelsValidationData: configModelsValidationData,
		};

		yield retry(3, 1500, updateRehabTreeApi, action.payload, rehabTree);
		yield put(
			getRehabTree({
				Id: action.payload,
			}),
		);
		yield put(addRehabTreeResolved());
		yield put(
			addRehabTreeValidationResult({
				treeId: action.payload as string,
				isValid: true,
				validationMessages: [],
			}),
		);
		if (allTreeNodes?.mainNodes.length > 0) {
			yield put(validateRehabTree({ treeId: action.payload }));
		} else {
			yield put(
				getRehabTree({
					Id: action.payload,
				}),
			);
		}
		yield put(
			success(
				t(
					'Tree has updated successfully, preview asset count is in progress',
				),
			),
		);
		yield put(setRefreshList(true));
	} catch (e) {
		if (e instanceof AxiosError) {
			if (e.response?.status == 400) {
				const errorMess =
					e?.response?.data?.errorMessage.replace('[400] ', '') ?? '';

				const errMessagesArr = JSON.parse(errorMess);

				const validationMessages = errMessagesArr.message.map(
					(ele: string) => {
						return { errorMessage: ele };
					},
				);
				yield put(
					addRehabTreeValidationResult({
						treeId: action.payload as string,
						isValid: false,
						validationMessages: validationMessages,
					}),
				);
				yield put(error(t('Invalid tree')));
			}
		} else {
			yield put(error(t('Update tree has failed')));
		}
		yield put(addRehabTreeRejected());
	}
}

function* watchSaveRehabTreeSaga() {
	yield takeLatest(saveRehabTree, saveRehabTreeSaga);
}

function* deleteRehabTreeSaga(action: AnyAction) {
	try {
		yield call(deleteRehabTreeApi, action.payload.treeId);
		yield put(deleteRehabTreeResolved(action.payload.treeId));
		yield put(success(t('Tree deleted successfully')));
		action.payload.refresh();
	} catch (_e) {
		const e = _e as AxiosError;
		if (e) {
			if (e.response?.status === 400) {
				yield put(
					error(
						t(
							'Unable to delete a published Rehab Tree. Please un-publish the Rehab Tree prior to deletion.',
						),
					),
				);
			}
		}
		yield put(deleteRehabTreeRejected());
	}
}
function* watchDeleteRehabTreeSaga() {
	yield takeLatest(deleteRehabTree, deleteRehabTreeSaga);
}

function* getRehabActionsSaga(action: AnyAction) {
	try {
		const payload: AxiosResponse<RehabActionsResolvedResponse> =
			yield retry(5, 1500, getRehabActionsApi, action.payload.Id);
		yield put(getRehabActionsResolved(payload.data.data));

		const rehabActions = payload.data.data;
		let options: {
			id: string;
			caption: string;
		}[] = []; //[AM_COMMON.noFilters()];
		if (rehabActions && rehabActions.length > 0) {
			options = [
				...rehabActions.map(action => {
					return { id: action._id, caption: action.actionId };
				}),
			];
		}
		yield put(
			updateColumnFilterSelectOptions({
				colKey: 'FINAL_RRHAB_ACTION',
				options: options,
			}),
		);
	} catch (e) {
		yield put(getRehabActionsRejected());
	}
}
function* watchGetRehabActions() {
	yield takeLatest(getRehabActions, getRehabActionsSaga);
}

function* addRehabActionSaga(action: AnyAction) {
	try {
		yield call(addRehabActionApi, action.payload.rehabAction);
		yield put(addRehabActionResolved(action.payload.rehabAction));
		yield put(
			getRehabActions({
				Id: action.payload.rehabAction.treeId,
			}),
		);
		yield put(success(t('Action successfully added')));
		yield put(addNewRehabActionToTree(action.payload.rehabAction));
	} catch (e) {
		yield put(addRehabActionRejected());
		if (e instanceof AxiosError) {
			if (e.response?.status == 400) {
				const errorMess =
					e?.response?.data?.errorMessage.replace('[400] ', '') ?? '';
				const errMessage = JSON.parse(errorMess);
				yield put(error(errMessage?.message));
			}
		}
	}
}

function* watchAddRehabActionSaga() {
	yield takeLatest(addRehabAction, addRehabActionSaga);
}

function* deleteRehabActionSaga(action: AnyAction) {
	try {
		yield retry(3, 1500, deleteRehabActionApi, action.payload);
		yield put(deleteRehabActionResolved(action.payload));
		yield put(success(t('Action successfully deleted')));
	} catch (err) {
		yield put(deleteRehabActionRejected());
		yield put(error(t('Failed to delete action')));
	}
}
function* watchDeleteRehabActionSaga() {
	yield takeLatest(deleteRehabAction, deleteRehabActionSaga);
}

function* saveRehabActionSaga(action: AnyAction) {
	try {
		yield call(saveRehabActionApi, action.payload.rehabAction);
		yield put(saveRehabActionResolved(action.payload.rehabAction));
		yield put(success(t('Rehab action saved successfully')));
	} catch (e) {
		yield put(saveRehabActionRejected());
		if (e instanceof AxiosError) {
			if (e.response?.status == 400) {
				const errorMess =
					e?.response?.data?.errorMessage.replace('[400] ', '') ?? '';
				const errMessage = JSON.parse(errorMess);
				yield put(error(errMessage?.message));
			}
		}
	}
}

function* watchSaveRehabActionSaga() {
	yield takeLatest(saveRehabAction, saveRehabActionSaga);
}

function* validateRehabTreeSaga(action: AnyAction) {
	try {
		yield call(validateRehabTreeApi, action.payload.treeId);
		yield put(getRehabTreeValidationStatus({ Id: action.payload.treeId }));
	} catch (e) {
		if (e instanceof AxiosError) {
			let errorMess = e?.response?.data?.errorMessage ?? '';

			if (e.response?.status == 400 || errorMess.includes('400')) {
				errorMess = errorMess.replace('[400] ', '') ?? '';

				const errMessagesArr = JSON.parse(errorMess);

				yield put(error(t('Validation failed')));
				if (errMessagesArr && Array.isArray(errMessagesArr.message)) {
					yield put(
						addRehabTreeValidationResult({
							treeId: action.payload.treeId as string,
							isValid: false,
							validationMessages: [
								{ errorMessage: errMessagesArr.message },
							],
						}),
					);
				} else {
					yield put(
						addRehabTreeValidationResult({
							treeId: action.payload.treeId as string,
							isValid: false,
							validationMessages: [
								{ errorMessage: errMessagesArr.message },
							],
						}),
					);
				}
			}

			yield put(validateRehabTreeRejected());
		} else {
			yield put(error(t('Validation failed')));
			yield put(validateRehabTreeRejected());
		}
	}
}

function* watchValidateRehabTreeSaga() {
	yield takeLatest(validateRehabTree, validateRehabTreeSaga);
}

function* runRehabTreeSaga(action: AnyAction) {
	try {
		// const riskConfigModels: RiskConfig[] = yield select(
		// 	selectorAllRiskConfigsIds,
		// );
		const startNodeId: string = yield select(currentStartNodeIdSelector);
		const currentTree: ReactFlowDecisionTree = yield select(
			getReactFlowTreeSelector,
		);

		const mainTreeIds = getMainTreeNodesIds(currentTree.nodes, startNodeId);

		const allTreeNodes = mainTreeIds
			? extractDraftNodes(currentTree.nodes, mainTreeIds as any)
			: extractDraftNodes(currentTree.nodes, [] as any);

		if (allTreeNodes?.mainNodes.length > 0) {
			let rootId = '';
			currentTree.nodes.forEach(node => {
				if (node.source && node.source === startNodeId && node.target) {
					rootId = node.target as string;
				}
			});
			convertReactFlowObjectToTree(allTreeNodes?.mainNodes, rootId);
		}

		const rehabActions: RehabAction[] = yield select(
			getRehabActionsSelector,
		);

		if (rehabActions.length > 0) {
			const treeObject = extractMainTreeAndCreateTreeObject(
				currentTree,
				startNodeId,
			);

			const costIsUpdated = checkIfActionCostChange(
				treeObject.treeNodes,
				rehabActions,
			);

			if (costIsUpdated) {
				updateTreeActionsWithUpdatedCost(
					treeObject.treeNodes,
					_.groupBy(rehabActions, '_id') as any,
				);
				yield retry(
					3,
					1500,
					updateRehabTreeApi,
					action.payload.treeId,
					treeObject,
				);
			}
		}
		yield call(
			updateRehabStatus,
			action.payload.treeId,
			TREE_STATES.IN_PROGRESS,
		);
		yield call(runRehabTreeApi, action.payload.treeId);

		yield put(
			getRehabTree({
				Id: action.payload.treeId,
			}),
		);
		yield put(
			getRehabActions({
				Id: action.payload.treeId,
			}),
		);
		yield put(success(t('Tree is running')));
		yield put(getRehabTreeStatus({ Id: action.payload.treeId }));
	} catch (e) {
		const err = e as AxiosError;
		yield put(runRehabTreeRejected());

		if (e instanceof AxiosError) {
			if (e.response?.status == 400) {
				const errorMess =
					e?.response?.data?.errorMessage.replace('[400] ', '') ?? '';

				const errMessagesArr = JSON.parse(errorMess);

				const validationMessages = errMessagesArr.message.map(
					(ele: string) => {
						return { errorMessage: ele };
					},
				);
				yield put(
					addRehabTreeValidationResult({
						treeId: action.payload.treeId as string,
						isValid: false,
						validationMessages: validationMessages,
					}),
				);
				yield put(error(t('Invalid tree')));
				//yield call(updateRehabStatus, action.payload.treeId, 'Failed');
			}
			//return;
		}

		if (err.message.includes('timeout')) {
			yield put(success(t('Tree is running')));
			yield call(
				updateRehabStatus,
				action.payload.treeId,
				TREE_STATES.COMPLETE,
			);
		} else {
			yield put(error(t('Run failed')));
			yield call(
				updateRehabStatus,
				action.payload.treeId,
				TREE_STATES.FAILED,
			);
		}
	}
}
function* watchRunRehabTreeSaga() {
	yield takeLatest(runRehabTree, runRehabTreeSaga);
}

function* getRehabResultSaga() {
	try {
		yield put(setRehabResultIsBusy(true));
		const query: string = yield select(rehabResultQuerySelector);
		const treeId: string = yield select(CurrentTreeSelector);
		// quick tmp fix - will do via custom header eventually
		const payload: AxiosResponse<ResolvedResponse> = yield retry(
			5,
			1500,
			getRehabResultApi,
			treeId,
			query,
		);
		const rows = payload.data.data as Row[];
		const data = {
			...payload.data,
			data: rows.map(rehabResult => {
				if (rehabResult.ASSET_ID.indexOf('#') > 0) {
					return {
						...rehabResult,
						ASSET_ID: rehabResult.ASSET_ID.split('#')[1],
					};
				} else {
					return rehabResult;
				}
			}),
		};
		yield put(getRehabResultResolved(data));
		yield put(setRehabResultIsBusy(false));
	} catch (e) {
		const { response }: any = e;
		yield put(
			getRehabResultRejected({
				code: response?.status ?? -1,
				text: response?.statusText ?? t('No additional information'),
			}),
		);
		yield put(setRehabResultIsBusy(false));
	}
}

function* watchGetRehabResult() {
	yield takeLatest(
		[
			getRehabResult,
			reloadRehabResult,
			changePage,
			changePagination,
			changeSort,
			changeFilterSelect,
			changeFiltersSelect,
		],
		getRehabResultSaga,
	);
}

function* LockRehabActionSaga(action: AnyAction) {
	const rehabResults = action.payload as RehabResults;
	try {
		//yield retry(3, 1500, validateRehabTreeApi, action.payload.treeId);
		if (rehabResults) {
			yield put(setRehabResultIsBusy(true));

			yield call(lockRehabActionApi, rehabResults);

			yield put(lockActionResolved());

			yield put(reloadRehabResult());

			yield put(
				success(
					t('Action {{locked}} successfully', {
						locked:
							rehabResults.locked == '1'
								? t('locked')
								: t('unlocked'),
					}),
				),
			);
		}
	} catch (e) {
		yield put(lockActionRejected());
		yield put(
			error(
				t('Action {{locked}} failed', {
					locked:
						rehabResults.locked == '1'
							? t('locked')
							: t('unlocked'),
				}),
			),
		);
		yield put(setRehabResultIsBusy(false));
	}
}
function* watchLockRehabActionSaga() {
	yield takeLatest(lockAction, LockRehabActionSaga);
}

function* EditRehabActionSaga(action: AnyAction) {
	const rehabResults = action.payload as RehabResults;
	try {
		//yield retry(3, 1500, validateRehabTreeApi, action.payload.treeId);

		if (rehabResults) {
			yield call(editRehabActionApi, rehabResults);

			yield put(editActionResolved());

			yield put(reloadRehabResult());

			//yield put(success('Action edit successfully'));
		}
	} catch (e) {
		yield put(editActionRejected());
		//yield put(error('Action edit failed'));
	}
}
function* watchEditRehabActionSaga() {
	yield takeLatest(editAction, EditRehabActionSaga);
}

function* duplicateRehabTreeSaga(action: AnyAction) {
	try {
		const response: AxiosResponse = yield call(
			duplicateRehabTreeApi,
			action.payload.treeId,
		);
		yield put(duplicateRehabTreeResolved());
		yield put(setRefreshList(true));
		action.payload.onComplete(response.data.treeId);
	} catch (e) {
		if (axios.isAxiosError(e)) {
			const axiosError = e as AxiosError;
			// const data = axiosError.response?.data as ConfigData;
			yield put(
				error(
					t('Failed to create rehab tree: {{info}}', {
						info:
							axiosError.response?.statusText ??
							// data?.errorMessage ??
							t('No additional information'),
					}),
				),
			);
		} else {
			yield put(
				error(
					t('Failed to create rehab tree: {{info}}', {
						info: t('No additional information'),
					}),
				),
			);
		}
		yield put(duplicateRehabTreeRejected());
	}
}
function* watchDuplicateRehabTreeSaga() {
	yield takeLatest(duplicateRehabTree, duplicateRehabTreeSaga);
}
interface RenameData {
	errorMessage: string;
}

export function* renameRehabTreeSaga(action: AnyAction) {
	const { treeId, name } = action.payload;
	try {
		yield put(setRehabTreesIsRenaming(true));
		yield call(renameRehabTreeApi, treeId, name);
		yield put(success(t('Tree renamed')));
		yield put(setRehabTreesIsRenaming(false));
	} catch (e) {
		if (axios.isAxiosError(e)) {
			const axiosError = e as AxiosError;
			const data = axiosError.response?.data as RenameData;
			if (data?.errorMessage === '[400] Duplicate tree name') {
				yield put(setRehabTreeNameError(t('Enter a unique name')));
			} else {
				yield put(
					error(
						t('Tree failed to save: {{info}}', {
							info:
								axiosError.response?.statusText ??
								data?.errorMessage ??
								t('No additional information'),
						}),
					),
				);
			}
		} else {
			yield put(
				error(
					t('Tree failed to save: {{info}}', {
						info: t('No additional information'),
					}),
				),
			);
		}
		yield put(setRehabTreesIsRenaming(false));
	}
}

function* watchRenameRehabTree() {
	yield takeLatest(renameRehabTree, renameRehabTreeSaga);
}

function* getRehabCostSaga(action: AnyAction) {
	try {
		// const treeId: string = yield select(CurrentTreeSelector);

		// quick tmp fix - will do via custom header eventually
		const payload: AxiosResponse<RehabCostResolvedResponse> = yield retry(
			5,
			1500,
			getRehabCostApi,
			action.payload,
		);
		yield put(getRehabCostResolved(payload.data.data));
	} catch (e) {
		const { response }: any = e;
		yield put(
			getRehabCostRejected({
				code: response?.status ?? -1,
				text: response?.statusText ?? t('No additional information'),
			}),
		);
	}
}

function* watchGetRehabCostSaga() {
	yield takeLatest(getRehabCost, getRehabCostSaga);
}

function* getRehabTreeStatusSaga(action: AnyAction) {
	try {
		const payload: AxiosResponse<RehabTreeStatusResolvedResponse> =
			yield retry(1, 1500, getRehabTreeStatusApi, action.payload.Id);
		yield put(getRehabTreeStatusResolved(payload.data.status));
	} catch (err) {
		yield put(getRehabTreeStatusRejected());
	}
}

function* watchGetRehabTreeStatusSaga() {
	yield takeLatest(getRehabTreeStatus, getRehabTreeStatusSaga);
}

function* getRehabTreeValidationStatusSaga(action: AnyAction) {
	try {
		const payload: AxiosResponse<RehabTreeStatusResolvedResponse> =
			yield retry(
				1,
				1500,
				getRehabTreeValidationStatusApi,
				action.payload.Id,
			);
		yield put(getRehabTreeValidationStatusResolved(payload.data.status));
		if (payload.data.status == 'Failed') {
			yield put(getRehabTree({ Id: action.payload.Id }));
		}
	} catch (err) {
		yield put(getRehabTreeValidationStatusRejected());
	}
}

function* watchGetRehabTreeValidationStatusSaga() {
	yield takeLatest(
		getRehabTreeValidationStatus,
		getRehabTreeValidationStatusSaga,
	);
}

const sagaArray = [
	watchGetRehabTrees(),
	watchGetRehabTreesList(),
	watchgetRehabTree(),
	watchAddRehabTreeSaga(),
	watchSaveRehabTreeSaga(),
	watchDeleteRehabTreeSaga(),
	watchGetRehabActions(),
	watchAddRehabActionSaga(),
	watchDeleteRehabActionSaga(),
	watchSaveRehabActionSaga(),
	watchValidateRehabTreeSaga(),
	watchGetRehabResult(),
	watchRunRehabTreeSaga(),
	watchLockRehabActionSaga(),
	watchEditRehabActionSaga(),
	watchDuplicateRehabTreeSaga(),
	watchGetRehabCostSaga(),
	watchRenameRehabTree(),
	watchGetRehabTreeStatusSaga(),
	watchGetRehabTreeValidationStatusSaga(),
];

export default sagaArray;
