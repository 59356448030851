import { Environments, getApiEnvironment } from '@innovyze/stylovyze';

import config from './config.api';
import { formatUrlTrailing } from '@Utils/urlFormatting';

import { axiosWithHeaders, getService } from '@innovyze/lib_get_service';

const apiEnv =
	(localStorage.getItem('apiEnv') as keyof typeof Environments) || undefined;

export const baseTilesUrl = getService('tiles', config, getApiEnvironment());

export const tilesBaseUrl = formatUrlTrailing(
	getService('tiles', config, apiEnv),
);

const tiles = axiosWithHeaders({
	service: 'tiles',
	config,
	axiosCreateOpts: {
		timeout: 3000,
	},
});

export default tiles;
