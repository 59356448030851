import { SystemType, SystemTypeRaw } from '../types/system.types';
import systemTypesFile from '../schema/SystemTypes.yaml';
import { SystemTypeImpl } from './system.class';
import { Capability } from '../types/capabilities.types';

export const getDefaultSystemType = async (): Promise<string> => {
	return 'WaterDistribution';
};

type GetSystemTypeFilter = { capability: Capability };

export const fromRawSystem = (raw: SystemTypeRaw): SystemType => {
	return new SystemTypeImpl(raw);
};

export const getSystemTypesRaw = async (
	filter?: GetSystemTypeFilter,
): Promise<SystemTypeRaw[]> => {
	const systemTypes = systemTypesFile as unknown as SystemTypeRaw[];

	if (!filter) {
		return systemTypes;
	} else if ('capability' in filter) {
		return systemTypes.filter(s =>
			fromRawSystem(s).hasCapability(filter.capability),
		);
	}

	return [];
};
export const getSystemTypes = async (
	filter?: GetSystemTypeFilter,
): Promise<SystemType[]> => {
	return (await getSystemTypesRaw(filter)).map(fromRawSystem);
};

export const getSystemTypeRaw = async (
	systemType: string,
): Promise<SystemTypeRaw | undefined> => {
	const systemTypes = await getSystemTypesRaw();

	return systemTypes.find(s => s.SystemType === systemType);
};
export const getSystemType = async (
	systemType: string,
): Promise<SystemType | undefined> => {
	const raw = await getSystemTypeRaw(systemType);

	if (raw == undefined) {
		return undefined;
	}

	return fromRawSystem(raw);
};

export const getSystemTypeByAssetTypeRaw = async (
	assetType: string,
): Promise<SystemTypeRaw | undefined> => {
	const systemTypes = await getSystemTypesRaw();

	return systemTypes.find(s => s.AssetTypes.includes(assetType));
};

export const getSystemTypeByAssetType = async (
	assetType: string,
): Promise<SystemType | undefined> => {
	const raw = await getSystemTypeByAssetTypeRaw(assetType);

	if (raw == undefined) {
		return undefined;
	}

	return fromRawSystem(raw);
};

const SYSTEM_TYPE_MAPPING = {
	ww: 'SanitarySewer',
	sw: 'Stormwater',
	default: 'WaterDistribution',
} as const satisfies Record<string, string>;

type SystemTypeMapping = typeof SYSTEM_TYPE_MAPPING;
type SystemTypePrefix = Exclude<keyof SystemTypeMapping, 'default'>;

export function getSystemTypePrefixes(): SystemTypePrefix[] {
	return Object.keys(SYSTEM_TYPE_MAPPING).filter(
		(prefix): prefix is SystemTypePrefix => prefix !== 'default',
	);
}

export function getSystemTypeIdByAssetTypePrefix(
	assetType: string,
): SystemTypeMapping[keyof SystemTypeMapping] {
	const systemTypePrefixes = getSystemTypePrefixes();
	const prefix =
		systemTypePrefixes.find(prefix => assetType.startsWith(prefix)) ||
		'default';

	return SYSTEM_TYPE_MAPPING[prefix];
}

export function getAssetTypePrefixBySystemType(
	systemType: string,
): SystemTypePrefix | '' {
	const systemTypePrefixes = getSystemTypePrefixes();
	const prefix =
		systemTypePrefixes.find(
			prefix => getSystemTypeIdByAssetTypePrefix(prefix) === systemType,
		) || '';

	return prefix;
}
