export type Capability =
	| 'Cof'
	| 'Conditions'
	| 'Events'
	| 'Export'
	| 'Inspections'
	| 'Lof'
	| 'Ratings'
	| 'Rehab'
	| 'Risk'
	| 'RiskScore'
	| 'ShowOnMapPanel'
	| 'Tasks'
	| 'Upload';

export const Capabilities: Capability[] = [
	// assets
	'Conditions',
	'Events',
	'Export',
	'Inspections',
	'Ratings',
	'Risk',
	'RiskScore',
	'Tasks',
	'Upload',
	// properties
	'Cof',
	'Lof',
	'ShowOnMapPanel',
	// both
	'Rehab',
];

export type InspectionTypes = 'MACP' | 'PACP' | 'MSCC' | 'WSA05';

export interface CapabilitiesOfAssetType {
	Conditions?: boolean;
	Events?: boolean;
	Export?: boolean;
	Inspections?: InspectionTypes[];
	Ratings?: boolean;
	Rehab?: boolean;
	Risk?: boolean;
	RiskScore?: boolean;
	Tasks?: boolean;
	Upload?: boolean;
}

export interface CapabilitiesOfProperty {
	Cof?: boolean;
	Lof?: boolean;
	Rehab?: boolean;
	ShowOnMapPanel?: boolean;
}
