import * as mapUiI18nSettings from '@innovyze/map-components/Translations';

import { Tab, TabWrapper, Tabs } from '@Components/Tabs';
import * as assetSchema from '@innovyze/lib_asset_schema';
import { Asset, selectAssetById } from '@innovyze/map-components';
import { NamespacedStoreState as MapUIStoreState } from '@Types/store.types';
import { InfoCard, Property, PropertyCard } from '@innovyze/stylovyze';
import { AssetDetailsSummary } from '@Types';
import React, { useEffect, useMemo } from 'react';
import {
	extractFieldType,
	extractValue,
	getValue,
	mapSegments,
	mapUserTables,
} from './ValueMapping';

import {
	assetSchemaSelectorByType,
	getAssetSchemaAction,
} from '@innovyze/lib_asset_schema_store';
import { useGlobalization } from '@Translations';
import { a11yProps } from '@Utils';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { TabPanel } from '../../Tabs/TabPanel';

const getType = (type?: string, unit?: string): Property['type'] => {
	if (unit === '$') return 'currency';
	if (unit) return 'unit';
	if (type) {
		if (type === 'string') return 'text';
		if (type === 'date') return 'dateonlyUTC';
		if (type === 'datetime' || type === 'time') return 'dateUTC';
		return type as Property['type'];
	}
	return 'text';
};

const convertFieldType = (
	type: string,
	precision?: number,
): { fieldType: string; multiline?: boolean; precision?: number } => {
	switch (type) {
		case 'Boolean':
			return { fieldType: 'boolean' };
		case 'Double':
			return { fieldType: 'number', precision: precision ?? 2 };
		case 'Integer':
		case 'Long Integer':
			return { fieldType: 'number', precision: precision ?? 0 };
		case 'Date / Time':
			return { fieldType: 'date' };
		case 'Text':
			return { fieldType: 'string' };
		case 'Memo':
			return { fieldType: 'string', multiline: true };
		default:
			return { fieldType: 'string' };
	}
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DetailsOverview = ({
	assetType,
	assetId,
}: {
	assetType: string;
	assetId: string;
}): JSX.Element => {
	const { t } = useGlobalization();
	const [value, setValue] = React.useState(0);
	const dispatch = useDispatch();

	useEffect(() => {
		if (assetType) {
			dispatch(getAssetSchemaAction({ assetType }));
		}
	}, [assetType, dispatch]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const assetSchema = useSelector<any, assetSchema.AssetSchema | undefined>(
		state => {
			if (assetType) {
				return assetSchemaSelectorByType(state, assetType);
			}
		},
	);

	const sections = assetSchema?.categories;

	const id = `${assetType}#${assetId}`;
	const details = useSelector<
		MapUIStoreState & DefaultRootState,
		Asset | undefined
	>(store => selectAssetById(store, id));

	const segmentSchema = assetSchema
		?.getProperties()
		.find(p => p.id === 'segments') as
		| assetSchema.NestedProperty
		| undefined;

	const segments = useMemo(() => {
		if (!segmentSchema) return;
		const segments = (details as AssetDetailsSummary | undefined)?.segments;
		if (!segments) return;
		return mapSegments(segments, t);
	}, [segmentSchema, details]);

	const userDefinedTables = useMemo(() => {
		const userDefined = (details as AssetDetailsSummary | undefined)
			?.userDefined;
		if (!userDefined) return;
		return mapUserTables(userDefined);
	}, [details]);

	const handleChange = (event: unknown, newValue: number) => {
		setValue(newValue);
	};

	const segmentsTabIndex = sections?.length ?? 0;
	const customTablesIndex = segments
		? segmentsTabIndex + 1
		: segmentsTabIndex;

	return (
		<InfoCard title={t('Properties')}>
			<TabWrapper>
				<Tabs
					orientation="vertical"
					variant="scrollable"
					value={value}
					onChange={handleChange}>
					{Array.from(sections ?? []).map((section, i) => (
						<Tab key={i} label={t(section)} {...a11yProps(i)} />
					))}
					{segments && (
						<Tab
							label={t('Segments')}
							{...a11yProps(segmentsTabIndex)}
						/>
					)}
					{userDefinedTables && (
						<Tab
							label={t('Custom Tables')}
							{...a11yProps(customTablesIndex)}
						/>
					)}
				</Tabs>
				{Array.from(sections ?? []).map((section, i) => (
					<TabPanel value={value} index={i} key={i}>
						<PropertyCard
							title={t(section)}
							properties={
								assetSchema
									?.getProperties()
									.filter(p => p.category === section)
									.filter(p => p.type !== 'List')
									.map(
										p =>
											// TS analyser in IDE is smart enough, but the compiler isn't
											// so I have to tell ts the type.
											p as unknown as assetSchema.GenericSingleProperty,
									)
									.map(property => {
										const { fieldType, precision } =
											convertFieldType(
												property.type,
												property.precision,
											);
										return {
											title: t(property.displayName, {
												context: 'Asset Property',
												// the translations come from the map components library
												// so need to use the namespace from that
												ns: mapUiI18nSettings.ns,
											}),
											value: getValue(
												t,
												details,
												property.id,
												property.units,
											),
											cy: property.id,
											type: getType(
												fieldType,
												property.units,
											),
											decimalPlaces: precision,
											loading: !details,
										};
									}) ?? []
							}
							columns={4}
						/>
					</TabPanel>
				))}
				{segments && (
					<TabPanel value={value} index={segmentsTabIndex}>
						{segments.map(segment => (
							<PropertyCard
								key={segment.segmentId}
								title={t('Segment: {{segmentId}}', {
									segmentId: segment.segmentId,
								})}
								properties={
									segmentSchema?.properties
										.filter(p => p.id !== 'segmentId')
										.map(p => {
											const { fieldType, precision } =
												convertFieldType(
													p.type,
													p.precision,
												);
											return {
												title: t(p.displayName),
												value: getValue(
													t,
													segment,
													p.id,
													p.units,
												),
												cy: p.id,
												type: getType(
													fieldType,
													p.units,
												),
												decimalPlaces: precision,
												loading: !details,
											};
										}) ?? []
								}
								columns={4}
							/>
						))}
					</TabPanel>
				)}
				{userDefinedTables && (
					<TabPanel value={value} index={customTablesIndex}>
						{userDefinedTables.map(({ section, fields }) => (
							<PropertyCard
								key={section}
								title={section}
								properties={fields.map(({ name, value }) => ({
									title: name,
									value: extractValue(value),
									cy: name,
									type: getType(extractFieldType(value)),
								}))}
								columns={4}
							/>
						))}
					</TabPanel>
				)}
			</TabWrapper>
		</InfoCard>
	);
};
