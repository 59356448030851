import { Property } from '../types/asset.types';
import { getSystemTypes } from '../system';
import pseudoLocalization from 'pseudo-localization';
import { getAssetSchemas } from '../asset';

export const getTranslationEntries = async (): Promise<string[][]> => {
	const entries: string[][] = [];
	const usedProperties = new Set<string>();

	const systemTypes = await getSystemTypes();

	for (const systemType of systemTypes) {
		const assetTypes = await getAssetSchemas({
			systemTypeId: systemType.id,
		});

		for (const assetType of assetTypes) {
			const displayName = assetType.displayName;
			const pluralName = assetType.pluralName;

			entries.push([
				`${displayName}`,
				displayName,
			]);

			entries.push([
				`${pluralName}`,
				pluralName,
			]);

			addPropertyEntries(
				assetType.getProperties(),
				usedProperties,
				entries,
			);
		}
	}

	return [...entries.sort()];
};

const addPropertyEntries = (
	properties: Property[],
	usedProperties: Set<string>,
	entries: string[][],
) => {
	for (const property of properties) {
		if (usedProperties.has(property.id)) {
			continue;
		}

		const { displayName } = property;

		if (!displayName) {
			continue;
		}

		entries.push([
			`${displayName}`,
			displayName,
		]);

		usedProperties.add(property.id);
	}
};

export const getTranslation = async (
	language: string,
): Promise<Record<string, string>> => {
	const translation = await getTranslationEntries();
	if (language === 'en-PS') {
		return Object.fromEntries(
			translation.map(([key, value]) => [
				key,
				`[${pseudoLocalization.localize(value)}]`,
			]),
		);
	}
	return Object.fromEntries(translation);
};

// This is to pass the translation "file" to stylovyze for loading the translation
export const getTranslationFile = async (
	language: string,
): Promise<{
	default: Record<string, string>;
}> => {
	const translation = await getTranslation(language);
	return { default: translation };
};
