import { createReducer } from '@reduxjs/toolkit';
import {
	InfoWaterProState,
	keyforAnalysisGroup,
	keyforSimulation,
	keyforTable,
	keyforTableMetadata,
	STATUS_ENUM,
} from '../types';
import {
	requestInfoWaterProAnalysisGroups,
	requestInfoWaterProAnalysisGroupsResolved,
	requestInfoWaterProAnalysisGroupsRejected,
	requestInfoWaterProSimulations,
	requestInfoWaterProSimulationsResolved,
	requestInfoWaterProSimulationsRejected,
	requestInfoWaterProTablesResolved,
	requestInfoWaterProTablesRejected,
	requestInfoWaterProTables,
	requestInfoWaterProMetadataResolved,
	requestInfoWaterProMetadataRejected,
	requestInfoWaterProMetadata,
	testOnlyInfoWaterProAnalysisGroupsLoading,
	testOnlyInfoWaterProSimulationsLoading,
	testOnlyInfoWaterProTablesLoading,
	testOnlyInfoWaterProMetadataLoading,
} from '../actions/infoWaterPro.actions';

export const initialInfoWaterProState: InfoWaterProState = {
	analysisGroups: {},
	simulations: {},
	tables: {},
	tableMetadata: {},
};

export default createReducer(initialInfoWaterProState, builder =>
	builder
		.addCase(
			requestInfoWaterProAnalysisGroupsResolved,
			(state, { payload }) => {
				const key = keyforAnalysisGroup(payload.dbid);
				state.analysisGroups[key] = {
					initialized: true,
					status: STATUS_ENUM.SUCCESS,
					data: payload.data,
				};
			},
		)
		.addCase(
			requestInfoWaterProAnalysisGroupsRejected,
			(state, { payload }) => {
				const key = keyforAnalysisGroup(payload.dbid);
				state.analysisGroups[key] = {
					initialized: true,
					status: STATUS_ENUM.ERROR,
					data: [],
					error: payload.error,
				};
			},
		)
		.addCase(requestInfoWaterProAnalysisGroups, (state, { payload }) => {
			const key = keyforAnalysisGroup(payload.dbid);
			if (!(key in state.analysisGroups)) {
				state.analysisGroups[key] = {
					initialized: true,
					status: STATUS_ENUM.PENDING,
					data: [],
				};
			}
		})
		.addCase(
			testOnlyInfoWaterProAnalysisGroupsLoading,
			(state, { payload }) => {
				const key = keyforAnalysisGroup(payload.dbid);
				state.analysisGroups[key] = {
					initialized: true,
					status: STATUS_ENUM.PENDING,
					data: [],
				};
			},
		)

		.addCase(
			requestInfoWaterProSimulationsResolved,
			(state, { payload }) => {
				const key = keyforSimulation(
					payload.dbid,
					payload.analysisGroupId,
				);

				state.simulations[key] = {
					initialized: true,
					status: STATUS_ENUM.SUCCESS,
					data: payload.data,
				};
			},
		)
		.addCase(
			requestInfoWaterProSimulationsRejected,
			(state, { payload }) => {
				const key = keyforSimulation(
					payload.dbid,
					payload.analysisGroupId,
				);
				state.simulations[key] = {
					initialized: true,
					status: STATUS_ENUM.ERROR,
					data: [],
					error: payload.error,
				};
			},
		)
		.addCase(requestInfoWaterProSimulations, (state, { payload }) => {
			if (payload.analysisGroupId === '' || payload.dbid === '') {
				return;
			}
			const key = keyforSimulation(payload.dbid, payload.analysisGroupId);
			if (!(key in state.simulations)) {
				state.simulations[key] = {
					initialized: true,
					status: STATUS_ENUM.PENDING,
					data: [],
				};
			}
		})
		.addCase(
			testOnlyInfoWaterProSimulationsLoading,
			(state, { payload }) => {
				const key = keyforSimulation(
					payload.dbid,
					payload.analysisGroupId,
				);
				state.simulations[key] = {
					initialized: true,
					status: STATUS_ENUM.PENDING,
					data: [],
				};
			},
		)

		.addCase(requestInfoWaterProTablesResolved, (state, { payload }) => {
			const key = keyforTable(payload.dbid, payload.simulationId);
			state.tables[key] = {
				initialized: true,
				status: STATUS_ENUM.SUCCESS,
				data: payload.data,
			};
		})
		.addCase(requestInfoWaterProTablesRejected, (state, { payload }) => {
			const key = keyforTable(payload.dbid, payload.simulationId);
			state.tables[key] = {
				initialized: true,
				status: STATUS_ENUM.ERROR,
				data: [],
				error: payload.error,
			};
		})
		.addCase(requestInfoWaterProTables, (state, { payload }) => {
			if (payload.simulationId === '' || payload.dbid === '') {
				return;
			}
			const key = keyforTable(payload.dbid, payload.simulationId);
			if (!(key in state.tables)) {
				state.tables[key] = {
					initialized: true,
					status: STATUS_ENUM.PENDING,
					data: [],
				};
			}
		})
		.addCase(testOnlyInfoWaterProTablesLoading, (state, { payload }) => {
			const key = keyforTable(payload.dbid, payload.simulationId);
			state.tables[key] = {
				initialized: true,
				status: STATUS_ENUM.PENDING,
				data: [],
			};
		})

		.addCase(requestInfoWaterProMetadataResolved, (state, { payload }) => {
			const key = keyforTableMetadata(
				payload.dbid,
				payload.simulationId,
				payload.tableId,
			);
			state.tableMetadata[key] = {
				initialized: true,
				status: STATUS_ENUM.SUCCESS,
				data: payload.data,
			};
		})
		.addCase(requestInfoWaterProMetadataRejected, (state, { payload }) => {
			const key = keyforTableMetadata(
				payload.dbid,
				payload.simulationId,
				payload.tableId,
			);
			state.tableMetadata[key] = {
				initialized: true,
				status: STATUS_ENUM.ERROR,
				data: { attributes: [], ids: [], timesteps: [] },
				error: payload.error,
			};
		})
		.addCase(requestInfoWaterProMetadata, (state, { payload }) => {
			if (
				payload.simulationId === '' ||
				payload.dbid === '' ||
				payload.tableId === ''
			) {
				return;
			}
			const key = keyforTableMetadata(
				payload.dbid,
				payload.simulationId,
				payload.tableId,
			);
			if (!(key in state.tableMetadata)) {
				state.tableMetadata[key] = {
					initialized: true,
					status: STATUS_ENUM.PENDING,
					data: { attributes: [], ids: [], timesteps: [] },
				};
			}
		})
		.addCase(testOnlyInfoWaterProMetadataLoading, (state, { payload }) => {
			const key = keyforTableMetadata(
				payload.dbid,
				payload.simulationId,
				payload.tableId,
			);
			state.tableMetadata[key] = {
				initialized: true,
				status: STATUS_ENUM.PENDING,
				data: { attributes: [], ids: [], timesteps: [] },
			};
		}),
);
