import { moduleNamespace as AmCommonNamespace } from '@Store/config.store';
import {
	ChartsStoreState,
	FullInspectionStoreState,
	ObservationStoreState,
	PaginationState,
	RiskConfigsListState,
} from '.';
import {
	AssetDetailStoreState,
	AssetManholeStoreState,
	AssetPipeStoreState,
	AssetRiskHistoryStoreState,
	AssetRiskStoreState,
} from './asset.types';
import { InspectionAttributeStoreState } from './inspectionAttributes.types';
import { MapStoreState } from './map.types';
import { SpatialStoreState, RiskSettingState } from './index';
import { RehabStoreState } from './rehab.types';
import { ConfigurationStoreState } from './configuration.types';
import { NamespacedStoreState as MapComponentStoreState } from '@innovyze/map-components';
import { AssetSchemaRootState } from '@innovyze/lib_asset_schema_store';
export interface StoreState {
	assetDetails?: AssetDetailStoreState;
	assetPipe?: AssetPipeStoreState;
	assetManhole?: AssetManholeStoreState;
	inspections?: InspectionAttributeStoreState;
	assetRiskHistory?: AssetRiskHistoryStoreState;
	observations: ObservationStoreState;
	map: MapStoreState;
	riskSetting: RiskSettingState;
	riskConfigs: PaginationState;
	riskConfigsList: RiskConfigsListState;
	assetRisk?: AssetRiskStoreState;
	fullInspection: FullInspectionStoreState;
	spatial: SpatialStoreState;
	RehabStore: RehabStoreState;
	charts: ChartsStoreState;
	configuration: ConfigurationStoreState;
}

export interface NamespacedStoreState extends MapComponentStoreState {
	[AmCommonNamespace]: StoreState;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type PartialStoreState = {};

export interface NamespacedPartialStoreState {
	[AmCommonNamespace]: PartialStoreState;
}

declare module 'react-redux' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultRootState
		extends NamespacedStoreState,
			AssetSchemaRootState {
		// extend any other module state here
	}
}
