import { FormControlOutline, FormControlUnderline } from './Select.styles';
import { InputLabel, MenuItem, Select } from '@mui/material';
import React, { ElementType } from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';
import {
	selectAssetTypesInSystem,
	selectLayersNamesInDataType,
} from '@Selectors/asset';
import { useGlobalization } from '@Translations/useGlobalization';
import { useSelector } from 'react-redux';

export interface AssetTypeSelectProps {
	systemType: string;
	assetType: string;
	onAssetTypeChange: SelectInputProps<string>['onChange'];
	topBar?: boolean;
	dataCy?: string;
	isSpatialDataEnabled: boolean;
}

export const AssetTypeSelect = ({
	systemType,
	assetType,
	onAssetTypeChange,
	topBar,
	dataCy,
	isSpatialDataEnabled,
}: AssetTypeSelectProps): JSX.Element => {
	const { t } = useGlobalization();
	const assetTypes = useSelector(state =>
		isSpatialDataEnabled
			? selectLayersNamesInDataType(state, systemType)
			: selectAssetTypesInSystem(state, systemType),
	);

	const FormControl: ElementType = topBar
		? FormControlUnderline
		: FormControlOutline;

	return (
		<FormControl>
			<InputLabel shrink={topBar}>
				{isSpatialDataEnabled ? t('Layer Name') : t('Asset type')}
			</InputLabel>
			<Select
				displayEmpty
				value={assetType}
				disabled={!systemType}
				onChange={onAssetTypeChange}
				data-cy={dataCy}>
				{topBar && (
					<MenuItem disabled value="">
						{isSpatialDataEnabled
							? t('Select a layer')
							: t('Select an asset type')}
					</MenuItem>
				)}
				{assetTypes?.map(assetType => (
					<MenuItem key={assetType} value={assetType}>
						{assetType}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
