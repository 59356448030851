import { createReducer } from '@reduxjs/toolkit';
import {
	addClicked,
	analysisGroupSelected,
	cancelClicked,
	openSimulationMultiSelectDialog,
	simulationsSelected,
} from '../actions/simulationMultiSelectDialog.actions';
import { SimulationMultiSelectDialogState } from '../types';

export const initialSimulationMultiSelectDialogState: SimulationMultiSelectDialogState =
	{
		open: false,
		selectedAnalysisGroup: { id: '', label: '' },
		selectedSimulations: [],
	};

const reducer = createReducer(
	initialSimulationMultiSelectDialogState,
	builder =>
		builder
			.addCase(
				analysisGroupSelected,
				(state, action): SimulationMultiSelectDialogState => {
					const newSelectedAnalysisGroup = action.payload;
					return {
						...state,
						selectedAnalysisGroup: newSelectedAnalysisGroup,
						selectedSimulations:
							newSelectedAnalysisGroup.id ===
							state.selectedAnalysisGroup.id
								? state.selectedSimulations
								: [],
					};
				},
			)
			.addCase(
				simulationsSelected,
				(state, action): SimulationMultiSelectDialogState => {
					return { ...state, selectedSimulations: action.payload };
				},
			)
			.addCase(cancelClicked, (): SimulationMultiSelectDialogState => {
				return initialSimulationMultiSelectDialogState;
			})
			.addCase(addClicked, (state): SimulationMultiSelectDialogState => {
				return { ...state, open: false };
			})
			.addCase(
				openSimulationMultiSelectDialog,
				(): SimulationMultiSelectDialogState => {
					return {
						...initialSimulationMultiSelectDialogState,
						open: true,
					};
				},
			),
);
export default reducer;
