import { FormatDateOptions } from '@innovyze/stylovyze';
import { GetFullInspResolvedParams } from '@Types/fullInspection.types';
import { InspectionStandard } from '@Types/inspection.types';
import {
	AddNoteParams,
	GetNotesParams,
	GetNotesResolvedParams,
} from '@Types/ObservationNotes.types';
import { addNamespace } from '@Utils/actions';
import { createAction } from '@reduxjs/toolkit';

export interface GetFullInspParams {
	standard: InspectionStandard | string;
	inspectionId: string;
	formatDate: (options: FormatDateOptions) => string;
	formatDateUTC: (options: FormatDateOptions) => string;
	enableMediaTranscode: boolean;
}

export const getFullInsp = createAction<GetFullInspParams>(
	addNamespace('inspection/getFullInsp'),
);

export const getFullInspResolved = createAction<GetFullInspResolvedParams>(
	addNamespace('inspection/getFullInspResolved'),
);

export const getFullInspRejected = createAction(
	addNamespace('inspection/getFullInspRejected'),
);

export const clearFullInsp = createAction(
	addNamespace('inspection/clearFullInsp'),
);

export const getNotes = createAction<GetNotesParams>(
	addNamespace('inspection/getFullInspNotes'),
);

export const getNotesResolved = createAction<GetNotesResolvedParams>(
	addNamespace('inspection/getFullInspNotesResolved'),
);

export const getNotesRejected = createAction(
	addNamespace('inspection/getFullInspNotesRejected'),
);

export const addNote = createAction<AddNoteParams>(
	addNamespace('inspection/addNote'),
);

export const addNoteRejected = createAction(
	addNamespace('inspection/addNoteRejected'),
);

export const setFullInspectionIsWaiting = createAction<boolean>(
	addNamespace('inspection/isWaiting'),
);

export const setNotesIsWaiting = createAction<boolean>(
	addNamespace('inspection/notesIsWaiting'),
);
