import {
	GenericSinglePropertyType,
	GenericSingleProperty,
	SingleProperty,
} from '@innovyze/lib_asset_schema';
import { FieldType } from '@Types/map.types';

type FieldTypeMapping = {
	fieldType: FieldType;
	precision?: number;
	multiline?: boolean;
};

const numberMapper = (defaultPrecision: number) => (
	property: GenericSingleProperty,
): FieldTypeMapping => ({
	fieldType: FieldType.Number,
	precision: property.precision ?? defaultPrecision,
});

const textMapper = (multiline?: boolean) => (): FieldTypeMapping => ({
	fieldType: FieldType.String,
	multiline,
});

const FIELD_TYPE_MAPPING: Record<
	GenericSinglePropertyType,
	(property: GenericSingleProperty) => FieldTypeMapping
> = {
	Text: textMapper(),
	Integer: numberMapper(0),
	'Long Integer': numberMapper(0),
	Double: numberMapper(2),
	Boolean: () => ({ fieldType: FieldType.Boolean }),
	Memo: textMapper(true),
	'Date / Time': () => ({ fieldType: FieldType.Date }),
};

export const getFieldTypeMapping = (
	property: SingleProperty,
): FieldTypeMapping => {
	const mapper = FIELD_TYPE_MAPPING[property.type] ?? textMapper();

	return mapper(property);
};
