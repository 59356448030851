import * as DataFinder from '../DataFinder';
import * as Mui from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import * as React from 'react';
import { getSelectionByCategory } from './DataFinderUtils';
import { SimulationDataObject } from '../SimulationDataFinderDialog';
import { SelectionRules } from './SelectionRules';

export type SelectionAction = 'add' | 'replace' | 'remove';

export const INPUT_PROPS: Mui.InputProps = {
	readOnly: true,
	endAdornment: <MuiIcons.CalendarViewWeekOutlined />,
};

export const INPUT_LABEL_PROPS: Mui.InputLabelProps = { shrink: true };

/**
 * These rules determine what are the prerequisite elements for each category of the selection.
 * For example, to get a list of simulations, you must first select an analysis group.
 * To get a list of output types, you must first select a model element type, simulation, and analysis group.
 */
export const selectionRules = new SelectionRules([
	{
		id: 'object-id',
		dependsOn: [
			'output-type',
			'model-element-type',
			'simulation',
			'analysis-group',
		],
	},
	{
		id: 'output-type',
		dependsOn: ['model-element-type', 'simulation', 'analysis-group'],
	},
	{
		id: 'model-element-type',
		dependsOn: ['simulation', 'analysis-group'],
	},
	{
		id: 'simulation',
		dependsOn: ['analysis-group'],
	},
	{
		id: 'analysis-group',
		dependsOn: [],
	},
]);

/**
 * determine if two selections are the same
 *
 * @param a first selection to compare
 * @param b second selection to compare
 * @returns true if the selections are the same, false otherwise
 */
export function isSameSelection(
	a: DataFinder.Selection,
	b: DataFinder.Selection,
): boolean {
	return a.categoryId === b.categoryId && a.optionId === b.optionId;
}

/**
 * determine if two selections have the same category
 *
 * @param a first selection to compare
 * @param b second selection to compare
 * @returns true if the selections have the same category, false otherwise
 */
export function hasSameCategory(
	a: DataFinder.Selection,
	b: DataFinder.Selection,
): boolean {
	return a.categoryId === b.categoryId;
}

/**
 * Determine whether to add remove or replace a selection.
 *
 * @param prevSelections the list of existing selections
 * @param nextSelection the new selection being clicked by the user
 * @returns 'remove' if the selection is already in the list, 'replace' if there is an existing selection with the same category, 'add' otherwise.
 */
export function getSelectionAction(
	prevSelections: DataFinder.Selection[],
	nextSelection: DataFinder.Selection,
): SelectionAction {
	for (const prevSelection of prevSelections) {
		if (isSameSelection(prevSelection, nextSelection)) return 'remove';
		else if (hasSameCategory(prevSelection, nextSelection))
			return 'replace';
	}

	return 'add';
}

/**
 * Generates a new array of selections based off an `action`.
 *
 * For example:
 *  - If the action is `add`, a new entry will be added to the previous selections.
 *  - If the action is `remove`, we remove it from the previous selections.
 *  - If the action is `replace`, we replace the previos selection that matches.
 *
 * We should also use this function to do extra clean up, for instace, we should remove
 * all selections that depend on a removed or replaced selection.
 * Or maybe we want to prevent the action if there are dependent selections.
 *
 * @param action The action that needs to be applied
 * @param prevSelections An array of previous selections
 * @param nextSelection The selection that will be applied
 * @returns
 */
export function getNextSelections(
	action: SelectionAction,
	prevSelections: DataFinder.Selection[],
	nextSelection: DataFinder.Selection,
): DataFinder.Selection[] {
	if (action === 'add') {
		return [...prevSelections, nextSelection];
	} else if (action === 'remove') {
		const filtered = prevSelections
			.filter(prevSelection => {
				return !isSameSelection(prevSelection, nextSelection);
			})
			.filter(
				prevSelection =>
					!selectionRules.dependsOn(
						prevSelection.categoryId,
						nextSelection.categoryId,
					),
			);

		return filtered;
	} else if (action === 'replace') {
		const filtered = prevSelections
			.map(prevSelection => {
				return hasSameCategory(prevSelection, nextSelection)
					? nextSelection
					: prevSelection;
			})
			.filter(
				prevSelection =>
					!selectionRules.dependsOn(
						prevSelection.categoryId,
						nextSelection.categoryId,
					),
			);

		return filtered;
	}

	return prevSelections;
}

/**
 * determine if the user has selected enough items to form a valid selection
 * note: This implements a simple rule that at least one selection must be made for each category
 * If more complex rules are needed in the future, those can be captured here, possibly with configuration in the `SELECTION_RULES` array
 *
 * @param selections the current selections
 * @returns true if the selections are valid, false otherwise
 */
export function validSelection(selections: DataFinder.Selection[]): boolean {
	return selectionRules.validSelection(selections);
}

/**
 * Generate a list of simulation data objects from the current selections.
 * This performs the opposite function of `parseSimulationObjects`.
 * note: this currently only supports a single selection for each category.
 *
 * @param dbid the database ID
 * @param selections an array of the current selections
 * @returns a list of `SimulationDataObjects` corresponding to the current selections
 */
export function generateSimulationObjects(
	dbid: string,
	selections: DataFinder.Selection[],
): SimulationDataObject[] {
	const analysisGroup = getSelectionByCategory(selections, 'analysis-group');
	const result = getSelectionByCategory(selections, 'simulation');
	const table = getSelectionByCategory(selections, 'model-element-type');
	const object = getSelectionByCategory(selections, 'object-id');
	const attribute = getSelectionByCategory(selections, 'output-type');

	if (!analysisGroup || !result || !table || !object || !attribute) {
		return [];
	}

	return [
		{
			database_id: dbid,
			analysis_group_id: analysisGroup.optionId,
			result_id: result.optionId,
			table_id: table.optionId,
			object_id: object.optionId,
			attribute: attribute.optionId,
		},
	];
}

/** Generate a list of selections from a list of SimulationDataObjects.
 * This performs the opposite function of `generateSimulationObjects`.
 * note: This only supports a single selection for now
 *
 * @param simulationDataObjects a list of SimulationDataObjects
 * @returns an array of selections corresponding to the given SimulationDataObjects
 */
export function parseSimulationObjects(
	simulationDataObjects: (
		| Partial<SimulationDataObject>
		| SimulationDataObject
	)[],
): DataFinder.Selection[] {
	const [simulationDataObject] = simulationDataObjects;

	if (!simulationDataObject) {
		return [];
	}

	const selection = [] as DataFinder.Selection[];
	selection.push({
		categoryId: 'analysis-group',
		optionId: simulationDataObject.analysis_group_id ?? '',
	});
	selection.push({
		categoryId: 'simulation',
		optionId: simulationDataObject.result_id ?? '',
	});
	if (simulationDataObject.table_id) {
		selection.push({
			categoryId: 'model-element-type',
			optionId: simulationDataObject.table_id,
		});
	}
	if (simulationDataObject.object_id) {
		selection.push({
			categoryId: 'object-id',
			optionId: simulationDataObject.object_id,
		});
	}
	if (simulationDataObject.attribute) {
		selection.push({
			categoryId: 'output-type',
			optionId: simulationDataObject.attribute,
		});
	}

	return selection;
}
