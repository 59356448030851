import React, {
	createContext,
	useRef,
	useEffect,
	useState,
	useMemo,
} from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import cookies from 'browser-cookies';
import {
	authorizerTokenCookie,
	authorizerTokenCookieExpirationDate,
	forgeAccessTokenExpiration,
	forgeRefreshTokenCookie,
	forgeRevalidationCookie,
} from '../types/authenticationContext.types';
import { Events } from '../utils/sharedWorkers.types';
import { DateTime } from 'luxon';
import { useLogoutContext } from '../contexts/AuthenticationWrapper/LogoutContext';
import { sanitizeSharedWorkerMessageData } from './sharedWorkerProviderUtils';

const ReauthSharedWorkerRefContext = createContext<{
	workerRef: React.MutableRefObject<SharedWorker | null>;
	modalOpen: boolean;
	expirationTime: number;
	openModal: () => void;
	initializeExtendSession: () => void;
	dispatchCloseSession: () => void;
	hasSharedWorkers: boolean;
}>({
	workerRef: { current: null },
	modalOpen: false,
	expirationTime: 0,
	openModal: () => void 0,
	initializeExtendSession: () => void 0,
	dispatchCloseSession: () => void 0,
	hasSharedWorkers: false,
});

export const useReauthSharedWorkerProvider = () =>
	React.useContext(ReauthSharedWorkerRefContext);

export interface SharedWorkerProviderProps {
	readonly children: React.ReactNode;
	readonly workerUrl?: string;
	readonly workerName?: string;
	readonly hasSharedWorkers?: boolean;
}

export default function SharedWorkerProvider({
	children,
	workerUrl = '/workers/forge.sharedworker.js',
	workerName = 'forgeWorker',
	hasSharedWorkers = true,
}: SharedWorkerProviderProps) {
	const workerRef = useRef<SharedWorker | null>(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [expirationTime, setExpirationTime] = useState(1000);

	const { logout } = useLogoutContext();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const postMessage = (type: string, data?: any) => {
		workerRef.current?.port.postMessage({ type, data });
	};

	useEffect(() => {
		if (workerUrl) {
			workerRef.current = new SharedWorker(workerUrl, {
				type: 'module',
				name: workerName,
			});
		}

		if (workerRef.current) {
			workerRef?.current?.port.start();

			const handleMessage = (event: {
				data: {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					data: any;
					type: Events;
					extraAttributes?: Record<string, unknown>;
				};
			}) => {
				const { data = {}, type, extraAttributes } = event.data;
				const sanitizedPayload = sanitizeSharedWorkerMessageData(data);

				if (type !== Events.DATADOG_LOG) {
					console.warn('Shared Worker response: ', {
						type,
						timestamp: new Date().toISOString(),
						sanitizedData: sanitizedPayload,
					});
				}

				datadogLogs.logger.warn(
					'Shared Worker Message Caught: ' + type,
					{
						payload: sanitizedPayload,
						messageType: type,
						extraAttributes: extraAttributes as Record<
							string,
							string
						>,
					},
				);

				if (type) {
					let expiresTimeInSeconds = 120;
					let expirationDate = '';
					switch (type) {
						case Events.PROPAGATE_FORGE_TOKEN_UPDATED:
							expiresTimeInSeconds =
								data.expires_in ?? expiresTimeInSeconds;
							expirationDate = DateTime.now()
								.plus({ seconds: +(expiresTimeInSeconds || 0) })
								.toISO() as string;
							cookies.erase(authorizerTokenCookie);
							cookies.set(
								authorizerTokenCookie,
								data.access_token,
							);
							cookies.erase(forgeRefreshTokenCookie);
							cookies.set(
								forgeRefreshTokenCookie,
								data.refresh_token,
							);
							cookies.erase(forgeAccessTokenExpiration);
							cookies.set(
								authorizerTokenCookieExpirationDate,
								expirationDate,
							);
							cookies.erase(forgeRevalidationCookie);
							cookies.set(
								forgeAccessTokenExpiration,
								expirationDate,
							);

							break;
						case Events.PROPAGATE_SESSION_FINISH_TIME:
							expiresTimeInSeconds =
								data.expires_in ?? expiresTimeInSeconds;
							expirationDate = DateTime.now()
								.plus({
									seconds: +expiresTimeInSeconds,
								})
								.toISO() as string;

							break;

						case Events.PROPAGATE_OPEN_MODAL:
							setModalOpen(true);
							setExpirationTime(data);
							break;

						case Events.PROPAGATE_LOGOUT:
							logout.current?.();
							break;
						case Events.PROPAGATE_CLOSE_MODAL:
							setModalOpen(false);
							break;

						default:
							break;
					}
				}
			};

			workerRef?.current?.port.addEventListener('message', handleMessage);

			return () => {
				workerRef?.current?.port.removeEventListener(
					'message',
					handleMessage,
				);
				workerRef?.current?.port.close();
			};
		}
	}, [workerUrl]);

	const initData = () => {
		if (workerRef.current) {
			if (cookies.get(forgeRefreshTokenCookie)) {
				const payload = {
					accessToken: cookies.get(authorizerTokenCookie),
					refresh_token: cookies.get(forgeRefreshTokenCookie),
					refreshExpirationDate: cookies.get(forgeRevalidationCookie),
					finalExpirationDate: '',

					expirationDate: cookies.get(forgeAccessTokenExpiration),
					revalidationDate: cookies.get(forgeRevalidationCookie),
				};
				postMessage(Events.INIT_FORGE_DATA, payload);
			}
		}
	};

	useEffect(() => {
		const timeout = setTimeout(() => initData(), 1000);
		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		if (workerRef?.current?.port) {
			setTimeout(() => {
				const payload = {
					accessToken: cookies.get(authorizerTokenCookie),
					expirationDate: cookies.get(
						authorizerTokenCookieExpirationDate,
					),
					finalExpirationDate: '',
					redirectUri: window.location.origin,
				};
				postMessage(Events.INIT_AUTH0_DATA, payload);
			}, 1000);
		}
	}, []);

	const openModal = () => {
		postMessage(Events.PROPAGATE_OPEN_MODAL);
	};

	const initializeExtendSession = () => {
		const payload = {} as { expirationDate?: string };
		const expirationDate = cookies.get(authorizerTokenCookieExpirationDate);
		if (expirationDate) payload.expirationDate = expirationDate;
		postMessage(Events.INIT_EXTEND_SESSION, payload);
	};

	const dispatchCloseSession = () => postMessage(Events.DO_LOGOUT);

	const contextValue = useMemo(
		() => ({
			workerRef,
			modalOpen,
			expirationTime,
			openModal,
			initializeExtendSession,
			dispatchCloseSession,
			hasSharedWorkers,
		}),
		[
			workerRef,
			modalOpen,
			expirationTime,
			openModal,
			initializeExtendSession,
			dispatchCloseSession,
			hasSharedWorkers,
		],
	);

	React.useEffect(() => {
		console.log('Shared Worker Provider mounted');
	}, []);

	return (
		<ReauthSharedWorkerRefContext.Provider value={contextValue}>
			{children}
		</ReauthSharedWorkerRefContext.Provider>
	);
}
