import { DSTable, DSTableMetadata, DSTreeObject } from './domainServices.types';
import { DataContainer } from './reducers.types';

// a generic lookup type
export type Lookup<T> = {
	[key: string]: T;
};

// lookup analysis groups by dbid
export type AnalysisGroupLookup = Lookup<DataContainer<DSTreeObject[]>>;

/**
 * Produce a key to look up/modify items in AnalysisGroupLookup.
 *
 * @param dbid database ID
 * @returns a key to look up analysis groups by dbid
 */
export function keyforAnalysisGroup(dbid: string): string {
	return dbid;
}

// lookup simulations by dbid and analysis group id
export type SimulationLookup = Lookup<DataContainer<DSTreeObject[]>>;

/**
 * Produce a composite key to look up/modify items in SimulationLookup.
 *
 * @param dbid database ID
 * @param analysisGroupId analysis group ID
 * @returns a key to look up simulations by dbid and analysis group ID
 */
export function keyforSimulation(
	dbid: string,
	analysisGroupId: string,
): string {
	return JSON.stringify({ dbid, analysisGroupId });
}

// lookup tables by dbid and simulation id
export type TableLookup = Lookup<DataContainer<DSTable[]>>;

/**
 * Produce a composite key to look up/modify items in TableLookup.
 *
 * @param dbid database ID
 * @param simulationId simulation ID
 * @returns a key to look up tables by dbid and simulation ID
 */
export function keyforTable(dbid: string, simulationId: string): string {
	return JSON.stringify({ dbid, simulationId });
}

export type TableMetadataLookup = Lookup<DataContainer<DSTableMetadata>>;

/* Produce a composite key to look up/modify items in TableMetadataLookup.
 *
 * @param dbid database ID
 * @param simulationId simulation ID
 * @param tableId table ID
 * @returns a key to look up table metadata by dbid, simulation ID, and table ID
 */
export function keyforTableMetadata(
	dbid: string,
	simulationId: string,
	tableId: string,
): string {
	return JSON.stringify({ dbid, simulationId, tableId });
}

export type InfoWaterProState = {
	analysisGroups: AnalysisGroupLookup;
	simulations: SimulationLookup;
	tables: TableLookup;
	tableMetadata: TableMetadataLookup;
};
