// THIS FILE IS AUTO-GENERATED BY PREBUILD SCRIPT - DO NOT EDIT

import wwFlume from './SanitarySewer/wwFlume.yaml';
import wwLateralPipe from './SanitarySewer/wwLateralPipe.yaml';
import wwManhole from './SanitarySewer/wwManhole.yaml';
import wwOrifice from './SanitarySewer/wwOrifice.yaml';
import wwOutfall from './SanitarySewer/wwOutfall.yaml';
import wwOutlet from './SanitarySewer/wwOutlet.yaml';
import wwPipe from './SanitarySewer/wwPipe.yaml';
import wwPump from './SanitarySewer/wwPump.yaml';
import wwPumpStation from './SanitarySewer/wwPumpStation.yaml';
import wwRoddingEye from './SanitarySewer/wwRoddingEye.yaml';
import wwScreen from './SanitarySewer/wwScreen.yaml';
import wwSiphon from './SanitarySewer/wwSiphon.yaml';
import wwSluice from './SanitarySewer/wwSluice.yaml';
import wwStorage from './SanitarySewer/wwStorage.yaml';
import wwValve from './SanitarySewer/wwValve.yaml';
import wwVortex from './SanitarySewer/wwVortex.yaml';
import wwWeir from './SanitarySewer/wwWeir.yaml';

import swCatchBasin from './Stormwater/swCatchBasin.yaml';
import swCatchpit from './Stormwater/swCatchpit.yaml';
import swChannel from './Stormwater/swChannel.yaml';
import swCulvert from './Stormwater/swCulvert.yaml';
import swGully from './Stormwater/swGully.yaml';
import swInlet from './Stormwater/swInlet.yaml';
import swLateralPipe from './Stormwater/swLateralPipe.yaml';
import swLinearDrain from './Stormwater/swLinearDrain.yaml';
import swManhole from './Stormwater/swManhole.yaml';
import swOutfall from './Stormwater/swOutfall.yaml';
import swOutlet from './Stormwater/swOutlet.yaml';
import swPipe from './Stormwater/swPipe.yaml';
import swPump from './Stormwater/swPump.yaml';
import swPumpStation from './Stormwater/swPumpStation.yaml';
import swRoddingEye from './Stormwater/swRoddingEye.yaml';
import swScreen from './Stormwater/swScreen.yaml';
import swSluice from './Stormwater/swSluice.yaml';
import swStorage from './Stormwater/swStorage.yaml';
import swValve from './Stormwater/swValve.yaml';
import swVortex from './Stormwater/swVortex.yaml';
import swWeir from './Stormwater/swWeir.yaml';

import control_valve from './WaterDistribution/control_valve.yaml';
import fitting from './WaterDistribution/fitting.yaml';
import fixed_head from './WaterDistribution/fixed_head.yaml';
import float_valve from './WaterDistribution/float_valve.yaml';
import general_asset from './WaterDistribution/general_asset.yaml';
import generator from './WaterDistribution/generator.yaml';
import hydrant from './WaterDistribution/hydrant.yaml';
import manhole from './WaterDistribution/manhole.yaml';
import meter from './WaterDistribution/meter.yaml';
import non_return_valve from './WaterDistribution/non_return_valve.yaml';
import other_valve from './WaterDistribution/other_valve.yaml';
import pipe from './WaterDistribution/pipe.yaml';
import pump from './WaterDistribution/pump.yaml';
import pump_station from './WaterDistribution/pump_station.yaml';
import service_valve from './WaterDistribution/service_valve.yaml';
import tank from './WaterDistribution/tank.yaml';
import well from './WaterDistribution/well.yaml';
import wtw from './WaterDistribution/wtw.yaml';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const yamlMapping: { [key: string]: { [key: string]: any } } = {
	SanitarySewer: {
		wwFlume: wwFlume,
		wwLateralPipe: wwLateralPipe,
		wwManhole: wwManhole,
		wwOrifice: wwOrifice,
		wwOutfall: wwOutfall,
		wwOutlet: wwOutlet,
		wwPipe: wwPipe,
		wwPump: wwPump,
		wwPumpStation: wwPumpStation,
		wwRoddingEye: wwRoddingEye,
		wwScreen: wwScreen,
		wwSiphon: wwSiphon,
		wwSluice: wwSluice,
		wwStorage: wwStorage,
		wwValve: wwValve,
		wwVortex: wwVortex,
		wwWeir: wwWeir,
	},
	Stormwater: {
		swCatchBasin: swCatchBasin,
		swCatchpit: swCatchpit,
		swChannel: swChannel,
		swCulvert: swCulvert,
		swGully: swGully,
		swInlet: swInlet,
		swLateralPipe: swLateralPipe,
		swLinearDrain: swLinearDrain,
		swManhole: swManhole,
		swOutfall: swOutfall,
		swOutlet: swOutlet,
		swPipe: swPipe,
		swPump: swPump,
		swPumpStation: swPumpStation,
		swRoddingEye: swRoddingEye,
		swScreen: swScreen,
		swSluice: swSluice,
		swStorage: swStorage,
		swValve: swValve,
		swVortex: swVortex,
		swWeir: swWeir,
	},
	WaterDistribution: {
		control_valve: control_valve,
		fitting: fitting,
		fixed_head: fixed_head,
		float_valve: float_valve,
		general_asset: general_asset,
		generator: generator,
		hydrant: hydrant,
		manhole: manhole,
		meter: meter,
		non_return_valve: non_return_valve,
		other_valve: other_valve,
		pipe: pipe,
		pump: pump,
		pump_station: pump_station,
		service_valve: service_valve,
		tank: tank,
		well: well,
		wtw: wtw,
	},
};

export default yamlMapping;

// THIS FILE IS AUTO-GENERATED BY PREBUILD SCRIPT - DO NOT EDIT
