import { createAction } from '@reduxjs/toolkit';
import { DSDatabase } from '../types/domainServices.types';

/**
 * An action to request the databases from the Domain Services API
 */
export const requestDomainServicesDatabases = createAction(
	'stylovyze/domainServices/requestDatabases',
);

export const requestDomainServicesDatabasesResolved = createAction<
	DSDatabase[]
>('stylovyze/domainServices/requestDatabases/resolved');

export const requestDomainServicesDatabasesRejected = createAction(
	'stylovyze/domainServices/requestDatabases/rejected',
);

/**
 * An action to set the state to loading.  This should only be used for testing.
 * It behaves like requestDomainServicesDatabases, but does not trigger the saga.
 */
export const testOnlyDomainServicesDatabasesLoading = createAction(
	'stylovyze/domainServices/requestDatabases/testOnlyLoading',
);
