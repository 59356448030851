import { ConnectedHistoricalChart, Resolutions } from '@innovyze/summaryze';
import React, { Dispatch, SetStateAction } from 'react';

import { StylovyzeDialog } from '@innovyze/stylovyze';
import styled from 'styled-components';
import { useGlobalization } from '@Translations/useGlobalization';

interface Props {
	title: string;
	id: string;
	alias: string;
	modalOpen: boolean;
	closeModal: () => void;
	setWorkspaceModalOpen: Dispatch<SetStateAction<boolean>>;
	goToSensorEdit?: () => void;
}

const StyledConnectedHistoricalChart = styled.div`
	min-width: 30rem;
	margin: auto;
`;

export default function SensorHistoryDialog({
	title,
	id,
	alias,
	modalOpen,
	closeModal,
	setWorkspaceModalOpen,
}: Props): React.ReactElement {
	const { t } = useGlobalization();
	const resolution = Resolutions['15 Minutes'];
	return (
		<StylovyzeDialog
			title={title}
			open={modalOpen}
			cancelText={t('Close', {
				context: 'Sensor history dialog close button',
			})}
			confirmText={t('Add to Workspace', {
				context: 'Sensor history dialog add to workspace button',
			})}
			onConfirm={() => {
				setWorkspaceModalOpen(true);
			}}
			onCancel={() => {
				closeModal();
			}}>
			<StyledConnectedHistoricalChart>
				<ConnectedHistoricalChart
					id={id}
					displayOptions={{}}
					primarySeries={{
						id,
						alias,
						displayOptions: {},
						func: 'Average',
						resolution,
						unit: '',
					}}
					separateYAxes={[]}
				/>
			</StyledConnectedHistoricalChart>
		</StylovyzeDialog>
	);
}
