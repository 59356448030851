import { Capability } from '../types/capabilities.types';
import { SystemType, SystemTypeRaw } from '../types/system.types';

export class SystemTypeImpl implements SystemType {
	id: string;
	displayName: string;
	defaultColour: string;
	assetTypes: string[];
	private readonly capabilities: string[];

	constructor(params: SystemTypeRaw) {
		this.id = params.SystemType;
		this.displayName = params.DisplayName;
		this.defaultColour = params.DefaultColour;
		this.assetTypes = params.AssetTypes;

		this.capabilities = [];
		if (params.Risk) {
			this.capabilities.push('Risk'.toLowerCase());
		}
		if (params.Rehab) {
			this.capabilities.push('Rehab'.toLowerCase());
		}
	}

	hasCapability(capability: Capability): boolean {
		return this.capabilities.includes(capability.toLowerCase());
	}
}
