import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

export interface FilterSaveDialogProps {
	children: JSX.Element;
	onSave?(name: string): void | Promise<void>;
}

export const FilterSaveDialog = ({
	children,
	onSave,
}: FilterSaveDialogProps): JSX.Element => {
	const [open, setOpen] = useState(false);
	const [inputError, setInputError] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [filterName, setFilterName] = useState('');

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setInputError(false);
		setDisabled(false);
		setFilterName('');
	};

	const handleSave = async () => {
		setInputError(!filterName);
		if (!filterName) return;
		try {
			setDisabled(true);
			if (onSave) await onSave(filterName);
			handleClose();
		} catch (err) {
			setDisabled(false);
		}
	};

	return (
		<React.Fragment>
			{React.cloneElement(children, { onClick: handleOpen })}
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					Save Filter As..
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={theme => ({
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme.palette.grey[500],
					})}>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<TextField
						autoFocus
						label="Filter Name"
						variant="outlined"
						value={filterName}
						error={inputError}
						onChange={e => setFilterName(e.target.value)}
						style={{ width: '400px' }}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						disabled={disabled}
						aria-label="save"
						onClick={handleSave}
						startIcon={<SaveIcon />}>
						Save
					</Button>
				</DialogActions>
			</BootstrapDialog>
		</React.Fragment>
	);
};
