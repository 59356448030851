import { Store } from '@reduxjs/toolkit';
class SharedStore {
	_store: Store | null = null;

	set store(store: Store | null) {
		this._store = store;
	}

	get store(): Store | null {
		return this._store;
	}
}

export default new SharedStore();
