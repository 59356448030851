import { Option } from '../components/SimulationDataFinderDialog/DataFinder';
import { DataContainer } from './reducers.types';

export interface DSDatabase {
	dbid: string;
	dbname: string;
}

export enum DSObjectType {
	ModelGroup = 1,
	InfoWaterSimulation = 227,
}

export interface DSTreeObject {
	oid: number;
	otype: DSObjectType;
	parentoid: number;
	oname: string;
}

export const optionFromDSTreeObject = (obj: DSTreeObject): Option => ({
	id: String(obj.oid),
	label: obj.oname,
});

export const optionFromDSTableObject = (obj: DSTable): Option => ({
	id: obj.id,
	label: obj.className ?? obj.id,
});

/**
 * A table provides information for a particular type of element
 * in the simulation, such as the pipes, junctions, or reservoirs.
 *
 * The id is the unique identifier for the table.
 * The className is a human readable name for the type of model element.
 *
 * attributes, names and units are arrays that provide information
 * about the different types of simulation data collected for this particular type of element.
 *
 * An attribute is a unique ID used to identify what quantity is being simulated.  For example, if the
 * table is for pipes, the attributes might be 'flow', 'velocity', 'headloss'.
 *
 * A name is a human readable name for each attribute using the same index as the attribute.
 *
 * A unit is the unit of measure for each attribute using the same index as the attribute. For
 * example, if the table is for pipes, the units might be 'cfs' corresponding to flow, 'fps'
 * corresponding to velocity and 'ft' corresponding to headloss.
 *
 */
export interface DSTable {
	attributes: string[];
	className: string;
	id: string;
	names: string[];
	units: string[];
}

/**
 * A DSTableMetadata object provides information about the attributes, ids, and timesteps
 * for a particular table.
 *
 * The attributes array provides the unique identifiers for each attribute in the table.
 * These should be the same as the attributes listed in the corresponding DSTable object.
 * The ids array provides a list of all of the elements of this type in the simulation.
 * The timesteps array provides a list of all the timespamps for which a simulation value is available.
 */
export interface DSTableMetadata {
	attributes: string[];
	ids: string[];
	timesteps: string[];
}

export interface DomainServicesState {
	databases: DataContainer<DSDatabase[]>;
}
