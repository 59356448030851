import {
	GenericSingleProperty,
	GenericSinglePropertyType,
	NestedProperty,
	Property,
	PropertyRaw,
	SingleProperty,
} from '../types/asset.types';
import { Capabilities, Capability } from '../types/capabilities.types';

export const newProperty = (params: PropertyRaw): Property => {
	if (params.Type === 'List') {
		return new NestedPropertyImpl(params);
	} else {
		return new GenericSinglePropertyImpl(params as PropertySchemaParams);
	}
};

export type PropertySchemaParams = PropertyRaw & {
	[key: string]: unknown;
};
export class GenericSinglePropertyImpl implements GenericSingleProperty {
	id: string;
	type: GenericSinglePropertyType;
	displayName: string;
	subType?: string;
	subSubType?: string;
	precision?: number;
	units?: string;
	unitsDescription?: string;
	unitsCode?: string;
	deprecatedFieldName?: string;
	category?: string;
	required: boolean;
	private capabilities: string[];

	constructor(params: PropertySchemaParams) {
		this.id = params.Property;
		this.deprecatedFieldName = params.DeprecatedFieldName;
		this.type = params.Type as GenericSinglePropertyType;
		this.subType = params.SubType;
		this.subSubType = params.SubSubType;
		this.displayName = params.DisplayName;
		this.precision = params.Precision;
		this.units = params.Units;
		this.unitsDescription = params.UnitsDescription;
		this.unitsCode = params.UnitsCode;
		this.category = params.Category;
		this.required = params.Required === true;

		this.capabilities = [];

		for (const capability of Capabilities) {
			if (params[capability]) {
				this.capabilities.push(capability);
			}
		}
	}

	hasCapability(capability: Capability): boolean {
		return this.capabilities.includes(capability);
	}
}

export class NestedPropertyImpl implements NestedProperty {
	type: 'List';
	properties: SingleProperty[];
	id: string;
	deprecatedFieldName?: string;
	displayName: string;
	category?: string;
	required: boolean;

	constructor(params: PropertyRaw) {
		this.id = params.Property;
		this.deprecatedFieldName = params.DeprecatedFieldName;
		this.type = 'List';
		this.displayName = params.DisplayName;
		this.category = params.Category;
		this.required = params.Required === true;
		this.properties =
			params.Properties?.map(rawProperty =>
				newProperty(rawProperty),
			).filter(p => p.type !== 'List') || [];
	}

	private convertToPropertyRaw(property: SingleProperty): PropertyRaw {
		return {
			Property: property.id,
			DeprecatedFieldName: property.deprecatedFieldName,
			Type: property.type,
			SubType: property.subType,
			SubSubType: property.subSubType,
			DisplayName: property.displayName,
			Precision: property.precision,
			Units: property.units,
			UnitsDescription: property.unitsDescription,
			UnitsCode: property.unitsCode,
			Lof: property.hasCapability('Lof'),
			Cof: property.hasCapability('Cof'),
			Rehab: property.hasCapability('Rehab'),
			ShowOnMapPanel: property.hasCapability('ShowOnMapPanel'),
			Category: property.category,
		};
	}

	copyWithNewProperties(properties: SingleProperty[]): NestedProperty {
		return new NestedPropertyImpl({
			Property: this.id,
			DeprecatedFieldName: this.deprecatedFieldName,
			Type: this.type,
			DisplayName: this.displayName,
			Category: this.category,
			Properties: properties.map(this.convertToPropertyRaw),
		});
	}
}
