import {
	AssetSchema,
	AssetSchemaRaw,
	GeometryType,
	Property,
	SingleProperty,
} from '../types/asset.types';
import {
	Capabilities,
	Capability,
	InspectionTypes,
} from '../types/capabilities.types';

import { SystemType } from '../types/system.types';
import { newProperty } from './property.class';

type AssetCapabilityFilterParam =
	| {
			capability: Capability;
	  }
	| {
			showOnMapPanel: boolean;
	  };

export type AssetSchemaParams = Omit<AssetSchemaRaw, 'SystemType'> & {
	SystemType: SystemType;
	[key: string]: unknown;
};
export class AssetSchemaImpl implements AssetSchema {
	systemType: SystemType;
	id: string;
	displayName: string;
	pluralName: string;
	defaultColour: string;
	defaultIcon?: string;
	defaultMinZoom: number;
	geometryType: GeometryType;
	categories: string[];
	private readonly properties: Property[];
	private readonly capabilities: Capability[];
	private readonly inspectionTypes: InspectionTypes[];

	constructor(params: AssetSchemaParams) {
		this.systemType = params.SystemType;
		this.id = params.AssetType;
		this.displayName = params.DisplayName;
		this.pluralName = params.PluralName;
		this.geometryType = params.GeometryType;
		this.properties = [];
		for (const rawProperty of params.Properties ?? []) {
			this.properties.push(newProperty(rawProperty));
		}
		this.defaultColour = params.DefaultColour ?? '#000000';
		this.defaultIcon = params.DefaultIcon;
		this.defaultMinZoom = params.DefaultMinZoom ?? 16;
		this.categories = params.Categories ?? [];
		this.capabilities = [];
		this.inspectionTypes = params.Inspections ?? [];

		for (const capability of Capabilities) {
			if (params[capability]) {
				this.capabilities.push(capability);
			}
		}
	}

	get fullDisplayName(): string {
		const assetDisplayName = this.displayName;
		const systemDisplayName = this.systemType.displayName;
		return `${systemDisplayName} - ${assetDisplayName}`;
	}

	hasCapability(capability: Capability): boolean {
		return this.capabilities.includes(capability);
	}

	getInspectionTypes(): string[] {
		return this.inspectionTypes;
	}

	hasInspectionType(inspectionType: InspectionTypes): boolean {
		return this.inspectionTypes.includes(inspectionType);
	}

	getProperties(filter?: AssetCapabilityFilterParam): Property[] {
		if (!filter) {
			return this.properties;
		}
		const checkFilter = (property: SingleProperty): boolean => {
			if ('capability' in filter) {
				return property.hasCapability(filter.capability);
			} else if ('showOnMapPanel' in filter) {
				return (
					property.hasCapability('ShowOnMapPanel') ===
					filter.showOnMapPanel
				);
			}

			return false;
		};
		const properties = [];
		for (const property of this.properties) {
			if (property.type !== 'List') {
				if (checkFilter(property)) {
					properties.push(property);
				}
			} else {
				const nestedProperties = [];
				for (const nestedProperty of property.properties) {
					if (checkFilter(nestedProperty)) {
						nestedProperties.push(nestedProperty);
					}
				}
				if (nestedProperties.length > 0) {
					properties.push(
						property.copyWithNewProperties(nestedProperties),
					);
				}
			}
		}
		return properties;
	}
}
