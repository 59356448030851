import {
	AutodeskContext,
	AutodeskTeams,
	AutodeskUserProfile,
} from '../../../types/forge';
import {
	getAutodeskTeams,
	getAutodeskUserProfile,
	retriedGetAutodeskContext,
} from '../../../services/forge.service';
import { getTenantId, setTenantId } from '@innovyze/lib_get_service';
import {
	setDefaultSite,
	setDefaultSiteName,
	setTeams,
	setToken,
	setUser,
} from '../../../actions/authenticationContext.actions';

import { SiteConfig } from '../../../contexts/SiteConfiguration';
import { processUserContextToStore } from '../../../utils/UserContext';
import { setUserContext } from '../../../actions/userContext.actions';
import { useDispatch } from 'react-redux';

export const useForgeUserContext = (config: SiteConfig | null) => {
	const dispatch = useDispatch();

	const getUserProfileData = async (accessToken?: string) => {
		const userProfileResponse = await getAutodeskUserProfile(
			config?.forge,
			accessToken,
		);
		return userProfileResponse;
	};

	const retrieveUserContext = async (accessToken: string) => {
		const organizationId = getTenantId();
		const [autodeskUserContext, autodeskUserTeams, userProfile] =
			await Promise.all([
				retriedGetAutodeskContext(
					organizationId ? organizationId : null,
				),
				getAutodeskTeams(),
				getUserProfileData(accessToken),
			]);
		return {
			autodeskUserContext,
			autodeskUserTeams,
			userProfile,
		};
	};

	const storeUserContext = (
		autodeskUserContext: AutodeskContext,
		autodeskUserTeams: AutodeskTeams,
		userProfile: AutodeskUserProfile,
		accessToken: string,
	) => {
		const { defaultSite, defaultSiteName, teams, userContext } =
			processUserContextToStore({
				autodeskUserContext,
				autodeskUserTeams,
				userProfile,
				accessToken,
			});
		dispatch(setUser(userProfile));
		dispatch(setTeams(teams));
		dispatch(setDefaultSite(defaultSite));
		dispatch(setDefaultSiteName(defaultSiteName));
		dispatch(setToken(accessToken));
		setTenantId(defaultSite);
		dispatch(setUserContext(userContext));
	};

	return { retrieveUserContext, storeUserContext };
};
