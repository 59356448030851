import { Environments } from '@innovyze/stylovyze';

import config from './config.api';
import { formatUrlTrailing } from '@Utils/urlFormatting';
import { axiosWithHeaders, getService } from '@innovyze/lib_get_service';

const apiEnv =
	(localStorage.getItem('apiEnv') as keyof typeof Environments) || undefined;

export const inspectionBaseUrl = formatUrlTrailing(
	getService('amInspection', config, apiEnv),
);

const service = axiosWithHeaders({
	service: 'amInspection',
	config,
	axiosCreateOpts: {
		timeout: 15000,
	},
});

export default service;
