import { getValue, passInId, passInLayerName } from './shared';

import { createSelector } from '@reduxjs/toolkit';

export const selectRiskBase = createSelector(
	[getValue],
	moduleState => moduleState.risk,
);

export const selectSpatialRecords = createSelector(
	[selectRiskBase],
	baseState => baseState.spatialRecords,
);

export const selectSpatialRecordById = createSelector(
	[selectSpatialRecords, passInId],
	(records, selectedItem) => records.find(({ id }) => id === selectedItem),
);

export const selectRiskScores = createSelector(
	[selectRiskBase],
	baseState => baseState.riskCondition,
);

export const selectRiskScoreByAssetId = createSelector(
	[selectRiskScores, passInId],
	(scores, selectedItem) => selectedItem && scores[selectedItem],
);

export const selectSpatialLayers = createSelector(
	[selectRiskBase],
	baseState => baseState.spatialLayers,
);

export const selectSpatialMeta = createSelector(
	[selectRiskBase],
	baseState => baseState.spatialMetaData,
);

export const selectSpatialLayerMeta = createSelector(
	[selectSpatialMeta, passInLayerName],
	(meta, layerName) => (layerName ? meta[layerName] ?? [] : []),
);
