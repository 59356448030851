import { put, retry, takeLatest } from 'redux-saga/effects';
import * as infoWaterProActions from '../actions/infoWaterPro.actions';

import {
	getDSChildren,
	getDSTableMetadata,
	getDSTables,
} from '../services/domainServices.service';
import { PayloadAction } from '@reduxjs/toolkit';
import { DSTable, DSTableMetadata, DSTreeObject } from '../types';
import {
	requestInfoWaterProMetadataRejected,
	requestInfoWaterProTables,
	requestInfoWaterProTablesRejected,
	requestInfoWaterProTablesResolved,
} from '../actions/infoWaterPro.actions';

const {
	requestInfoWaterProAnalysisGroups,
	requestInfoWaterProAnalysisGroupsResolved,
	requestInfoWaterProAnalysisGroupsRejected,
	requestInfoWaterProSimulations,
	requestInfoWaterProSimulationsResolved,
	requestInfoWaterProSimulationsRejected,
} = infoWaterProActions;

function* requestAnalysisGroupsSaga(action: PayloadAction<{ dbid: string }>) {
	const { dbid } = action.payload;
	try {
		const analysisGroups: DSTreeObject[] | undefined = yield retry(
			5,
			1500,
			getDSChildren,
			dbid,
			'0',
		);
		if (analysisGroups) {
			yield put(
				requestInfoWaterProAnalysisGroupsResolved({
					dbid,
					data: analysisGroups,
				}),
			);
		} else {
			yield put(
				requestInfoWaterProAnalysisGroupsRejected({
					dbid,
					error: 'Failed to retrieve analysis groups',
				}),
			);
		}
	} catch (e) {
		yield put(
			requestInfoWaterProAnalysisGroupsRejected({
				dbid,
				error: 'Failed to retrieve analysis groups',
			}),
		);
	}
}

function* requestSimulationsSaga(
	action: PayloadAction<{ dbid: string; analysisGroupId: string }>,
) {
	const { dbid, analysisGroupId } = action.payload;
	if (dbid === '' || analysisGroupId === '') {
		return;
	}
	try {
		const simulations: DSTreeObject[] | undefined = yield retry(
			5,
			1500,
			getDSChildren,
			dbid,
			analysisGroupId,
		);
		if (simulations) {
			yield put(
				requestInfoWaterProSimulationsResolved({
					dbid,
					analysisGroupId,
					data: simulations,
				}),
			);
		} else {
			yield put(
				requestInfoWaterProSimulationsRejected({
					dbid,
					analysisGroupId,
					error: 'Failed to retrieve simulations',
				}),
			);
		}
	} catch (e) {
		yield put(
			requestInfoWaterProSimulationsRejected({
				dbid,
				analysisGroupId,
				error: 'Failed to retrieve simulations',
			}),
		);
	}
}

function* requestTablesSaga(
	action: PayloadAction<{ dbid: string; simulationId: string }>,
) {
	const { dbid, simulationId } = action.payload;
	if (dbid === '' || simulationId === '') {
		return;
	}
	try {
		const tables: DSTable[] | undefined = yield retry(
			5,
			1500,
			getDSTables,
			dbid,
			simulationId,
		);
		if (tables) {
			yield put(
				requestInfoWaterProTablesResolved({
					dbid,
					simulationId,
					data: tables,
				}),
			);
		} else {
			yield put(
				requestInfoWaterProTablesRejected({
					dbid,
					simulationId,
					error: 'Failed to retrieve tables',
				}),
			);
		}
	} catch (e) {
		yield put(
			requestInfoWaterProTablesRejected({
				dbid,
				simulationId,
				error: 'Failed to retrieve tables',
			}),
		);
	}
}

function* requestMetadataSaga(
	action: PayloadAction<{
		dbid: string;
		simulationId: string;
		tableId: string;
	}>,
) {
	const { dbid, simulationId, tableId } = action.payload;
	if (dbid === '' || simulationId === '' || tableId === '') {
		return;
	}
	try {
		const metadata: DSTableMetadata | undefined = yield retry(
			5,
			1500,
			getDSTableMetadata,
			dbid,
			simulationId,
			tableId,
		);
		if (metadata) {
			yield put(
				infoWaterProActions.requestInfoWaterProMetadataResolved({
					dbid,
					simulationId,
					tableId,
					data: metadata,
				}),
			);
		} else {
			yield put(
				requestInfoWaterProMetadataRejected({
					dbid,
					simulationId,
					tableId,
					error: 'Failed to retrieve metadata',
				}),
			);
		}
	} catch (e) {
		yield put(
			requestInfoWaterProMetadataRejected({
				dbid,
				simulationId,
				tableId,
				error: 'Failed to retrieve metadata',
			}),
		);
	}
}

export function* watchRequestAnalysisGroupsSaga() {
	yield takeLatest(
		requestInfoWaterProAnalysisGroups,
		requestAnalysisGroupsSaga,
	);
}

export function* watchRequestSimulationsSaga() {
	yield takeLatest(requestInfoWaterProSimulations, requestSimulationsSaga);
}

export function* watchRequestTablesSaga() {
	yield takeLatest(requestInfoWaterProTables, requestTablesSaga);
}

export function* watchRequestMetadataSaga() {
	yield takeLatest(
		infoWaterProActions.requestInfoWaterProMetadata,
		requestMetadataSaga,
	);
}

export default [
	watchRequestAnalysisGroupsSaga(),
	watchRequestSimulationsSaga(),
	watchRequestTablesSaga(),
	watchRequestMetadataSaga(),
];
