import {
	getSystemTypeIdByAssetTypePrefix,
	getSystemTypePrefixes,
} from '@innovyze/lib_asset_schema';

export const formatUrlTrailing = (url: string): string =>
	url.endsWith('/') ? url : `${url}/`;

function toSnakeCase(str: string): string {
	return str
		.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
		.replace(/^_/, '');
}

function getBaseAssetType(assetType: string): string {
	const prefixPattern = new RegExp(`^(${getSystemTypePrefixes().join('|')})`);
	const baseAssetType = assetType.replace(prefixPattern, '');
	return toSnakeCase(baseAssetType);
}

export function makeMapURL(
	assetType: string,
	assetId: string,
	position: GeoJSON.Position,
): string {
	const systemType = toSnakeCase(getSystemTypeIdByAssetTypePrefix(assetType));
	const baseAssetType = getBaseAssetType(assetType);
	const positionAndZoom = `${position[0]},${position[1]}/18`;
	const assetInfo = `${systemType}_${baseAssetType}:${assetType}=~${assetId}`;

	return `/map#/map/streets/${positionAndZoom}/${assetInfo}`;
}
