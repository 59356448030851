import type { Selection } from '../DataFinder';

/**
 * test to see if a given option is selected (i.e. if it is in the list of selections).
 *
 * @param selections an array of the current selections
 * @param categoryId the id of the category the option being tested
 * @param optionId the id of the option being tested
 * @returns true if the option is selected, false otherwise
 */

export function isItemSelected(
	selections: Selection[],
	categoryId: string,
	optionId: string,
): boolean {
	for (const selection of selections) {
		if (
			selection.categoryId === categoryId &&
			selection.optionId === optionId
		) {
			return true;
		}
	}

	return false;
}

/**
 * Get a list of the current selections for a given category.
 *
 * @param selections an array of the current selections
 * @param categoryId the id of the category to get selections for
 * @returns an array of the current selections for the given category
 */
export function getSelectionByCategory(
	selections: Selection[],
	categoryId: string,
): Selection | undefined {
	return selections.find(s => s.categoryId === categoryId);
}
