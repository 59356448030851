import { useDispatch, useSelector } from 'react-redux';
import {
	DSObjectType,
	keyforSimulation,
	optionFromDSTreeObject,
	SharedStoreState,
} from '../../types';
import { DataContainer, STATUS_ENUM } from '../../types/reducers.types';
import { Option } from '../../components/SimulationDataFinderDialog/DataFinder';
import { requestInfoWaterProSimulations } from '../../actions/infoWaterPro.actions';
import { useEffect } from 'react';

/** provide all the simulations available in the given dbid and analysis group as an array of Options */
type AnalysisGroupSimulations = {
	analysisGroupId: string;
	options: Option[] | undefined;
};

export const infoWaterProSimulationsSelector = (
	state: SharedStoreState,
	dbid: string,
	analysisGroupId: string,
): DataContainer<Option[]> => {
	const key = keyforSimulation(dbid, analysisGroupId);
	const sims = state.infoWaterPro.simulations[key];
	if (!sims) {
		return { initialized: false, status: STATUS_ENUM.IDLE, data: [] };
	} else {
		const options = sims?.data
			?.filter(obj => obj.otype === DSObjectType.InfoWaterSimulation)
			.map(optionFromDSTreeObject);
		return { ...sims, data: options };
	}
};

export const infoWaterProAnalysisGroupsSimulationsSelector = (
	state: SharedStoreState,
	dbid: string,
	analysisGroupIds: string[],
): AnalysisGroupSimulations[] => {
	const analysisGroupSimulations: AnalysisGroupSimulations[] = [];
	analysisGroupIds.forEach(analysisGroupId => {
		const key = keyforSimulation(dbid, analysisGroupId);
		const sims = state.infoWaterPro.simulations[key];
		let simulations: AnalysisGroupSimulations;
		if (!sims) {
			simulations = {
				analysisGroupId,
				options: undefined,
			};
		} else {
			simulations = {
				analysisGroupId,
				options: sims?.data
					?.filter(
						obj => obj.otype === DSObjectType.InfoWaterSimulation,
					)
					.map(optionFromDSTreeObject),
			};
		}
		analysisGroupSimulations.push(simulations);
	});
	return analysisGroupSimulations;
};

/** provide all the simulations available in the given dbid and analysis group as an array of Options */
export const useSelectInfoWaterProSimulations = (
	dbid: string,
	analysisGroupId: string,
): DataContainer<Option[]> => {
	const selector = useSelector((state: SharedStoreState) =>
		infoWaterProSimulationsSelector(state, dbid, analysisGroupId),
	);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(requestInfoWaterProSimulations({ dbid, analysisGroupId }));
	}, [dbid, analysisGroupId]);
	return selector;
};

export const useSelectInfoWaterProAnalysisGroupsSimulations = (
	dbid: string,
	analysisGroupIds: string[],
): DataContainer<AnalysisGroupSimulations[]> => {
	const dispatch = useDispatch();
	const analysisGroupSimulations = useSelector((state: SharedStoreState) =>
		infoWaterProAnalysisGroupsSimulationsSelector(
			state,
			dbid,
			analysisGroupIds,
		),
	);
	if (dbid === '' || analysisGroupIds.length === 0) {
		return {
			initialized: false,
			status: STATUS_ENUM.IDLE,
			data: [],
		};
	}
	let requestSimulations = false;
	analysisGroupIds.forEach(analysisGroupId => {
		const analysisGroupSimulation = analysisGroupSimulations.find(
			s => s.analysisGroupId === analysisGroupId,
		);
		if (analysisGroupSimulation?.options === undefined) {
			requestSimulations = true;
			dispatch(requestInfoWaterProSimulations({ dbid, analysisGroupId }));
		}
	});
	const dataContainer: DataContainer<AnalysisGroupSimulations[]> = {
		initialized: true,
		status: requestSimulations ? STATUS_ENUM.PENDING : STATUS_ENUM.SUCCESS,
		data: analysisGroupSimulations,
	};
	return dataContainer;
};

/** provide a single simulation label by ID */
export const infoWaterProSimulationLabelSelector = (
	state: SharedStoreState,
	dbid: string,
	analysisGroupId: string,
	simulationId: string,
): DataContainer<string> => {
	const sims = infoWaterProSimulationsSelector(state, dbid, analysisGroupId);
	const sim = sims?.data?.find(({ id }) => id === simulationId);
	return { ...sims, data: sim?.label ?? '' };
};

/** provide a single simulation label by ID */
export const useSelectInfoWaterProSimulationLabel = (
	dbid: string,
	analysisGroupId: string,
	simulationId: string,
): DataContainer<string> => {
	const selector = useSelector((state: SharedStoreState) =>
		infoWaterProSimulationLabelSelector(
			state,
			dbid,
			analysisGroupId,
			simulationId,
		),
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(requestInfoWaterProSimulations({ dbid, analysisGroupId }));
	}, [dbid, analysisGroupId]);
	return selector;
};
