import yamlMapping from '../schema';
import { AssetSchemaRaw } from '../types/asset.types';

export const loadAssetFile = async (
	systemType: string,
	assetType: string,
): Promise<AssetSchemaRaw | undefined> => {
	try {
		const file = yamlMapping[systemType]?.[assetType];

		if (!file) {
			return undefined;
		}

		// Copy the asset to make sure no one is modifying it
		return JSON.parse(JSON.stringify(file));
	} catch {
		return undefined;
	}
};
