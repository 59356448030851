import { Field, FieldType } from '@Types/map.types';
import {
	excludedFilterColumn,
	spatialFilterColumn,
} from '@Components/AssetGrid/columnMapping';

import { NamespacedStoreState } from '@Types/store.types';
import { createSelector } from '@reduxjs/toolkit';
import { getValue } from './shared';
import { isValidFieldType } from '@Utils/helpers';
import { selectSpatialLayerMeta } from './risk';
import { assetSchemaSelectorByType } from '@innovyze/lib_asset_schema_store';
import { SingleProperty } from '@innovyze/lib_asset_schema';
import { getFieldTypeMapping } from './getFieldTypeMapping';

const DEFAULT_COLUMN_WIDTH = 150;

const isVisibleByDefault = (property: SingleProperty): boolean =>
	property.hasCapability('ShowOnMapPanel');

const mapAssetPropertyToField = (property: SingleProperty): Field => {
	const { fieldType, precision, multiline } = getFieldTypeMapping(property);
	return {
		name: property.displayName,
		fieldKey: property.id,
		fieldType,
		columnWidth: DEFAULT_COLUMN_WIDTH,
		precision,
		units: property.units,
		multiline,
		visible: isVisibleByDefault(property),
	};
};

const calculateVisibility = (columns: Pick<Field, 'fieldKey' | 'visible'>[]) =>
	columns.reduce<Record<string, boolean>>((visibility, column) => {
		visibility[column.fieldKey] = column.visible ?? false;
		return visibility;
	}, {});

export const selectAssetGridColumnLayout = createSelector(
	[assetSchemaSelectorByType],
	assetSchema => {
		const properties = assetSchema?.getProperties() ?? [];

		const propertyColumns: Field[] = properties
			.flatMap(property =>
				property.type === 'List' ? property.properties : [property],
			)
			.map(mapAssetPropertyToField);

		const columns = [
			...propertyColumns,
			spatialFilterColumn,
			excludedFilterColumn,
		];

		return { columns, visibility: calculateVisibility(columns) };
	},
);

export const selectSpatialDataColumnLayout = createSelector(
	[selectSpatialLayerMeta],
	layerMeta => {
		const propertyColumns = layerMeta.map(({ property, type }) => ({
			fieldKey: property,
			fieldType: correctFieldType(type),
			name: property,
			visible: true,
			columnWidth: DEFAULT_COLUMN_WIDTH,
		}));

		const columns = [
			...propertyColumns,
			spatialFilterColumn,
			excludedFilterColumn,
		];

		return { columns, visibility: calculateVisibility(columns) };
	},
);

export const selectMapLayout = createSelector(
	[getValue],
	moduleState => moduleState.map.layout,
);

const passInAssetType = (_: NamespacedStoreState, assetType: string): string =>
	assetType;

const selectMapLayoutByAssetType = createSelector(
	[selectMapLayout, passInAssetType],
	(layout, assetType) => layout && layout[assetType],
);

const selectMapLayoutForPropertyPane = createSelector(
	[selectMapLayoutByAssetType],
	assetTypeLayout =>
		assetTypeLayout &&
		assetTypeLayout.find(({ showInPanel }) => showInPanel),
);

/**
 * This function is used in lib_am_common repository for Asset Details.
 * @deprecated and should be removed in the future.
 */
export const selectMapLayoutForAssetDetails = createSelector(
	[selectMapLayoutByAssetType],
	assetTypeLayout => {
		return (
			assetTypeLayout &&
			assetTypeLayout.filter(({ showInPanel }) => !showInPanel)
		);
	},
);

/**
 * @deprecated in favor of new asset schema and should be removed in the future.
 */
export const selectMapLayoutForAssetGrid = createSelector(
	[selectMapLayoutForPropertyPane, selectMapLayoutForAssetDetails],
	(toShow, additional) => {
		const fieldKeys = toShow?.fields.map(({ fieldKey }) => fieldKey);
		const additionalFields = additional?.flatMap(({ fields }) => fields);
		const columns = [
			...(toShow?.fields || []).map(data => ({ ...data, visible: true })),
			spatialFilterColumn,
			excludedFilterColumn,
			...(additionalFields || [])?.filter(
				({ fieldKey }) => !fieldKeys?.includes(fieldKey),
			),
		];
		return { columns, visibility: calculateVisibility(columns) };
	},
);

const correctFieldType = (fieldType: string): FieldType => {
	if (fieldType === 'datetime' || fieldType === 'time') {
		return FieldType.Date;
	}
	if (isValidFieldType(fieldType)) {
		return fieldType as FieldType;
	}
	return FieldType.String;
};
