import { useState } from 'react';

export interface CustomSelectionModel {
	selectionMode: 'all' | 'custom';
	selection: string[];
}

export interface CustomSelection {
	customSelection: CustomSelectionModel;
	handleCustomSelection: (row: { _id: string }) => void;
	handleAllSelected: (checked: boolean) => void;
	isSelected: (id: string) => boolean;
	isAllSelected: () => boolean;
	resetSelection: () => void;
}

export const useCustomSelection = (pageSize: number): CustomSelection => {
	const [customSelection, setCustomSelection] = useState<
		CustomSelectionModel
	>({
		selectionMode: 'custom',
		selection: [],
	});

	const handleCustomSelection = (row: { _id: string }) => {
		const { selectionMode, selection } = customSelection;
		const selected = selection.includes(row._id);
		switch (selectionMode) {
			case 'all':
				if (!selected) {
					setCustomSelection({
						selectionMode: 'all',
						selection: [...customSelection.selection, row._id],
					});
				} else {
					setCustomSelection({
						selectionMode: 'all',
						selection: customSelection.selection.filter(
							id => id !== row._id,
						),
					});
				}
				break;
			case 'custom':
				if (!selected) {
					setCustomSelection({
						selectionMode: 'custom',
						selection: [...customSelection.selection, row._id],
					});
				} else {
					setCustomSelection({
						selectionMode: 'custom',
						selection: customSelection.selection.filter(
							id => id !== row._id,
						),
					});
				}
				break;
		}
	};

	const handleAllSelected = (allSelected: boolean) => {
		setCustomSelection({
			selectionMode: allSelected ? 'all' : 'custom',
			selection: [],
		});
	};

	const isSelected = (id: string): boolean => {
		const { selectionMode, selection } = customSelection;
		if (selectionMode === 'all') {
			if (selection.includes(id)) {
				return false;
			}
			return true;
		}
		if (selectionMode === 'custom') {
			if (selection.includes(id)) {
				return true;
			}
			return false;
		}
		return false;
	};

	const isAllSelected = (): boolean => {
		const { selectionMode, selection } = customSelection;
		if (selectionMode === 'all' && selection.length === 0) {
			return true;
		}
		if (selection.length === pageSize) {
			return true;
		}
		return false;
	};

	const resetSelection = () => {
		setCustomSelection({
			selectionMode: 'custom',
			selection: [],
		});
	};

	return {
		customSelection,
		handleCustomSelection,
		handleAllSelected,
		isSelected,
		isAllSelected,
		resetSelection,
	};
};
