import { getApiEnvironment } from '@innovyze/stylovyze';

import config from './config.api';
import { formatUrlTrailing } from '@Utils/urlFormatting';
import { axiosWithHeaders, getService } from '@innovyze/lib_get_service';

export const rehabBaseUrl = formatUrlTrailing(
	getService('amRehab', config, getApiEnvironment()),
);

const rehabService = axiosWithHeaders({
	service: 'amRehab',
	config,
	axiosCreateOpts: {
		timeout: 15000,
	},
});

export default rehabService;
