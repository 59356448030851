/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Curve,
	CurveManagerForm,
	CurveParsedFile,
	FileWithPromise,
	FileWithValue,
} from '../../../types/curveManager.types';
import { FileDropzone } from '../../../components';
import { FormHelperText } from '@mui/material';
import {
	removeDuplicatedAsset,
	removeValidatedAsset,
	setCurveData,
} from '../../../actions/curveManager.actions';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { useGlobalization } from '../../../contexts';
import React, { useEffect, useMemo, useState } from 'react';
import {
	ErrorList,
	ErrorListItem,
	ErrorTitle,
} from './CurveManagerFileDropzone.styles';
import { AttachedFile } from 'types';
import parse from 'csv-parse';
import { useSelectCurveManager } from '../../../selectors';
import { useIsFeatureEnabled } from '../../../utils';

const parserOptions: parse.Options = {
	columns: true,
	trim: true,
	skipEmptyLines: true,
	skipLinesWithError: true,
};

export const CurveManagerFileDropzone = () => {
	const { t } = useGlobalization();
	const multipleCurveUploadEnabled = !!useIsFeatureEnabled(
		'info360-multiple-curve-upload',
	);

	const dispatch = useDispatch();
	const { setFieldValue, setFieldTouched, setFieldError, errors, values } =
		useFormikContext<CurveManagerForm>();

	const {
		overwriteModal: { duplicatedAssets, validatedAssets },
	} = useSelectCurveManager();

	const [uploadedFiles, setUploadedFiles] = useState<AttachedFile[]>([]);

	const setCurveDataErrors = (errors: string[]) => {
		if (!multipleCurveUploadEnabled) {
			setFieldError('curveData', undefined);
		}
		const deduplicatedErrors = errors.filter(
			(error, index) => errors.indexOf(error) === index,
		);
		if (deduplicatedErrors.length > 0) {
			setFieldError('curveData', deduplicatedErrors.join(', '));
			return;
		}
		setFieldError('curveData', undefined);
	};

	const getDuplicatedAssetsErrors = (): string[] => {
		const errorsList: string[] =
			typeof errors?.curveData === 'string'
				? errors?.curveData?.split(', ')
				: [];
		duplicatedAssets?.forEach(asset => {
			const error = `${asset} - ${t(
				'Curve name already exists. Hit create to overwrite',
			)}`;
			errorsList.push(error);
		});
		return errorsList;
	};

	useEffect(() => {
		if (validatedAssets.length === 0 || uploadedFiles.length === 0) {
			return;
		}

		const newUploadedFiles = uploadedFiles.map(file => ({
			...file,
			isLoading: !validatedAssets.includes(file.path.split('.')[0]),
		}));

		setUploadedFiles(newUploadedFiles);
	}, [validatedAssets]);

	useEffect(() => {
		if (!duplicatedAssets || duplicatedAssets?.length === 0) {
			return;
		}
		const errors = getDuplicatedAssetsErrors();
		setCurveDataErrors(errors);
	}, [duplicatedAssets]);

	const getParsedFilePromises = (
		files: File[],
		filesText: string[],
	): Array<Promise<CurveParsedFile>> => {
		return filesText.map(async (text, index) => {
			return new Promise((resolve, reject) => {
				parse(text, parserOptions, (err, output: CurveParsedFile) => {
					const file = files[index];
					if (err) {
						return reject(
							`${file.name} - ${t('Invalid CSV file.')}`,
						);
					}
					if (!output || !output.length) {
						if (file.name.includes('.csv')) {
							return reject(
								`${file.name} - ${t(
									'Cannot load an empty CSV file.',
								)}`,
							);
						} else {
							return reject(
								`${file.name} - ${t(
									'File type not supported. Please upload a CSV file.',
								)}`,
							);
						}
					}
					const keys = Object.keys(output[0]);
					if (keys.length != 2)
						return reject(
							`${file.name} - ${t(
								'CSV file should only contain two columns with a header row.',
							)}`,
						);

					return resolve(output);
				});
			});
		});
	};

	const getFullfilledPromisesValues = (
		settledPromises: FileWithPromise[],
	) => {
		return settledPromises
			.filter(({ promise }) => promise.status === 'fulfilled')
			.map<FileWithValue>(({ file, promise }) => ({
				file,
				value: (promise as PromiseFulfilledResult<CurveParsedFile>)
					.value,
			}));
	};

	const getCurveName = (
		curveData: Partial<Curve>[],
		existingCurveData: Partial<Curve>[],
	): string => {
		if (curveData.length > 1 || existingCurveData.length >= 1) {
			return t('Multiple');
		}
		if (curveData.length === 1) {
			if (values.curveName.length > 0) {
				return values.curveName;
			}
			return curveData[0]?.name?.split('.')[0] ?? values.curveName;
		}

		return '';
	};

	const removeCurveDataDuplicates = (
		existingCurves: Partial<Curve>[],
		newCurves: Partial<Curve>[],
	): Partial<Curve>[] => {
		const allCurves = [...existingCurves];
		newCurves.forEach(curve => {
			const existingCurveIndex = allCurves.findIndex(
				({ name }) => curve.name === name,
			);

			if (existingCurveIndex === -1) {
				allCurves.push(curve);
				return;
			}
			allCurves[existingCurveIndex] = { ...curve };
		});
		return allCurves;
	};

	const removeDuplicateDropzoneFiles = (
		existingFiles: AttachedFile[],
		newFiles: AttachedFile[],
	): AttachedFile[] => {
		const allFiles = [...existingFiles];
		newFiles.forEach(file => {
			const existingFileIndex = allFiles.findIndex(
				({ path }) => file.path === path,
			);

			if (existingFileIndex === -1) {
				allFiles.push(file);
			}
		});
		return allFiles;
	};

	const processFullfilledValues = (fullfilledValues: FileWithValue[]) => {
		const dropzoneFiles: AttachedFile[] =
			fullfilledValues.map<AttachedFile>(({ file }) => ({
				path: file.name,
				dateCreated: file.lastModified,
				isLoading: true,
			}));

		const curveData = fullfilledValues.map<Partial<Curve>>(
			({ file, value }) => {
				const depthArray = value?.map(({ x }) => +x);
				const volumeArray = value?.map(({ y }) => +y);
				return {
					depthArray,
					volumeArray,
					name: file.name.split('.')[0],
				};
			},
		);
		if (curveData.length === 1 && values.curveData?.length === 0) {
			// User just uploaded a single file, and there are no existing curves
			dispatch(setCurveData(curveData[0]));
		}
		const curveName = getCurveName(curveData, values.curveData);
		setFieldValue('curveName', curveName);
		if (values.curveData?.length >= 1) {
			const newCurveData = removeCurveDataDuplicates(
				values.curveData,
				curveData,
			);
			const newUploadedFiles = removeDuplicateDropzoneFiles(
				uploadedFiles,
				dropzoneFiles,
			);
			setFieldValue('curveData', newCurveData);
			setUploadedFiles(newUploadedFiles);
		} else {
			setFieldValue('curveData', curveData);
			setUploadedFiles(dropzoneFiles);
		}
		setFieldTouched('curveData', true);
	};

	const getRejectedPromisesReasons = (files: FileWithPromise[]): string[] => {
		return files
			.filter(({ promise }) => promise.status === 'rejected')
			.map(({ promise }) => (promise as PromiseRejectedResult).reason);
	};

	const getFilesText = async (files: File[]) => {
		const filesTextPromise = files.map(async file => file.text());
		const filesText = await Promise.all(filesTextPromise);

		return filesText;
	};

	const handleFileDropped = async <T extends File>(files: T[]) => {
		try {
			if (files.length > 1 && !multipleCurveUploadEnabled) {
				throw [t('Please upload only one CSV file.')];
			}
			const filesText = await getFilesText(files);
			const parsedFilePromises = getParsedFilePromises(files, filesText);
			const settledPromises = await Promise.allSettled(
				parsedFilePromises,
			);

			const filesWithPromise = files.map<FileWithPromise>(
				(file, index) => ({
					file,
					promise: settledPromises[index],
				}),
			);
			const fullfilledValues =
				getFullfilledPromisesValues(filesWithPromise);

			const rejectionReasons =
				getRejectedPromisesReasons(filesWithPromise);

			const duplicationErrors = getDuplicatedAssetsErrors();

			const errors = [...rejectionReasons, ...duplicationErrors];

			processFullfilledValues(fullfilledValues);

			if (errors.length > 0) {
				throw errors;
			}
		} catch (e) {
			const errors = e as unknown as string[];
			setTimeout(() => {
				setCurveDataErrors(errors);
			}, 1);
		}
	};

	const errorsList: string[] = useMemo(() => {
		const errorList =
			typeof errors?.curveData === 'string'
				? errors?.curveData?.split(', ')
				: [];

		if (!multipleCurveUploadEnabled) {
			return errorList.map(error =>
				error.includes('- ') ? error.split('- ')[1] : error,
			); // Remove the file name from the error message in case it is added
		}

		return errorList;
	}, [errors, multipleCurveUploadEnabled]);

	const handleDeleteFile = (file: AttachedFile, errors: string[]) => {
		const curveName = file.path.split('.')[0];
		const newCurveData = values.curveData.filter(
			({ name }) => name !== curveName,
		);
		if (duplicatedAssets?.includes(curveName)) {
			dispatch(removeDuplicatedAsset(curveName));
		}
		dispatch(removeValidatedAsset(curveName));

		const newErrorsList = errors.filter(
			error => !error.includes(curveName),
		);

		setCurveDataErrors(newErrorsList);
		setFieldValue('curveData', newCurveData);
		const newFiles = uploadedFiles.filter(
			({ path }) => path.split('.')[0] !== curveName,
		);

		setUploadedFiles(newFiles);

		if (newCurveData.length === 1) {
			dispatch(setCurveData(newCurveData[0]));
		}
	};

	return (
		<>
			<FileDropzone
				token={''}
				files={uploadedFiles}
				supportedFileText={t('Supported file type: csv')}
				onDropAcceptedExternal={handleFileDropped}
				hideFileExtension
				hideFileDate
				showDeleteButton
				onFileDelete={file => handleDeleteFile(file, errorsList)}
			/>
			{errorsList?.length > 0 && !multipleCurveUploadEnabled ? (
				<FormHelperText error>{errorsList[0]}</FormHelperText>
			) : null}
			{errorsList?.length > 0 && multipleCurveUploadEnabled ? (
				<>
					<ErrorTitle>{t('Validation Errors')}</ErrorTitle>
					<ErrorList>
						{errorsList.map((error, index) => (
							<ErrorListItem key={index}>
								<FormHelperText error>{error}</FormHelperText>
							</ErrorListItem>
						))}
					</ErrorList>
				</>
			) : null}
		</>
	);
};

export default CurveManagerFileDropzone;
