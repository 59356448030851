import {
	generateDeviantUrl as generateDeviantUrlV2,
	generateNotificationHubAppsyncUrl as generateNotificationHubAppsyncUrlV2,
	generateRegionForcedStandardUrl as generateRegionForcedStandardUrlV2,
	generateStandardUrl as generateStandardUrlV2,
	getService as getServiceV2,
	Services,
	PartialServiceMap,
	Environments,
	Region,
} from '@innovyze/lib_get_service';

export const generateDeviantUrl = (url: string) => {
	console.warn(
		'generateDeviantUrl within stylovyze is deprecated and will be removed with stylovyze 1.1.0. Please use generateDeviantUrl from lib_get_service instead.',
	);
	return generateDeviantUrlV2(url);
};
export const generateNotificationHubAppsyncUrl = (subDomain: string) => {
	console.warn(
		'generateNotificationHubAppsyncUrl within stylovyze is deprecated and will be removed with stylovyze 1.1.0. Please use generateNotificationHubAppsyncUrl from lib_get_service instead.',
	);
	return generateNotificationHubAppsyncUrlV2(subDomain);
};

export const generateRegionForcedStandardUrl = (
	region: Region,
	subDomain: string,
	env: string,
) => {
	console.warn(
		'generateRegionForcedStandardUrl within stylovyze is deprecated and will be removed with stylovyze 1.1.0. Please use generateRegionForcedStandardUrl from lib_get_service instead.',
	);
	return generateRegionForcedStandardUrlV2(region, subDomain, env);
};

export const generateStandardUrl = (subDomain: string, env: string) => {
	console.warn(
		'generateStandardUrl within stylovyze is deprecated and will be removed with stylovyze 1.1.0. Please use generateStandardUrl from lib_get_service instead.',
	);
	return generateStandardUrlV2(subDomain, env);
};
export const getService = (
	service: Services,
	mapOverride: PartialServiceMap,
	environmentOverride: Environments,
) => {
	console.warn(
		'getService within stylovyze is deprecated. Please use getService from lib_get_service instead.',
	);
	return getServiceV2(service, mapOverride, environmentOverride);
};
