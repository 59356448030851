import { createAction } from '@reduxjs/toolkit';

export const analysisGroupSelected = createAction<{
	id: string;
	label: string;
}>('simulationMultiSelectDialog/analysisGroupSelected');

export const simulationsSelected = createAction<
	{
		id: string;
		label: string;
	}[]
>('simulationMultiSelectDialog/simulationsSelected');

export const cancelClicked = createAction(
	'simulationMultiSelectDialog/cancelClicked',
);

export const addClicked = createAction(
	'simulationMultiSelectDialog/addClicked',
);

export const openSimulationMultiSelectDialog = createAction(
	'simulationMultiSelectDialog/openSimulationMultiSelectDialog',
);
