import * as React from 'react';
import { AxiosInstance } from 'axios';
import { axiosWithHeaders } from '@innovyze/lib_get_service';
import { apiConfig } from '../../../apis/config.api';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useAxios(baseURL: string, options?: { timeout?: number }) {
  const controller = React.useRef(new AbortController());
  const _axios = React.useRef<AxiosInstance>();
  React.useLayoutEffect(() => {
    _axios.current = axiosWithHeaders({
      service: 'edgeAnalytics',
      config: apiConfig,
      axiosCreateOpts: {
        baseURL,
        timeout: options?.timeout ?? 10_000,
        signal: controller.current.signal,
      },
    });

    return () => controller.current.abort();
  }, [options?.timeout, baseURL]);

  return _axios;
}
