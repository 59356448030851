import {
	AssetPipeSummary,
	AssetPipeSummaryResolvedResponse,
} from '@Types/asset.types';
import { ExpectedStringType, parseServerObjectToFormattedString } from '@Utils';
import { centreExtent, geometriesExtent } from '@innovyze/shared-utils';
import {
	getAssetPipe,
	getAssetPipeRejected,
	getAssetPipeResolved,
} from '@Actions/assetPipe.actions';
import { mapMainPosition, mapMainSelection } from '@Actions/map.actions';
import { put, retry, takeLatest } from 'redux-saga/effects';

import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';
import { MapPosition } from '@innovyze/inno-map';
import { getSummaryApi } from '@Services';

// TODO make it generic @AssetSchemaMigration
function* getAssetPipeSaga(action: AnyAction) {
	try {
		const payload: AxiosResponse<AssetPipeSummaryResolvedResponse> =
			yield retry(5, 15000, getSummaryApi, action.payload);

		const result = payload.data;
		if (
			payload.data.asset &&
			payload.data.asset.segments &&
			payload.data.asset.segments.length == 1
		) {
			const segment = payload.data.asset.segments[0];
			result.asset.material = segment.material as string;
			result.asset.diameter = segment.width as number;
		}

		if (payload.data.asset) {
			const formatDateUTC = action.payload.formatDateUTC;
			if (payload.data.asset['yearLaid']) {
				payload.data.asset['yearLaid'] =
					parseServerObjectToFormattedString(
						payload.data.asset['yearLaid'],
						ExpectedStringType.Date,
						formatDateUTC,
					);
			}
		}
		yield put(getAssetPipeResolved(result));
	} catch (e) {
		yield put(getAssetPipeRejected());
	}
}

function* watchGetAssetPipe() {
	yield takeLatest(getAssetPipe, getAssetPipeSaga);
}

function* getAssetPipeResolvedSaga(action: AnyAction) {
	const asset: AssetPipeSummary = action.payload.asset;
	if (asset) {
		const extent = asset.geometry
			? geometriesExtent([asset.geometry])
			: undefined;
		const center = centreExtent(extent);

		if (center) {
			const position: MapPosition = {
				center,
				zoom: 15,
			};

			yield put(mapMainPosition(position));
		}

		yield put(
			mapMainSelection([
				{ id: asset.assetId, layerId: 'risk_levels_medium' },
			]),
		);
	}
}

function* watchGetAssetPipeResolved() {
	yield takeLatest(getAssetPipeResolved, getAssetPipeResolvedSaga);
}

export default [watchGetAssetPipe(), watchGetAssetPipeResolved()];
