import {
	ConfigLayer,
	tilesConfigCreator,
	tilesJsonConfigCreator,
} from '@innovyze/inno-map';
import { LineStringType, PointType, PolygonType } from '@innovyze/shared-utils';
import {
	MapOptions,
	MapRehabLayer,
	MapRiskLayer,
	MapType,
	SourceOptions,
	SpatialLayer,
} from '@Types';
import {
	inspectionCctv,
	inspectionCctvObs,
	inspectionManhole,
	inspectionsLayerName,
	ratingColours,
	rehabColors,
	riskGradeColours,
	riskGradeLayerNames,
} from './index';
import { getSystemTypeIdByAssetTypePrefix } from '@innovyze/lib_asset_schema';

const debugRiskValues = false;
const riskValues: number[] = debugRiskValues
	? [-1, 0, 1, 2, 3, 4, 5]
	: [0, 1, 2, 3, 4, 5];

// This function just to dance around the TS array/tuple madness
// of LSP that gets confused between [number, number] and number[]
const propertyLevel = (key: string, level: number) => {
	const p: Record<string, [number, number]> = {};
	p[key] = [level - 0.5, level + 0.5];
	return p;
};

function createTileSourceRegExp(assetType: string): RegExp {
	const systemType = getSystemTypeIdByAssetTypePrefix(assetType);

	return new RegExp(`^${systemType}(-${assetType})?$`);
}

const riskLayerGroup = (
	opts: SourceOptions | undefined,
	name: string,
	zIndex: number,
	groupCount: number,
	groupIndex: number,
	assetType = 'wwPipe',
): ConfigLayer => {
	return {
		layerName: name,
		dataServiceId: opts?.dataServiceId ?? '',
		tileSource: createTileSourceRegExp(assetType),
		tileLayerName: assetType,
		zIndex: zIndex + groupCount - groupIndex, // highest priority first
		layers: [0, 1, 2, 3, 4].map(n => ({
			layerName: riskGradeLayerNames[n],
			type: 'line',
			color: riskGradeColours[n],
			properties: propertyLevel(`risk${groupIndex}`, n),
			selectable: false,
		})),
	};
};

export const riskLayers = (
	opts: SourceOptions | undefined,
	riskLayers: MapRiskLayer[] = [],
): ConfigLayer[] => {
	if (riskLayers.length < 1) {
		return [];
	}

	const zIndexLevelRisk = 10;

	return [
		{
			layerName: 'Risk levels',
			layers: riskLayers.map((l, n) =>
				riskLayerGroup(
					opts,
					l.name,
					zIndexLevelRisk,
					riskLayers.length,
					n,
					l.assetType,
				),
			),
		},
	];
};

const cctvLayers = (
	opts: SourceOptions | undefined,
	noMinZoom = false,
): ConfigLayer[] =>
	riskValues.map(n => ({
		layerName: `${n}`,
		id: `cctv${n}`,
		dataServiceId: opts?.dataServiceId ?? '',
		type: 'line',
		color: ratingColours[n],
		lineWidth: 5,
		zIndex: 1000 + n,
		minZoom: noMinZoom ? 0 : opts?.minZoom ?? 0,
		properties: {
			rating: [n - 0.5, n + 0.5],
		},
		selectable: false,
	}));

const tiledCctvLayers = (): ConfigLayer[] =>
	riskValues.map(n => ({
		layerName: `${n}`,
		id: `cctv${n}`,
		dataServiceId: 'tiled-inspections',
		type: 'line',
		color: ratingColours[n],
		lineWidth: 5,
		zIndex: 1000 + n,
		properties: {
			rating: [n - 0.5, n + 0.5],
		},
		selectable: false,
	}));

const observationLayers = (
	opts: SourceOptions | undefined,
	noMinZoom = false,
): ConfigLayer[] =>
	riskValues.map(n => ({
		layerName: `${n}`,
		id: `obs${n}`,
		dataServiceId: opts?.dataServiceId ?? '',
		type: 'symbol',
		icon: 'defect',
		color: ratingColours[n],
		labelColor: ratingColours[n],
		zIndex: 1100 + n,
		minZoom: noMinZoom ? 0 : opts?.minZoom ?? 0,
		properties: {
			rating: [n - 0.5, n + 0.5],
		},
		selectable: false,
	}));

const tiledObservationLayers = (): ConfigLayer[] =>
	riskValues.map(n => ({
		layerName: `${n}`,
		id: `obs${n}`,
		dataServiceId: 'tiled-inspections',
		type: 'symbol',
		icon: 'defect',
		color: ratingColours[n],
		labelColor: ratingColours[n],
		zIndex: 1100 + n,
		properties: {
			rating: [n - 0.5, n + 0.5],
		},
		selectable: false,
	}));

const manholeSurveyLayers = (
	opts: SourceOptions | undefined,
	noMinZoom = false,
): ConfigLayer[] =>
	riskValues.map(n => ({
		layerName: `${n}`,
		id: `manhole-survey-${n}`,
		dataServiceId: opts?.dataServiceId,
		type: 'symbol',
		icon: 'manhole-survey',
		color: ratingColours[n],
		lineWidth: 5,
		zIndex: 1000 + n,
		minZoom: noMinZoom ? 0 : opts?.minZoom ?? 0,
		properties: {
			rating: [n - 0.5, n + 0.5],
		},
		selectable: false,
	}));

const tiledManholeSurveyLayers = (): ConfigLayer[] =>
	riskValues.map(n => ({
		layerName: `${n}`,
		id: `manhole-survey-${n}`,
		dataServiceId: 'tiled-inspections',
		type: 'symbol',
		icon: 'manhole-survey',
		color: ratingColours[n],
		lineWidth: 5,
		zIndex: 1000 + n,
		properties: {
			rating: [n - 0.5, n + 0.5],
		},
		selectable: false,
	}));

const inspectionLayers = (
	inspectionSources: SourceOptions[],
	noMinZoom = false,
	isTiledInspectionsEnabled = false,
): ConfigLayer[] => {
	const cctvSource = inspectionSources.find(
		n => n.dataServiceId === inspectionCctv,
	);
	const cctvObsSource = inspectionSources.find(
		n => n.dataServiceId === inspectionCctvObs,
	);
	const manholeSource = inspectionSources.find(
		n => n.dataServiceId === inspectionManhole,
	);

	const hasCctv = cctvSource || cctvObsSource;

	const cctvs = hasCctv
		? [
				{
					layerName: cctvSource?.layerName ?? '',
					layers: [
						cctvLayers(cctvSource, noMinZoom),
						{
							layerName: cctvObsSource?.layerName ?? '',
							layers: observationLayers(cctvObsSource, noMinZoom),
						},
					].flat(),
				},
		  ]
		: [];

	const tiledCctvs = [
		{
			layerName: 'CCTV',
			tileSource: 'tiled-inspections',
			tileLayerName: /[Ii]nspections/,
			layers: [
				tiledCctvLayers(),
				{
					layerName: 'Observations',
					tileSource: 'tiled-inspections',
					tileLayerName: /[Dd]efects/,
					layers: tiledObservationLayers(),
				},
			].flat(),
		},
	];

	const manholes = manholeSource
		? [
				{
					layerName: manholeSource?.layerName ?? '',
					layers: manholeSurveyLayers(manholeSource, noMinZoom),
				},
		  ]
		: [];

	const tiledManholes = [
		{
			layerName: 'Manhole surveys',
			tileSource: 'tiled-inspections',
			tileLayerName: /[Mm]anhole_[Ss]urveys/,
			layers: tiledManholeSurveyLayers(),
		},
	];

	const layers = [cctvs, manholes].flat();

	const tiledLayers = [tiledCctvs, tiledManholes].flat();

	if (isTiledInspectionsEnabled) {
		return tiledLayers.length > 0
			? [
					{
						layerName: 'Inspections',
						dataServiceId: 'tiled-inspections',
						layers: tiledLayers,
					},
			  ]
			: [];
	}

	return layers.length > 0
		? [
				{
					layerName: inspectionsLayerName,
					layers,
				},
		  ]
		: [];
};

const rehabLayers = (
	opts: SourceOptions | undefined,
	mapLayers: MapRehabLayer[] = [],
): ConfigLayer | undefined => {
	if (mapLayers.length < 1) {
		return;
	}

	return {
		layerName: 'Rehab',
		dataServiceId: opts?.dataServiceId ?? '',
		layers: mapLayers.map((l, treeIndex) => ({
			layerName: l.layerName,
			id: l.id,
			tileSource: createTileSourceRegExp(l.assetType),
			tileLayerName: l.assetType,
			zIndex: 100 + treeIndex,
			layers: l.layers?.map((layer, i) => ({
				...layer,
				type: 'line',
				color: rehabColors[i],
				properties: {
					[`rehab${treeIndex}`]: layer.layerName,
				},
			})),
		})),
	};
};

export const layerNameToId = (layerName: string): string =>
	layerName.replace(/[\s-]/g, '').toLowerCase();

export const mapGeometryType = (layer: SpatialLayer): MapType => {
	// SpatialLayer return CSV for all the GeoJson geometry types
	// in the imported FaetureCollection.
	// Hopfully these will all be of the same type, but that is
	// not actually guarentied. Hence the CSV

	// 2do refactor
	// saas_risk_management provides a GEOMETRY_TYPE called feature_type
	// in an interface confusingly also called SPATIAL_LAYER in app_am_asset_upload
	// Sadly this is actually only the value of the 1st feature in the FeatureCollection
	// so could be misleading, it is got from the SPATIAL_DATA snowflake table.
	// Currently the risk version is just used as a label in the
	// UI so just misleading rather than wrong. As mentioned earlier we cannot really
	// support mixed geometry types in a single FeatureCollection upload currently
	// (at least as far as the map is concerned). When
	// we do; we should also change the saas_risk_management response to use this method.

	// 'Like' as these pick up on 'Multi' versions as well
	const geometryType = layer.GEOMETRY_TYPE?.toLowerCase() ?? '';
	const isPointLike = geometryType.includes(PointType.toLowerCase());
	const isLineStringLike = geometryType.includes(
		LineStringType.toLowerCase(),
	);
	const isPolygonLike = geometryType.includes(PolygonType.toLowerCase());

	if (!isPolygonLike && !isLineStringLike && !isPointLike) {
		// Not specified, was layer ingested pre GEOMETRY_TYPE table
		console.warn(
			'mapGeometryType SPATIAL_LAYERS missing valid GEOMETRY_TYPE (guessed "symbol")',
		);
		console.warn(layer);
		// guessing symol as at least coords of line/polygon should render as symbols - so showing something
		return 'symbol';
	}

	if (isPolygonLike && !isLineStringLike && !isPointLike) {
		return 'polygon';
	}

	if (!isPolygonLike && isLineStringLike && !isPointLike) {
		return 'line';
	}

	if (!isPolygonLike && !isLineStringLike && isPointLike) {
		return 'symbol';
	}

	// Got a mixture, not supported - return 'highest' type
	console.warn('mapGeometryType contains mixture (highest used now)');
	console.warn(`  Polygon: ${isPolygonLike}`);
	console.warn(`  LineString: ${isLineStringLike}`);
	console.warn(`  Point: ${isPointLike}`);

	return isPolygonLike ? 'polygon' : 'line';
};

export const configLayers = (
	token: string | undefined,
	options: MapOptions,
	spatialLayers: SpatialLayer[] = [],
	isTiledInspectionsEnabled = false,
): ConfigLayer[] => {
	const layers: ConfigLayer[] = [];

	if (options.tilesSource && !options.tilesSourceHide) {
		layers.push(
			tilesConfigCreator(
				{
					layerName: options.tilesSource.layerName,
					dataServiceId: options.tilesSource.dataServiceId,
					requestToken: token,
				},
				options.tilesSource.url,
			),
		);
	}

	if (options.rehabLayers) {
		const rehabLayerConfig = rehabLayers(
			options.tilesSource,
			options.rehabLayers,
		);
		if (rehabLayerConfig) {
			layers.push(rehabLayerConfig);
		}
	}

	if (options.showRisk) {
		riskLayers(options.tilesSource, options.riskLayers).forEach(l =>
			layers.push(l),
		);
	}

	if (options.inspectionSources && options.inspectionSources.length > 0) {
		inspectionLayers(
			options.inspectionSources,
			options.generalOptions.noMinZoom,
			isTiledInspectionsEnabled,
		).forEach(l => layers.push(l));
	}

	if (options.otherSource) {
		if (
			spatialLayers.length &&
			(options?.useSpecifiedSpatialLayer ?? false)
		) {
			spatialLayers.forEach(l =>
				layers.push({
					id: layerNameToId(l.LAYER_NAME),
					layerName: l.LAYER_NAME,
					tileSource:
						options.otherSource?.dataServiceId ?? 'UNDEFINED',
					tileLayerName: layerNameToId(l.LAYER_NAME),
					dataServiceId:
						options.otherSource?.dataServiceId ?? 'UNDEFINED',
					type: mapGeometryType(l),
				}),
			);
		} else {
			layers.push(
				tilesJsonConfigCreator(
					{
						layerName: options.otherSource.layerName,
						dataServiceId: options.otherSource.dataServiceId,
						tileSource: options.otherSource.dataServiceId,
						requestToken: token,
					},
					options.otherSource.url,
				),
			);
		}
	}

	return layers;
};
