import * as DataFinder from '../DataFinder';
import { getSelectionByCategory } from './DataFinderUtils';

export type SelectionRule = {
	id: string; // The id of the selection
	dependsOn: string[]; // The ids of the selections that this selection depends on
};

/**
 * implement a set of rules for the selection of data
 */
export class SelectionRules {
	rules: SelectionRule[];

	/**
	 * create a new ruleset from the list of `SelectionRules` provided
	 *
	 * @param rules the rules to apply to the selection
	 */
	constructor(rules: SelectionRule[]) {
		this.rules = rules;
	}

	/**
	 * determine if one category depends on another
	 *
	 * @param a the first category
	 * @param b the second category
	 * @returns true if the first category depends on the second, false otherwise
	 */
	dependsOn(a: string, b: string): boolean {
		const rule = this.rules.find(r => r.id === a);
		return rule ? rule.dependsOn.includes(b) : false;
	}

	/**
	 * determine if the current selections are valid (complete)
	 *
	 * @param selections the current selections
	 * @returns true if there is a selection for every category, false otherwise
	 */
	validSelection(selections: DataFinder.Selection[]): boolean {
		return this.rules.every(rule =>
			getSelectionByCategory(selections, rule.id),
		);
	}

	/**
	 *
	 * @param selections the current selections
	 * @param category the category to check
	 * @returns true if all dependencies for the given category are met, false otherwise
	 */
	dependenciesMet(
		selections: DataFinder.Selection[],
		category: string,
	): boolean {
		const rule = this.rules.find(r => r.id === category);
		return rule
			? rule.dependsOn.every(dep =>
					getSelectionByCategory(selections, dep),
			  )
			: false;
	}
}
