/* eslint-disable @typescript-eslint/no-explicit-any */
import WebSocket from 'isomorphic-ws';
import cookies from 'browser-cookies';

import { error, getApiEnvironment } from '@innovyze/stylovyze';
import { getService } from '@innovyze/lib_get_service';
import { WebSocketData } from '@Types/webSocket.types';
import { put } from 'redux-saga/effects';

const URL = getService('amRiskWss', {}, getApiEnvironment());

let webSocket: WebSocketClass;

export const useWebSockets = () => {
	if (webSocket === undefined) {
		webSocket = new WebSocketClass();
	}

	return webSocket;
};
class WebSocketClass {
	private webSocket: WebSocket | null = null;
	private pingInterval: NodeJS.Timeout | null = null;
	private pingIntervalTime = 480000;
	private maxRetries = 5;
	private retries = 0;

	private WebSocketRoutes: Map<string, (data: any) => Promise<void>> =
		new Map<string, (data: any) => Promise<void>>();
	private WebSockeErrorRoutes: Map<string, (data: any) => Promise<void>> =
		new Map<string, (data: any) => Promise<void>>();

	enabled = false;
	connectionId = '';
	lastRequestId = '';

	constructor() {
		this.connect();
	}

	setupRoutes = (
		route: string,
		resultsCallBack: (results: any) => Promise<void>,
		errorsCallBack: (errors: any) => Promise<void>,
	) => {
		if (!this.WebSocketRoutes.has(route)) {
			console.log('setupRoutes', route);
			this.WebSocketRoutes.set(route, resultsCallBack);
			this.WebSockeErrorRoutes.set(route, errorsCallBack);
		}
	};

	private getHandler = (route: string): ((data: any) => Promise<void>) => {
		const handler = this.WebSocketRoutes.get(route);
		if (handler) {
			return handler;
		}

		throw new Error(`Route Handler Mapping is missing for route: ${route}`);
	};

	private getErrorHandler = (
		route: string,
	): ((data: any) => Promise<void>) => {
		const handler = this.WebSockeErrorRoutes.get(route);
		if (handler) {
			return handler;
		}

		throw new Error(`Route Handler Mapping is missing for route: ${route}`);
	};

	private onConnOpen = () => {
		this.enabled = true;
		this.setupPing();
	};

	private onConnClose = () => {
		this.enabled = false;

		if (this.pingInterval) {
			this.clearInterval();
			this.pingInterval = null;
		}
		if (this.retries < this.maxRetries) {
			this.retries++;
			this.connect();
			this.setupPing();
		}
	};

	private onMessage = (event: WebSocket.MessageEvent) => {
		// console.log('Received message: ', event.data);
		try {
			if (event.data === 'pong') {
				this.enabled = true;
				this.setupPing();
				return;
			}

			const data = JSON.parse(event.data as string);
			const type = data.type;
			let handler: (data: WebSocketData) => Promise<void>;
			// console.log('type', type);
			switch (type) {
				case 'msgReceived':
					this.connectionId = data.connectionId;
					this.lastRequestId = data.requestId;
					break;
				case 'ping':
					if (this.enabled && this.webSocket) {
						this.webSocket.send('pong');
					}
					break;
				case 'results':
					handler = this.getHandler(data.route);
					handler(data);
					break;
				case 'error':
					try {
						handler = this.getErrorHandler(data.route);
						handler(data);
					} catch (error) {
						console.error('Error handling message: ', error);
					} finally {
						console.error('Error: ', data.error);
						put(
							error(
								typeof data.payload == 'string'
									? data.payload
									: data.payload.payload,
								data.error,
							),
						);
					}
					break;
			}
		} catch (error) {
			console.error('Error handling message: ', error);
		}
	};

	private onError = (event: WebSocket.ErrorEvent) => {
		console.error('Websocket error: ', event);
	};

	private setupPing = () => {
		this.pingInterval = setInterval(() => {
			if (
				this.webSocket &&
				this.webSocket.readyState === this.webSocket.OPEN
			) {
				this.sendMessage('ping');
				this.enabled = false;
				this.clearInterval();
				this.pingInterval = null;
			}
		}, this.pingIntervalTime);
	};

	private connect = () => {
		if (
			this.webSocket === null ||
			this.webSocket.readyState === this.webSocket.CLOSED
		) {
			this.webSocket = new WebSocket(
				URL + '?token=Bearer ' + cookies.get('auth0.user.token'),
			);
			this.webSocket.onopen = this.onConnOpen;
			this.webSocket.onmessage = this.onMessage;
			this.webSocket.onclose = this.onConnClose;
			this.webSocket.onerror = this.onError;
		}
	};

	private clearInterval = () => {
		if (this.pingInterval) {
			clearInterval(this.pingInterval);
		}
	};

	private reset = () => {
		if (this.pingInterval) {
			this.clearInterval();
		}
		if (
			this.webSocket &&
			this.webSocket.readyState !== this.webSocket.CLOSED
		) {
			this.webSocket.onclose = null;
			this.webSocket.close();
		}
		this.webSocket = null;
		this.pingInterval = null;
		this.maxRetries = 5;
		this.retries = 0;

		this.enabled = false;
		this.connectionId = '';
		this.lastRequestId = '';
	};

	sendMessage = (message: string) => {
		if (
			this.webSocket === null ||
			this.webSocket.readyState == this.webSocket.CLOSED
		) {
			this.reset();
			this.connect();
			setTimeout(() => {
				this.sendMessage(message);
			}, 1000);
		} else if (
			this.webSocket.readyState == this.webSocket.CONNECTING ||
			this.webSocket.readyState == this.webSocket.CLOSING
		) {
			setTimeout(() => {
				this.sendMessage(message);
			}, 1000);
		} else if (
			this.enabled &&
			this.webSocket.readyState == this.webSocket.OPEN
		) {
			this.webSocket?.send(message);
		}
	};
}
