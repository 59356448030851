import * as curveManagerActions from '../actions/curveManager.actions';
import { error as notifyError, success } from '../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { getAssetById, bulkCurveOperation } from '../services/PumpTankCurve';
import {
	BulkActionPayload,
	CheckDuplicateCurveByIdPayload,
	Curve,
	CURVE_TYPES,
	CurveManagerStoreState,
	CurveOperation,
	CurveOperationResponse,
	CurveOperationType,
	CurveType,
	GetCurvesParams,
	PaginatedCurves,
	TableState,
} from '../types/curveManager.types';
import { AxiosResponse } from 'axios';
import { fork, put, retry, select, takeLatest } from 'redux-saga/effects';
import { createSearchQuery } from '../utils/curveManagerUtils';
import { deleteAsset, getSensors } from '../services/PumpTankCurve/sensors';
import { curveManagerSelector } from '../selectors';
import { ROWS_PER_PAGE } from '../types/pagination';

function* bulkCurveOperationSaga(action: PayloadAction<BulkActionPayload>) {
	const { bulkActionResolved, bulkActionRejected } = curveManagerActions;

	try {
		const {
			operation,
			form: { curveData, curveType, xSeries, ySeries, curveName },
			isCurveUnitsEnabled,
			selectedCurve,
		} = action.payload;

		const payload = curveData.map<CurveOperation>(curve => ({
			operation,
			data: {
				type: curveType,
				body: {
					...(operation === CurveOperationType.UPDATE
						? selectedCurve
						: {}),
					name:
						operation === CurveOperationType.UPDATE ||
						curveData.length === 1
							? curveName
							: curve.name,
					depthArray: curve.depthArray,
					volumeArray: curve.volumeArray,
					xSeries: isCurveUnitsEnabled ? xSeries : undefined,
					ySeries: isCurveUnitsEnabled ? ySeries : undefined,
				},
			},
		}));
		const response: AxiosResponse<CurveOperationResponse> = yield retry(
			5,
			1500,
			bulkCurveOperation,
			payload,
		);
		const { items } = response.data;
		const succededOperations =
			items?.filter(
				operation =>
					operation.data?.status && operation.data.status < 300,
			)?.length ?? 0;

		const failedOperations = items?.length - succededOperations;
		yield put(curveManagerActions.closeDialog());
		if (succededOperations > 0) {
			if (succededOperations === 1) {
				yield put(success(`Curve successfully ${operation}d`));
			} else {
				yield put(
					success(
						`${succededOperations} curves successfully ${operation}d`,
					),
				);
			}
		}
		if (failedOperations > 0) {
			if (failedOperations === 1) {
				yield put(notifyError(`Curve was not ${operation}d `));
			} else {
				yield put(
					notifyError(
						`${failedOperations} curves were not ${operation}d`,
					),
				);
			}
		}

		yield put(bulkActionResolved(response.data));
		const {
			paginatedCurveList: { tableState },
		}: CurveManagerStoreState = yield select(curveManagerSelector);
		yield put(
			curveManagerActions.getCurves({
				limit: ROWS_PER_PAGE[tableState.limitIndex],
				offset: 1,
				assetTypes: CURVE_TYPES,
				searchQuery: createSearchQuery(tableState.searchQuery),
			}),
		);
	} catch (error) {
		yield put(bulkActionRejected());
	}
}

function* getAssetCurvesSaga(action: PayloadAction<GetCurvesParams>) {
	const { getCurvesResolved, getCurvesRejected } = curveManagerActions;
	try {
		const response: AxiosResponse<PaginatedCurves> = yield retry(
			5,
			1500,
			getSensors,
			action.payload,
		);
		if (response.data) {
			const convertToNewCurves = (curves: Curve[]): Curve[] => {
				return curves.map(curve => {
					let assetType = curve.assetType;
					if (assetType === CurveType.PRESSURE_CURVE) {
						// This could be old pump curve data, find out the real asset type by Y-Series
						if (curve.ySeries?.type === 'Power') {
							assetType = CurveType.POWER_CURVE;
						} else if (curve.ySeries?.type === 'Efficiency') {
							assetType = CurveType.EFFICIENCY_CURVE;
						}
					}
					return {
						...curve,
						assetType,
						oldAssetType: curve.assetType,
						name: curve.name ?? curve.curveId, // some old curves doesn't have the "name" field
						assetId: curve.assetId ?? curve.curveId, // some old curves doesn't have the "assetId" field
					};
				});
			};

			const { assets, ...otherFields }: PaginatedCurves = response.data;
			yield put(
				getCurvesResolved({
					assets: convertToNewCurves(assets),
					...otherFields,
				}),
			);
		} else {
			throw new Error('OperationStatus != SUCCESS');
		}
	} catch (e) {
		console.error(e);
		yield put(getCurvesRejected());
	}
}

function* checkDuplications(curveName: string, curveType: CurveType) {
	try {
		const response = yield retry(
			5,
			1500,
			getAssetById,
			curveName,
			curveType,
		);
		const { checkDuplicateCurveByIdResolved, pushValidatedAssets } =
			curveManagerActions;
		if (response.data.asset !== null) {
			yield put(checkDuplicateCurveByIdResolved(curveName));
		}
		yield put(pushValidatedAssets(curveName));
		return response.data.asset;
	} catch (err) {
		console.error(err);
		return null;
	}
}

function* checkDuplicationsSaga(action: PayloadAction<BulkActionPayload>) {
	const {
		form: { curveName, curveType },
	} = action.payload;

	const response = yield checkDuplications(curveName, curveType);
	if (response !== null) {
		yield put(curveManagerActions.openOverwriteModal(curveType));
		return;
	}
	yield put(curveManagerActions.bulkAction(action.payload));
}

function* checkDuplicateCurveByIdSaga(
	action: PayloadAction<CheckDuplicateCurveByIdPayload>,
) {
	const { curveType, curveData, curveName } = action.payload.form;
	const { validatedAssets } = action.payload;

	const curveIds =
		curveData
			?.filter(({ name }) => !validatedAssets.includes(name))
			?.map(curve => (curveData.length === 1 ? curveName : curve.name)) ??
		[];
	for (const curveId of curveIds) {
		yield fork(checkDuplications, curveId, curveType);
	}
}

function* deleteCurveSaga(
	action: PayloadAction<{ curveId: string; tableState: TableState }>,
) {
	const { deleteCurveResolved, deleteCurveRejected } = curveManagerActions;
	try {
		const response: AxiosResponse<PaginatedCurves> = yield retry(
			5,
			1500,
			deleteAsset,
			action.payload.curveId,
		);
		if (response.data) {
			yield put(success('Curve successfully deleted'));
			yield put(deleteCurveResolved());
			yield put(
				curveManagerActions.tableAction({
					...action.payload.tableState,
					page: 1,
				}),
			);
			yield put(
				curveManagerActions.getCurves({
					limit: ROWS_PER_PAGE[action.payload.tableState.limitIndex],
					offset: 1,
					assetTypes: CURVE_TYPES,
					searchQuery: createSearchQuery(
						action.payload.tableState.searchQuery,
					),
				}),
			);
		} else {
			throw new Error('OperationStatus != SUCCESS');
		}
	} catch (e) {
		console.error(e);
		yield put(notifyError('Error deleting curve'));
		yield put(deleteCurveRejected());
	}
}

function* watchCheckDuplications() {
	yield takeLatest(
		curveManagerActions.checkDuplications,
		checkDuplicationsSaga,
	);
}

function* watchGetAssetCurves() {
	yield takeLatest(curveManagerActions.getCurves, getAssetCurvesSaga);
}

function* watchBulkCurveOperation() {
	yield takeLatest(curveManagerActions.bulkAction, bulkCurveOperationSaga);
}

function* watchDeleteCurve() {
	yield takeLatest(curveManagerActions.deleteCurve, deleteCurveSaga);
}

function* watchCheckDuplicateCurveById() {
	yield takeLatest(
		curveManagerActions.checkDuplicateCurveById,
		checkDuplicateCurveByIdSaga,
	);
}
export default [
	watchGetAssetCurves(),
	watchDeleteCurve(),
	watchCheckDuplications(),
	watchBulkCurveOperation(),
	watchCheckDuplicateCurveById(),
];
