import { stringifySource } from './utils';
import { useReading } from './reading';
import { useSources } from './source';
import * as CoreData from '../data';
import * as React from 'react';
import * as Utils from '../utils';
import type {
  Analytic,
  OHLCData,
  OHLCDataMap,
  Reading,
  RequestBody,
  ResponseBody,
  ResponseDataEntry,
  SensorData,
  SensorDataMap,
  Source,
  TimeSeriesData,
  TimeSeriesDataMap,
} from './types';
import { useAnalytic } from './analytic';
import { getService } from '@innovyze/lib_get_service';
import { apiConfig } from '../../../apis/config.api';
import { getApiEnvironment } from '@innovyze/stylovyze';

const SERVICE_URL = getService('sensorDataV2', apiConfig, getApiEnvironment());

const MEMOIZED_EMPTY_DATA_ARRAY: SensorData = [];
const MEMOIZED_EMPTY_DATA_MAP: SensorDataMap = new Map();

// TODO: Memoize getRawSensorData, getRawSensorDataMap, getProcessedData and getProcessedDataMap
// These functions may be called even if the raw data / analytic combo hasn't changed, and
// the raw data will be processed again even if it produces the same result.
export function useRetriever(
  sources: Source[],
  options?: Pick<RequestBody, 'type'>
): {
  limit?: number;
  sources: Source[];
  sourceKeys: string[];
  status: CoreData.Status;
  error: Error | undefined;
  timeSelection?: { start?: number; end?: number };
  snapSelection?: { start?: number; end?: number };
  retrieve: (options?: Pick<RequestBody, 'limit' | 'timeSelection'>) => void;
  getSourceKey: (source: Source) => string;
  getEntry: (source: Source | string) => ResponseDataEntry | undefined;
  getRawSensorData: (source: Source | string) => SensorData;
  getRawSensorDataMap: (source: Source | string) => SensorDataMap;
  getProcessedData: <T = TimeSeriesData | OHLCData>(
    source: Source | string,
    analytic: Reading | Analytic | 'OHLC',
    params?: Record<string, unknown>,
    dataOverride?: TimeSeriesData
  ) => T;
  getProcessedDataMap: <T = TimeSeriesDataMap | OHLCDataMap>(
    source: Source | string,
    analytic: Reading | Analytic | 'OHLC',
    params?: Record<string, unknown>,
    dataOverride?: TimeSeriesData
  ) => T;
} {
  const _options = options;
  const _sources = useSources(sources, options);
  const retriever = CoreData.useRetriever<ResponseBody>();
  const axios = Utils.useAxios(SERVICE_URL);
  const reading = useReading();
  const _analytic = useAnalytic();

  const retrieve = React.useCallback(
    (options?: Pick<RequestBody, 'limit' | 'timeSelection'>) => {
      retriever.retrieve(async () => {
        const payload = { ...options, ..._options, sources: _sources.stable };
        const result = await axios.current.post<ResponseBody>(
          `/v2/series/time?type=${_options?.type ?? 'data'}`,
          payload
        );
        return result.data;
      });
    },
    [axios, _sources.stable, retriever.retrieve, _options?.type]
  );

  const getSourceKey = React.useCallback(
    (source: Source): string => {
      return stringifySource(source, { type: options?.type });
    },
    [options?.type]
  );

  const getEntry = React.useCallback(
    (source: Source | string): ResponseDataEntry | undefined => {
      const key = typeof source === 'string' ? source : getSourceKey(source);
      return retriever.result?.entries[key];
    },
    [getSourceKey, retriever.result?.entries]
  );

  const getRawSensorData = React.useCallback(
    (source: Source | string): SensorData => {
      const entry = getEntry(source);
      if (entry === undefined) return MEMOIZED_EMPTY_DATA_ARRAY;
      return (
        retriever.result?.entries[entry.key].data ?? MEMOIZED_EMPTY_DATA_ARRAY
      );
    },
    [getEntry, retriever.result?.entries]
  );

  const getRawSensorDataMap = React.useCallback(
    (source: Source | string): SensorDataMap => {
      const dataArray = getRawSensorData(source);
      const dataMap: SensorDataMap = new Map();

      if (dataArray.length === 0) return MEMOIZED_EMPTY_DATA_MAP;

      for (const [timestamp, ...measures] of dataArray) {
        const measuresMap: Map<Reading, number | null> = new Map();

        for (let i = 0; i < measures.length; i++) {
          const value = measures[i];
          const key = reading.at(i);
          if (key !== undefined) measuresMap.set(key, value);
        }

        dataMap.set(timestamp, measuresMap);
      }

      return dataMap;
    },
    [getRawSensorData, reading.at]
  );

  const getProcessedData = React.useCallback(
    <T = TimeSeriesData | OHLCData>(
      source: Source | string,
      analytic: Reading | Analytic | 'OHLC',
      params?: Record<string, unknown>,
      dataOverride?: TimeSeriesData
    ): T => {
      const rawData = getRawSensorData(source);

      if (reading.isValid(analytic)) {
        return reading.getProcessedData(rawData, analytic, dataOverride) as T;
      } else if (_analytic.isValid(analytic)) {
        return _analytic.getProcessedData(
          rawData,
          analytic,
          params,
          dataOverride
        ) as T;
      }

      return [] as T;
    },
    [getRawSensorData, reading.getProcessedData, reading.isValid]
  );

  const getProcessedDataMap = React.useCallback(
    <T = TimeSeriesDataMap | OHLCDataMap>(
      source: Source | string,
      analytic: Reading | Analytic | 'OHLC',
      params?: Record<string, unknown>,
      dataOverride?: TimeSeriesData
    ): T => {
      const processedData = getProcessedData(
        source,
        analytic,
        params,
        dataOverride
      );

      const processedDataMap: TimeSeriesDataMap = new Map();

      for (let i = 0; i < processedData.length; i++) {
        const [timestamp, value] = processedData[i];
        processedDataMap.set(timestamp, value);
      }

      return processedDataMap as T;
    },
    [getProcessedData]
  );

  return {
    limit: retriever.result?.limit,
    sources: _sources.stable,
    sourceKeys: _sources.keys,
    status: retriever.status,
    error: retriever.error,
    timeSelection: retriever.result?.timeSelection,
    snapSelection: retriever.result?.snapSelection,
    retrieve,
    getEntry,
    getRawSensorData,
    getRawSensorDataMap,
    getProcessedData,
    getProcessedDataMap,
    getSourceKey,
  };
}
