import styled from 'styled-components';

export const ErrorTitle = styled.h5`
	color: #aa0000;
	margin-bottom: 0;
	font-size: 15px;
`;

export const ErrorList = styled.ul`
	margin-top: 10px;
	padding-left: 30px;
`;

export const ErrorListItem = styled.li`
	&::marker {
		color: #aa0000;
	}
`;
