import { Environments, getApiEnvironment } from '@innovyze/stylovyze';

import config from './config.api';
import { formatUrlTrailing } from '@Utils/urlFormatting';
import { axiosWithHeaders, getService } from '@innovyze/lib_get_service';

const apiEnv =
	(localStorage.getItem('apiEnv') as keyof typeof Environments) || undefined;

export const baseAssetsUrl = getService('assets', config, getApiEnvironment());
export const assetsBaseUrl = formatUrlTrailing(
	getService('assets', config, apiEnv),
);

const service = axiosWithHeaders({
	service: 'assets',
	config,
	axiosCreateOpts: {
		timeout: 30000,
	},
});

export default service;
