import { ButtonGroup } from '@mui/material';
import { rem } from '../../utils/styles';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Icon = styled.span`
	.MuiSvgIcon-root {
		&& {
			font-size: ${rem(20)};
			margin-right: ${rem(5)};
			vertical-align: text-bottom;
		}
	}
`;
export const SelectedIcon = styled.span`
	margin-left: ${rem(-4)};
	margin-right: ${rem(8)};
	.MuiSvgIcon-root {
		&& {
			font-size: ${rem(20)};
			vertical-align: text-bottom;
		}
	}
`;

export const StyledButtonGroup = styled(ButtonGroup)`
	box-shadow: 0 0 0 0;
`;
