/* eslint-disable no-console */
import { ExtractionKeys, e, t } from '@Translations/extraction';

import { ConfigLayer } from '@Root';
import { ConfigOverrides } from '@Map/types';
import { IconSet } from './types';
import { notUndefined } from '@Map/utils';
import { reduceArrayToObject } from '@Map/utils';
import { assetSchemaSelector } from '@innovyze/lib_asset_schema_store';
import { AssetSchema } from '@innovyze/lib_asset_schema';
import sharedStore from '../../sharedStore';

interface AssetDetailType {
	displayName: string;
	layerName: string;
	icon?: string;
	color?: string;
	defaultMinZoom?: number;
	layerType?: ConfigLayer['type'];
}

type TranslateAssetDetailType = ExtractionKeys<
	AssetDetailType,
	'displayName' | 'layerName'
>;

export const assetUnknown: TranslateAssetDetailType = {
	displayName: e('Unknown'),
	layerName: e('Unknown'),
	icon: 'unknown',
	color: '#4A6067',
};

export const getAssetSchema = (id: string): AssetSchema | undefined => {
	const state = sharedStore.store?.getState();
	if (!state) return;
	if (Object.keys(state.assetSchema).length === 0) {
		throw new Error('No asset schema found');
	}
	const assetSchema = assetSchemaSelector(state);
	return assetSchema[id] || undefined;
};

export const getAllAssetSchemas = (): Record<string, AssetSchema> => {
	const state = sharedStore.store?.getState();
	if (!state) return {};
	const allAssetSchemas = assetSchemaSelector(state);
	return allAssetSchemas;
};

export const switchGeometryType = (schema: AssetSchema) => {
	switch (schema?.geometryType) {
		case 'LineString':
			return 'line';
		case 'Polygon':
			return 'polygon';
		default:
			return 'symbol';
	}
};

/**
 * Uses the asset schema to determine the layer/displayName, icon and color for the asset type
 */
export default class AssetRegistry {
	// these asset types were supported before but are not yet part of the asset schema, these are still here so there are no breaking changes.
	// when we have an answer about how to deal with these in the schema we can remove them.
	private static assetTypes: { [index: string]: TranslateAssetDetailType } = {
		customer: {
			displayName: e('Customer', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Customers', {
				context: 'Asset registry - plural item',
			}),
			icon: 'customer',
			color: '#4A6067',
			defaultMinZoom: 18,
		},
		facility: {
			displayName: e('Facility', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Facilities', {
				context: 'Asset registry - plural item',
			}),
			icon: 'facilities',
			color: '#AA5D00',
			defaultMinZoom: 18,
		},
		flow_meter: {
			displayName: e('Flow Meter', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Flow Meters', {
				context: 'Asset registry - plural item',
			}),
			icon: 'flow-meter',
			color: '#AA0000',
		},
		sensor: {
			displayName: e('Sensor', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Sensors', {
				context: 'Asset registry - plural item',
			}),
			icon: 'sensor',
			color: '#AA0000',
			defaultMinZoom: 11,
		},
		'sensor-alert': {
			displayName: e('Alert', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Alerts', {
				context: 'Asset registry - plural item',
			}),
			icon: 'sensor-alert',
			color: '#AA0000',
		},
		valve_open_inoperable: {
			displayName: e('Valve Open Inoperable', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'valve-open-inoperable',
			color: '#007ca0',
		},
		valve_closed_inoperable: {
			displayName: e('Valve Closed Inoperable', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'valve-closed-inoperable',
			color: '#007ca0',
		},
		valve_open: {
			displayName: e('Valve Open', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'valve-open',
			color: '#007ca0',
		},
		valve_closed: {
			displayName: e('Valve Closed', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'valve-closed',
			color: '#007ca0',
		},
		valve_unknown: {
			displayName: e('Valve Unknown', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'valve-unknown',
			color: '#007ca0',
		},
		pipe_break: {
			displayName: e('Pipe Break', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Pipe Breaks', {
				context: 'Asset registry - plural item',
			}),
			icon: 'pipe-break',
			color: '#007ca0',
		},
		lamphole: {
			displayName: e('Lamphole', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Lampholes', {
				context: 'Asset registry - plural item',
			}),
			icon: 'lamphole',
			color: '#4a6067',
		},
		cleanout: {
			displayName: e('Cleanout', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Cleanouts', {
				context: 'Asset registry - plural item',
			}),
			icon: 'cleanout',
			color: '#4a6067',
		},
		defect: {
			displayName: e('Defect', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Defects', {
				context: 'Asset registry - plural item',
			}),
			icon: 'defect',
			color: '#4a6067',
		},
		data_logger: {
			displayName: e('Data logger', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Data loggers', {
				context: 'Asset registry - plural item',
			}),
			icon: 'data-logger',
		},
		wwDataLogger: {
			displayName: e('Data logger', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Data loggers', {
				context: 'Asset registry - plural item',
			}),
			icon: 'data-logger',
		},
		valve: {
			displayName: e('Valve', {
				context: 'Asset registry - singular item',
			}),
			layerName: e('Valves', {
				context: 'Asset registry - plural item',
			}),
			icon: 'valve',
			layerType: 'line',
		},
	};

	public static getIcon(id: string): string | undefined {
		const schema = getAssetSchema(id);
		if (!schema) {
			return AssetRegistry.assetTypes[id]?.icon;
		}
		return schema?.defaultIcon || undefined;
	}

	public static getDisplayName(id: string): string {
		const schema = getAssetSchema(id);
		if (!schema)
			return t(
				AssetRegistry.assetTypes[id]?.displayName ||
					assetUnknown.displayName,
			);
		return t(schema?.displayName || assetUnknown.displayName);
	}

	public static getLayerName(id: string): string {
		const schema = getAssetSchema(id);
		if (!schema)
			return t(
				AssetRegistry.assetTypes[id]?.layerName ||
					assetUnknown.layerName,
			);
		return t(schema?.pluralName || assetUnknown.layerName);
	}

	public static getColor(id: string): string {
		const schema = getAssetSchema(id);
		if (!schema)
			return (
				AssetRegistry.assetTypes[id]?.color ||
				assetUnknown.color ||
				'#4A6067'
			);
		return schema?.defaultColour || assetUnknown.color || '#4A6067';
	}

	public static getIconSet(): IconSet {
		const allAssetSchemas = getAllAssetSchemas();
		const schemaIcons = reduceArrayToObject(
			Object.entries(allAssetSchemas)
				.map(([assetType, detail]) => {
					return detail.defaultIcon
						? { [assetType]: detail.defaultIcon }
						: undefined;
				})
				.filter(notUndefined),
		);

		const registryIcons = reduceArrayToObject(
			Object.entries(AssetRegistry.assetTypes)
				.map(([assetType, detail]) =>
					detail.icon
						? {
								[assetType]: detail.icon,
						  }
						: undefined,
				)
				.filter(notUndefined),
		);

		const allIcons = { ...schemaIcons, ...registryIcons };
		return allIcons;
	}

	public static getLayerType(id: string): ConfigLayer['type'] {
		const schema = getAssetSchema(id);
		if (!schema) return AssetRegistry.assetTypes[id]?.layerType || 'symbol';
		return switchGeometryType(schema);
	}

	private static formatConfigDefaults(
		assetType: string,
		detail: AssetSchema | TranslateAssetDetailType,
	): ConfigOverrides {
		const layerType = switchGeometryType(detail as AssetSchema);
		const defaultIcon =
			(detail as AssetSchema).defaultIcon ||
			(detail as TranslateAssetDetailType).icon;
		const pluralName =
			(detail as AssetSchema).pluralName ||
			(detail as TranslateAssetDetailType).layerName;
		return {
			assetType,
			systemType: 'WaterDistribution',
			icon: defaultIcon || undefined,
			minZoom: detail.defaultMinZoom || (layerType === 'line' ? 2 : 16),
			iconMinZoom: layerType === 'line' && defaultIcon ? 16 : 2,
			layerName: pluralName,
			type: layerType || 'symbol',
		};
	}

	public static getConfigDefaults(): ConfigOverrides[] {
		const allAssetSchemas = getAllAssetSchemas();
		const schemaDetails = Object.entries(
			allAssetSchemas,
		).map(([assetType, detail]) =>
			AssetRegistry.formatConfigDefaults(assetType, detail),
		);

		const registryDetails = Object.entries(
			AssetRegistry.assetTypes,
		).map(([assetType, detail]) =>
			AssetRegistry.formatConfigDefaults(assetType, detail),
		);

		return [...schemaDetails, ...registryDetails];
	}

	public static getConfigDefaultsForAsset(
		id: string,
	): ConfigOverrides | undefined {
		if (!id) return;
		const schemaDetail = getAssetSchema(id);
		if (!schemaDetail) {
			const detail = AssetRegistry.assetTypes[id];
			return AssetRegistry.formatConfigDefaults(id, detail);
		}
		return AssetRegistry.formatConfigDefaults(id, schemaDetail);
	}
}
