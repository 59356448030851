import {
	MapInsert,
	MapRiskLayer,
	MapSpatialInsert,
	NamespacedStoreState,
	RiskLayersMask,
} from '@Types';
import {
	configLayers,
	dataServices,
	selectedAssets,
	selectedPosition,
	tileOptions,
} from '@Utils';

import { UserContextStoreState } from '@innovyze/stylovyze';
import { createSelector } from '@reduxjs/toolkit';
import { mapInsertInitial } from '../reducers/map.reducer';
import { moduleNamespace } from '@Store/config.store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

export const getTenant = (state: UserContextStoreState): string =>
	state.userContext.defaultSite;

const mapSelector = createSelector(parseNamespace, state => state.map);
const mapMain = createSelector([mapSelector, getTenant], (base, tenantId) => {
	if (tenantId && base.main.byTenant && base.main.byTenant[tenantId]) {
		return base.main.byTenant[tenantId];
	}
	return base.main;
});

export const getMapPosition = createSelector(mapMain, main => main.position);

export const getMapBackground = createSelector(
	mapMain,
	main => main.background,
);

export const getMapSelected = createSelector(mapMain, main => main.selected);

export const getMapSelectedIds = createSelector(mapSelector, baseState =>
	selectedAssets(baseState.main.selected),
);

export const getMapSelectedPosition = (zoom: number) => {
	return createSelector(getMapSelected, selected =>
		selectedPosition(selected, zoom),
	);
};

export const getMapHiddenLayers = createSelector(
	mapMain,
	main => main.hiddenLayers,
);

const riskLayers = createSelector(mapSelector, base => base.risk?.layers ?? []);

export const selectMapRehabLayers = createSelector(
	mapSelector,
	base => base.rehab?.layers ?? [],
);

const showRiskLayer = (
	mask: RiskLayersMask | undefined,
	value: string | undefined,
): boolean => {
	if (mask !== undefined) {
		// Should match RiskConfigPublishState in saas_risk_management
		switch (value) {
			case 'published':
				return (
					(mask & RiskLayersMask.Published) ===
					RiskLayersMask.Published
				);
			case 'unpublished':
				return (
					(mask & RiskLayersMask.Unpublished) ===
					RiskLayersMask.Unpublished
				);
		}
	}

	return false;
};

export const riskLayersSelector = (mask: RiskLayersMask) =>
	createSelector(riskLayers, riskLayers =>
		riskLayers.filter((x: MapRiskLayer) =>
			showRiskLayer(mask, x.publishState),
		),
	);

export const useSelectRiskLayers = (mask: RiskLayersMask): MapRiskLayer[] =>
	useSelector(riskLayersSelector(mask));

export const selectRiskLayers = (mask: RiskLayersMask): MapRiskLayer[] => {
	const data = useSelectRiskLayers(mask);
	useEffect(() => {
		console.warn(
			'Deprecated',
			'Due to the rules of hooks we should call hooks with `use` prefix',
			'use useSelectRiskLayers instead of selectRiskLayers',
		);
	}, []);
	return data;
};
const mapInspection = createSelector(
	mapSelector,
	base => base.inspectionInsert,
);
const mapInspectionInsert = createSelector(
	mapInspection,
	base => base.insert ?? [],
);

const sourceLayersFixedLocation = (
	token: string | undefined,
	insert: MapInsert,
): MapInsert => ({
	...insert,
	sources: insert ? dataServices(token, insert.options, insert.location) : [],
	layers: insert ? configLayers(token, insert.options) : [],
});

export const mapInsertInspectionSelector = (token: string | undefined) =>
	createSelector(mapInspectionInsert, insert =>
		sourceLayersFixedLocation(token, insert),
	);

export const useSelectMapInsertInspection = (
	token: string | undefined,
): MapInsert => useSelector(mapInsertInspectionSelector(token));

export const selectMapInsertInspection = (
	token: string | undefined,
): MapInsert => {
	const data = useSelectMapInsertInspection(token);
	useEffect(() => {
		console.warn(
			'Deprecated',
			'Due to the rules of hooks we should call hooks with `use` prefix',
			'use useSelectMapInsertInspection instead of selectMapInsertInspection',
		);
	}, []);
	return data;
};

const mapAsset = createSelector(mapSelector, base => base.assetInsert ?? []);
const mapAssetInsert = createSelector(mapAsset, base => base.insert);

export const mapInsertAssetSelector = (token: string | undefined) =>
	createSelector(mapAssetInsert, (insert: MapInsert) =>
		sourceLayersFixedLocation(token, insert),
	);

export const useSelectMapInsertAsset = (token: string | undefined): MapInsert =>
	useSelector(mapInsertAssetSelector(token));

export const selectMapInsertAsset = (token: string | undefined): MapInsert => {
	const data = useSelectMapInsertAsset(token);
	useEffect(() => {
		console.warn(
			'Deprecated',
			'Due to the rules of hooks we should call hooks with `use` prefix',
			'use useSelectMapInsertAsset instead of selectMapInsertAsset',
		);
	}, []);
	return data;
};

const mapSpatials = createSelector(mapSelector, base => base.spatialInserts);

const sourceLayersSpatialLocation = (
	token: string | undefined,
	layerName: string,
	inserts: MapSpatialInsert[],
	showSystemTypeLayers = false,
): MapInsert => {
	const spatial = inserts.find(
		f => f.layer.LAYER_NAME.toLowerCase() === layerName.toLowerCase(),
	);

	if (!spatial) {
		return { ...mapInsertInitial };
	}

	const options = { ...spatial.insert.options };

	if (showSystemTypeLayers) {
		options.tilesSource = { ...tileOptions };
		options.tilesSourceHide = false;
	}

	return {
		...spatial.insert,
		sources: dataServices(token, options, spatial.insert.location),
		layers: configLayers(token, options, [spatial.layer]),
	};
};

export const mapInsertSpatialSelector = (
	token: string | undefined,
	layerName: string,
	showSystemTypeLayers = false,
) =>
	createSelector(mapSpatials, mapSpatials =>
		sourceLayersSpatialLocation(
			token,
			layerName,
			mapSpatials,
			showSystemTypeLayers,
		),
	);

export const useSelectMapInsertSpatial = (
	token: string | undefined,
	layerName: string,
	showSystemTypeLayers = false,
): MapInsert =>
	useSelector(
		mapInsertSpatialSelector(token, layerName, showSystemTypeLayers),
	);

export const selectMapInsertSpatial = (
	token: string | undefined,
	layerName: string,
	showSystemTypeLayers = false,
): MapInsert => {
	const data = useSelectMapInsertSpatial(
		token,
		layerName,
		showSystemTypeLayers,
	);
	useEffect(() => {
		console.warn(
			'Deprecated',
			'Due to the rules of hooks we should call hooks with `use` prefix',
			'use useSelectMapInsertSpatial instead of selectMapInsertSpatial',
		);
	}, []);
	return data;
};

export default {
	useSelectMapInsertSpatial,
	useSelectRiskLayers,
	useSelectMapInsertInspection,
	useSelectMapInsertAsset,
	getMapPosition,
	getMapBackground,
	getMapSelected,
	getMapSelectedIds,
	getMapSelectedPosition,
	getMapHiddenLayers,
	mapInsertInspectionSelector,
	selectMapInsertInspection,
	mapInsertAssetSelector,
	selectMapInsertAsset,
	mapInsertSpatialSelector,
	selectMapInsertSpatial,
	selectRiskLayers,
	riskLayersSelector,
	selectMapRehabLayers,
};
