import config from './config.api';
import { axiosWithHeaders } from '@innovyze/lib_get_service';

const uploadService = axiosWithHeaders({
	service: 'amUpload',
	config,
	axiosCreateOpts: {
		timeout: 15000,
	},
});

export default uploadService;
