import { Store, applyMiddleware, combineReducers, createStore } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { moduleNamespace } from './config.store';
import reducers from '@Reducers';
import rootSaga from '../sagas';
import { reducers as sharedReducers } from '@innovyze/stylovyze';
import libAmCommon from '@innovyze/lib-am-common/Reducers';
import libAmCommonSaga from '@innovyze/lib-am-common/Sagas';
import { all } from 'redux-saga/effects';
import {
	moduleNamespace as mapUiNamespace,
	reducers as mapUiReducers,
	sagas as mapUiSagas,
} from '@innovyze/map-components';
import {
	assetSchemaRootReducer,
	assetSchemaRootSaga,
} from '@innovyze/lib_asset_schema_store';

const sagaMiddleware = createSagaMiddleware();

declare global {
	interface Window {
		store: Store;
		Cypress: object;
	}
}

const store: Store = createStore(
	combineReducers({
		[moduleNamespace]: reducers,
		[mapUiNamespace]: mapUiReducers,
		...sharedReducers,
		libAmCommon,
		...assetSchemaRootReducer,
	}),
	composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

const masterRootSaga = function* () {
	yield all([
		rootSaga(),
		libAmCommonSaga(),
		mapUiSagas(),
		assetSchemaRootSaga(),
	]);
};
sagaMiddleware.run(masterRootSaga);

if (window.Cypress) {
	window.store = window.store || {};
	window.store = store;
}

export default store;
