import {
	AddressInfo,
	AssetGridWrapper,
	CloseButton,
	CloseIcon,
	LongLatLocation,
	PlaceName,
	PreviewContainer,
	SearchContainer,
	Wrapper,
} from './ReactMap.styles';
import { Anchor, Popup } from '@Components';
import {
	BackgroundTypes,
	GeocoderResult,
	LayerToggle,
	MapFunction,
	MapSelectedAssets,
} from '@Map/types';
import { GEOCODE_POPUP, SEARCH_RESULT } from '@Map/geocoder/constants';
import { GRID_TOGGLED, GRID_TOGGLE_STATE } from '@Map/GridControl';
import {
	PANEL_BACKGROUND_CHANGED,
	PANEL_LAYER_TOGGLE,
	PANEL_VISIBILITY_CHANGED,
} from '@Map/panel/constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	cx,
	functionalityEnabled,
	getDisplayMode,
	getEditMode,
	lngLatToFixed,
} from '@Map/utils';
import {
	useDebounce,
	useEventListener,
	usePrevious,
	useShallowEqlEffect,
} from '@Hooks';

import { useDispatch, useStore } from 'react-redux';
import { BackgroundRegistry } from '@Map/panel/BackgroundRegistry';
import ControlsContainer from '@Components/ControlsContainer/ControlsContainer';
import { MapElement } from '../mapElement';
import { MapboxProvider } from '../context/MapboxProvider';
import { Messages } from '@Components/Messages/Messages';
import MessagesSnackbarContent from '@Components/Messages/MessagesSnackbarContent';
import PolygonControl from '@Components/PolygonControl/PolygonControl';
import { ReactMapProps } from './types';
import ReactResizeDetector from 'react-resize-detector';
import { SnackbarProvider } from 'notistack';
import sharedStore from '../sharedStore';
import { useDebug } from './DebugContext';
import { useMapConfig } from './useMapConfig';
import { useTheme } from 'styled-components';
import {
	getAllAssetsSchemaAction,
	getAllSystemsSchemaAction,
} from '@innovyze/lib_asset_schema_store';

interface Size {
	width: number;
	height: number;
}

const ReactMap = ({
	dataServices,
	layerConfig,
	summaryPanel,
	onSelectedAssetsChange,
	onBackgroundChanged,
	background,
	mode,
	selectedAssets,
	hiddenLayers,
	onHiddenLayersChange,
	position,
	onPositionChange,
	geocoderId,
	animationControlId,
	searchEndpoints,
	selectionOptions,
	onBoundsChange,
	layerPanelOpen: layerPanelInitiallyOpen,
	hoverPopup,
	hoverPopupDebounce,
	showMinZoomWarning,
	onSearch,
	defaultSearch,
	restrictSearchToBounds,
	pitchControl,
	enableColorByAttributeRange,
	assetGrid,
	printPreview,
	disableFitToExtents,
	enableColorByRangeHeatmap,
	focusedAssets,
	zoomToFocusedAssets,
	polygonControl,
	onPolygonComplete,
	themeEditorMode,
	enableStyleEditing,
	fitBoundsOptions,
	iconSet,
	logger,
	datagridFilter,
	datagridFilterEndpoint,
	datagridFilterEndpoints,
	tracingEndpoint,
	logLevel,
	logContext,
	config,
	...localPropOverrides
}: ReactMapProps): JSX.Element => {
	const [isMapRefSet, setIsMapRefSet] = useState(false);
	const [isMessagesRefSet, setIsMessagesRefSet] = useState(false);
	const map = useRef<MapElement | null>(null);
	const [items, setItems] = useState<MapSelectedAssets | null>(null);
	const [popupProps, setPopupProps] = useState({});
	const [geocoderPopupProps, setGeocoderPopupProps] = useState({});
	const searchRef = useRef<HTMLDivElement | null>(null);
	const panelRef = useRef<HTMLDivElement | null>(null);
	const previewRef = useRef<HTMLDivElement | null>(null);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const messagesRef = useRef<HTMLDivElement | null>(null);
	const [size, setSize] = useState<Size>({
		width: 0,
		height: 0,
	});
	const debouncedSize = useDebounce(size, 200);
	const previousSize = usePrevious(debouncedSize);
	const displayMode = getDisplayMode(mode);
	const [showGridPanel, setShowGridPanel] = useState(false);
	const {
		bearerToken,
		mapKey,
		arcGISBasemapStylesToken,
		defaultBounds,
		basemapOrigin,
		distanceUnit,
		themeEndpoint,
		colorByMaxValues,
		showMultiThemes,
		localStoragePrefix,
		unitSystem,
		defaultThemeId,
		searchRadius,
	} = useMapConfig({ ...config, ...localPropOverrides });
	const editorMode = getEditMode(themeEditorMode, enableStyleEditing);
	const theme = useTheme();
	const debug = useDebug();
	const mapLogLevel = localStorage.getItem('mapLogLevel') ?? logLevel;

	const store = useStore();

	useEffect(() => {
		sharedStore.store = store;
	}, [store]);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getAllAssetsSchemaAction());
		dispatch(getAllSystemsSchemaAction());
	}, []);

	useEffect(() => {
		map?.current?.setMessageElement?.(messagesRef.current);
	}, [messagesRef]);

	useEffect(() => {
		if (disableFitToExtents != undefined) {
			map.current?.setDisableFitToExtents?.(disableFitToExtents);
		}
	}, [disableFitToExtents]);

	useEffect(() => {
		if (fitBoundsOptions) {
			map.current?.setFitBoundsOptions?.(fitBoundsOptions);
		}
	}, [fitBoundsOptions]);

	useEffect(() => {
		if (iconSet) {
			map?.current?.setIconSet?.(iconSet);
		}
	}, [iconSet]);

	// only runs when the dataSources change
	useShallowEqlEffect(() => {
		if (dataServices) {
			map?.current?.setDataServices?.(dataServices);
		} else {
			map?.current?.clearDataServices?.();
		}
	}, [dataServices]);

	useEffect(() => {
		if (themeEndpoint) {
			map?.current?.setThemeEndpoint?.(themeEndpoint);
		}
	}, [themeEndpoint]);

	useEffect(() => {
		if (tracingEndpoint) {
			map?.current?.setTracingEndpoint?.(tracingEndpoint);
		}
	}, [tracingEndpoint]);

	useShallowEqlEffect(() => {
		if (layerConfig) {
			map?.current?.setLayerConfig?.(layerConfig);
		}
	}, [layerConfig]);

	const selectedAssetsChanged = useCallback(
		e => {
			const selectedItems = e.detail.length ? e.detail : null;
			onSelectedAssetsChange?.(selectedItems);
			debug.setSelectedAssets?.(selectedItems);
			if (summaryPanel) setItems(selectedItems);
		},
		[summaryPanel, onSelectedAssetsChange, debug],
	);

	useEventListener('selectedassets', selectedAssetsChanged, map);

	useEffect(() => {
		if (selectedAssets) {
			map?.current?.setSelectedAssets?.(selectedAssets);
		}
	}, [selectedAssets]);

	useEffect(() => {
		if (hiddenLayers) {
			map.current?.setHiddenLayers?.(hiddenLayers);
		}
	}, [hiddenLayers]);

	useEffect(() => {
		if (position) {
			map.current?.setPosition?.(position);
		} else {
			map.current?.resetPosition?.();
		}
	}, [position]);

	const positionChange = useCallback(
		e => {
			onPositionChange?.(e.detail);
			debug.setPosition?.(e.detail);
		},
		[onPositionChange, debug],
	);

	useEventListener('position', positionChange, map);

	useEffect(() => {
		const initialised = !previousSize?.height && !previousSize?.width;
		if (initialised) return;
		if (
			debouncedSize.height !== previousSize?.height ||
			debouncedSize.width != previousSize?.width
		) {
			map.current?.resize?.();
		}
	}, [debouncedSize, previousSize]);

	useEffect(() => {
		if (logger) {
			map.current?.setLogger?.(logger);
		}
	}, [logger]);

	useEffect(() => {
		if (logContext) {
			map.current?.setLoggerContext?.(logContext);
		}
	}, [logContext]);

	useEffect(() => {
		if (defaultBounds) {
			map.current?.setDefaultBounds?.(
				defaultBounds,
				unitSystem,
				searchRadius,
			);
		}
	}, [defaultBounds, unitSystem, searchRadius]);

	useEffect(() => {
		if (datagridFilter) {
			let endpoints = datagridFilterEndpoints;
			if (datagridFilterEndpoint && !datagridFilterEndpoints) {
				endpoints = [
					{
						endpoint: datagridFilterEndpoint,
						dataType: 'all',
					},
				];
			}
			if (!endpoints) return;
			map.current?.setDataGridFilter?.(datagridFilter, endpoints);
		}
	}, [datagridFilter, datagridFilterEndpoint, datagridFilterEndpoints]);

	const deselectAssets = useCallback(
		(assets: string[]) => {
			/** istanbul ignore next */
			if (!items) return;
			const newSelectedItems = items.filter(
				item => !assets.includes(item.id),
			);
			map?.current?.setSelectedAssets?.(newSelectedItems);
		},
		[items],
	);

	useEffect(() => {
		if (searchEndpoints) {
			map.current?.setSearchEndpoints?.(searchEndpoints);
		}
	}, [searchEndpoints]);

	useEffect(() => {
		if (selectionOptions) {
			map.current?.setSelectionOptions?.(selectionOptions);
		}
	}, [selectionOptions]);

	useShallowEqlEffect(() => {
		if (focusedAssets) {
			map.current?.setFocusedAssets?.(focusedAssets);
		}
	}, [focusedAssets]);

	const highlightAssets = useCallback(
		(assets: string[]) => {
			/** istanbul ignore next */
			if (!items) return;
			const highlightItems = items.filter(item =>
				assets.includes(item.id),
			);
			map.current?.setHighlightedAssets?.(highlightItems);
		},
		[items],
	);

	const zoomToAssets = useCallback(
		(assets: string[]) => {
			/** istanbul ignore next */
			if (!items) return;
			const zoomToItems = items.filter(item => assets.includes(item.id));
			map.current?.zoomToAssets?.(zoomToItems);
		},
		[items],
	);

	const traceFromAsset = useCallback((assetId: string, dsTrace?: boolean) => {
		if (!assetId) return;
		map?.current?.traceFromAsset?.(assetId, dsTrace);
	}, []);

	const boundsChange = useCallback(
		e => {
			onBoundsChange?.(e.detail);
			debug.setBounds?.(e.detail);
		},
		[onBoundsChange, debug],
	);

	useEventListener('boundsChanged', boundsChange, map);

	const hoverPopupChange = useCallback(e => {
		setPopupProps(e.detail);
	}, []);

	const cancelPopupUpdate = useCallback(() => {
		map?.current?.cancelPopupUpdate?.();
	}, []);

	useEventListener('hoverPopup', hoverPopupChange, map);

	const geocoderPopupChange = useCallback(e => {
		const detail = e.detail;
		setGeocoderPopupProps(detail);
	}, []);

	useEventListener(GEOCODE_POPUP, geocoderPopupChange, map);

	const searchResult = useCallback(
		e => {
			const detail = e.detail;
			onSearch?.(detail);
		},
		[onSearch],
	);

	useEventListener(SEARCH_RESULT, searchResult, map);

	const layersDebug = useCallback(
		e => {
			debug.setLayersDebug?.(e.detail);
		},
		[debug],
	);

	useEventListener('layersDebug', layersDebug, map);

	const backgroundChanged = useCallback(
		e => {
			const newBackground = e.detail.backgroundInfo;
			onBackgroundChanged?.(newBackground);
			debug.setBackground?.(newBackground);
		},
		[onBackgroundChanged, debug],
	);

	useEventListener(PANEL_BACKGROUND_CHANGED, backgroundChanged, map);

	const hiddenLayersChange = useCallback(
		e => {
			const { hiddenLayers } = e.detail as LayerToggle;
			onHiddenLayersChange?.(hiddenLayers);
			debug.setHiddenLayers?.(hiddenLayers);
		},
		[onHiddenLayersChange, debug],
	);

	useEventListener(PANEL_LAYER_TOGGLE, hiddenLayersChange, map);

	const gridToggled = useCallback(e => {
		const toggleState = e.detail;
		if (toggleState === GRID_TOGGLE_STATE.on) {
			setShowGridPanel(true);
		} else {
			setShowGridPanel(false);
		}
	}, []);

	useEventListener(GRID_TOGGLED, gridToggled, map);

	// ------ Layer Panel visibility
	// This reflects the visibility of the layer panel and the theme editor
	// It uses a custom event to communicate between the map and the react component
	const [layerPanelOpen, setLayerPanelOpen] = React.useState(
		layerPanelInitiallyOpen ?? false,
	);
	const [layerPanelEditorOpen, setLayerPanelEditorOpen] = React.useState(
		false,
	);

	const mapPanelVisibilityChanged = useCallback(
		(event: Event) => {
			const { open, editorOpen } = (event as CustomEvent<{
				open: boolean;
				editorOpen: boolean;
			}>).detail;
			setLayerPanelOpen(open);
			setLayerPanelEditorOpen(editorOpen);
		},
		[setLayerPanelOpen, setLayerPanelEditorOpen],
	);

	useEventListener(PANEL_VISIBILITY_CHANGED, mapPanelVisibilityChanged, map);
	// ------ Layer Panel visibility

	const setSearch = (search: string) => (e: React.MouseEvent) => {
		e.preventDefault();
		map?.current?.setSearch?.(search);
	};

	const previewSearch = (location?: number[] | false) => () => {
		if (!location) return;
		map?.current?.setSearchPreview?.(location);
	};

	const clearPreviewSearch = () => {
		map?.current?.clearSearchPreview?.();
	};

	const closeGeocodePopup = () => {
		setGeocoderPopupProps({});
		map?.current?.removeMarker?.();
	};

	const mouseOutsideMap = () => {
		map?.current?.outsideMap?.();
	};

	useEffect(() => {
		if (defaultSearch) {
			if (geocoderId) {
				console.warn(
					'The property `defaultSearch` should be set on the `<Geocoder>` component, if being used, instead of `<ReactMap>`',
				);
			} else {
				map?.current?.setSearch?.(defaultSearch);
			}
		}
	}, [defaultSearch, geocoderId]);

	useEffect(() => {
		map?.current?.setSearchElement?.(searchRef.current);
	}, [searchRef]);

	useEffect(() => {
		map?.current?.setPanelElement?.(panelRef.current);
	}, [panelRef]);

	useEffect(() => {
		map?.current?.setPreviewElement?.(previewRef.current);
	}, [previewRef]);

	useEffect(() => {
		map?.current?.setMuiTheme?.(theme);
	}, [theme]);

	useEffect(() => {
		debug.setHiddenLayers?.(hiddenLayers ?? []);
		debug.setBackground?.(
			BackgroundRegistry.getStyleWithFallback(
				background ?? BackgroundTypes.Streets,
				arcGISBasemapStylesToken ?? null,
			),
		);
		if (map?.current?.mapPosition) {
			debug.setPosition?.(map?.current?.mapPosition);
		}
		if (map?.current?.bounds) {
			debug.setBounds?.(map?.current?.bounds);
		}
		if (map?.current?.layerDebug) {
			debug.setLayersDebug?.(map?.current?.layerDebug);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [arcGISBasemapStylesToken, background, hiddenLayers]);

	const handleMessagesElementRefCallback = useCallback(element => {
		setIsMessagesRefSet(Boolean(element));
	}, []);

	const handleMapRefCallback = useCallback(element => {
		map.current = element;
		setIsMapRefSet(Boolean(element));
	}, []);

	useEffect(() => {
		if (isMapRefSet && isMessagesRefSet) {
			map.current?.setMessageElement?.(messagesRef.current);
		}
	}, [isMapRefSet, isMessagesRefSet]);

	return (
		<SnackbarProvider
			domRoot={containerRef.current || undefined}
			maxSnack={1}
			anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
			Components={{
				success: MessagesSnackbarContent,
				error: MessagesSnackbarContent,
				warning: MessagesSnackbarContent,
				info: MessagesSnackbarContent,
				default: MessagesSnackbarContent,
			}}
			SnackbarProps={{ 'data-cy': 'snackbar' }}>
			<MapboxProvider
				map={map?.current?.map}
				setMapState={map?.current?.setMapState}>
				<Wrapper
					className={cx(printPreview && 'print-preview')}
					onMouseLeave={mouseOutsideMap}
					ref={containerRef}>
					<Messages
						ref={messagesRef}
						onRefCallback={handleMessagesElementRefCallback}
					/>
					<PreviewContainer ref={previewRef} />
					<ReactResizeDetector
						handleWidth
						handleHeight
						onResize={(width, height): void =>
							setSize({
								width: width ?? 0,
								height: height ?? 0,
							})
						}></ReactResizeDetector>
					<SearchContainer ref={searchRef} />
					<inno-map
						mapKey={mapKey}
						arcGISBasemapStylesToken={arcGISBasemapStylesToken}
						basemaporigin={basemapOrigin}
						ref={handleMapRefCallback}
						background={background}
						mode={mode}
						loglevel={mapLogLevel}
						panel={!!summaryPanel}
						panelOpen={layerPanelInitiallyOpen}
						geocoderid={geocoderId}
						distanceunit={distanceUnit}
						animationControlId={animationControlId}
						hoverPopup={!!hoverPopup}
						hoverPopupDebounce={hoverPopupDebounce}
						showMinZoomWarning={
							printPreview ? false : showMinZoomWarning
						}
						themeEditorMode={editorMode}
						colorByMaxValues={colorByMaxValues}
						pitchControl={pitchControl}
						enableColorByAttributeRange={
							enableColorByAttributeRange
						}
						enableColorByRangeHeatmap={enableColorByRangeHeatmap}
						restrictSearchToBounds={restrictSearchToBounds}
						showMultiThemes={showMultiThemes}
						localStoragePrefix={localStoragePrefix}
						unitSystem={unitSystem}
						gridControl={!!assetGrid}
						printpreview={printPreview}
						defaultthemeid={defaultThemeId}
						searchRadius={searchRadius}
						bearerToken={bearerToken}
						zoomToFocusedAssets={zoomToFocusedAssets}></inno-map>
					<ControlsContainer
						layerPanelOpen={layerPanelOpen}
						layerPanelEditorOpen={layerPanelEditorOpen}>
						{polygonControl && showGridPanel && (
							<PolygonControl
								onDrawingComplete={onPolygonComplete}
							/>
						)}
					</ControlsContainer>
					{summaryPanel &&
						React.cloneElement(summaryPanel, {
							items,
							deselectAssets,
							highlightAssets,
							zoomToAssets,
							traceFromAsset,
						})}
					{assetGrid && (
						<AssetGridWrapper show={showGridPanel}>
							{assetGrid}
						</AssetGridWrapper>
					)}
					{functionalityEnabled(
						MapFunction.backgroundControl,
						displayMode,
					) && <div ref={panelRef} />}
					{hoverPopup &&
						React.cloneElement(hoverPopup, {
							...popupProps,
							cancelPopupUpdate,
							containerSize: size,
						})}
					<Popup
						{...geocoderPopupProps}
						anchor={Anchor.bottom}
						containerSize={size}
						dataCy="geocode-popup-wrapper"
						contentDataCy="geocode-popup-content">
						{(features: GeocoderResult[]) => {
							const feature = features[0];
							const lngLat = feature.lngLat?.toArray();
							const latLngToFixed = lngLatToFixed(
								feature.lngLat,
								true,
							);
							return (
								<AddressInfo>
									<CloseButton onClick={closeGeocodePopup}>
										<CloseIcon />
									</CloseButton>
									{feature.place_name && (
										<PlaceName data-cy="geocode-popup-place-name">
											<a
												href="#"
												onClick={setSearch(
													feature.place_name,
												)}
												onMouseEnter={previewSearch(
													'coordinates' in
														feature.geometry &&
														(feature.geometry
															.coordinates as number[]),
												)}
												onMouseOut={clearPreviewSearch}>
												{feature.place_name}
											</a>
										</PlaceName>
									)}
									<LongLatLocation data-cy="geocode-popup-lng-lat">
										<a
											href="#"
											onClick={setSearch(
												latLngToFixed?.join(',') ?? '',
											)}
											onMouseEnter={previewSearch(lngLat)}
											onMouseOut={clearPreviewSearch}>
											{latLngToFixed?.join(', ')}
										</a>
									</LongLatLocation>
								</AddressInfo>
							);
						}}
					</Popup>
				</Wrapper>
			</MapboxProvider>
		</SnackbarProvider>
	);
};
export default ReactMap;
