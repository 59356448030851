import {
	SimulationProperties,
	SimulationPropertiesProps,
} from '@Components/SimulationProperties';
import { FeatureItem, SimulationItem } from '@Utils/types';
import React from 'react';

interface SimulationsTabProps extends Omit<SimulationPropertiesProps, 'item'> {
	/** array of feature item properties */
	items: SimulationItem[];
	/** function for "view chart" button, button is only shown if function passed through */
	viewChart?: SimulationPropertiesProps['viewChart'];
}

const featureItemToSimulationItem = (item: FeatureItem): SimulationItem => ({
	...item,
	id: item.id,
	objectId: item.objectId ?? '',
	display_name: item.display_name ?? '',
	type: item.type ?? '',
	startDateTime: item.startDateTime ?? '',
	endDateTime: item.endDateTime ?? '',
	simulations: item.simulations ?? [],
});

export const SimulationsTab = ({
	items,
	viewChart,
}: SimulationsTabProps): JSX.Element => {
	return (
		<div data-cy="tab-item-list">
			{items.map(item => (
				<SimulationProperties
					key={item.id}
					item={featureItemToSimulationItem(item)}
					viewChart={viewChart}
				/>
			))}
		</div>
	);
};
