import { NamespacedStoreState, StoreState } from '@Types/store.types';

import { moduleNamespace } from '../store.config';
import { DefaultRootState } from 'react-redux';

export const getValue = (state: DefaultRootState): StoreState =>
	state[moduleNamespace];

export const passInId = (
	state: NamespacedStoreState,
	selectedItem: string | null,
): string | null => selectedItem;

export const passInSystemType = (
	state: NamespacedStoreState,
	systemType: string | null,
): string | null => systemType;

export const passInLayerName = (
	_: NamespacedStoreState,
	layerName: string,
): string | null => layerName;
