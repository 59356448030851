// NOTE: If you are looking for the action for requesting the databases, this is
// in domainServices.actions.ts because it is a common utility between all simulation types

import { createAction } from '@reduxjs/toolkit';

import {
	DSTable,
	DSTableMetadata,
	DSTreeObject,
} from '../types/domainServices.types';

/**
 * An action to request the analysis groups for the given database
 */
export const requestInfoWaterProAnalysisGroups = createAction<{ dbid: string }>(
	'stylovyze/infoWaterPro/requestAnalysisGroups',
);

export const requestInfoWaterProAnalysisGroupsResolved = createAction<{
	dbid: string;
	data: DSTreeObject[];
}>('stylovyze/infoWaterPro/requestAnalysisGroups/resolved');

export const requestInfoWaterProAnalysisGroupsRejected = createAction<{
	dbid: string;
	error: string;
}>('stylovyze/infoWaterPro/requestAnalysisGroups/rejected');

/**
 * An action to set the state to loading.  This should only be used for testing.
 * It behaves like requestInfoWaterProAnalysisGroups, but does not trigger the saga.
 */
export const testOnlyInfoWaterProAnalysisGroupsLoading = createAction<{
	dbid: string;
}>('stylovyze/infoWaterPro/requestAnalysisGroups/testOnlyLoading');

/**
 * An action to request the simulations for the given analysis group
 */
export const requestInfoWaterProSimulations = createAction<{
	dbid: string;
	analysisGroupId: string;
}>('stylovyze/infoWaterPro/requestSimulations');

export const requestInfoWaterProSimulationsResolved = createAction<{
	dbid: string;
	analysisGroupId: string;
	data: DSTreeObject[];
}>('stylovyze/infoWaterPro/requestSimulations/resolved');

export const requestInfoWaterProSimulationsRejected = createAction<{
	dbid: string;
	analysisGroupId: string;
	error: string;
}>('stylovyze/infoWaterPro/requestSimulations/rejected');

/**
 * An action to set the state to loading.  This should only be used for testing.
 * It behaves like requestInfoWaterProSimulations, but does not trigger the saga.
 */
export const testOnlyInfoWaterProSimulationsLoading = createAction<{
	dbid: string;
	analysisGroupId: string;
}>('stylovyze/infoWaterPro/requestSimulations/testOnlyLoading');

/**
 * An action to request the tables for the given simulation
 * The table request provides the model element types as well as the output types
 */
export const requestInfoWaterProTables = createAction<{
	dbid: string;
	simulationId: string;
}>('stylovyze/infoWaterPro/requestTables');

export const requestInfoWaterProTablesResolved = createAction<{
	dbid: string;
	simulationId: string;
	data: DSTable[];
}>('stylovyze/infoWaterPro/requestTables/resolved');

export const requestInfoWaterProTablesRejected = createAction<{
	dbid: string;
	simulationId: string;
	error: string;
}>('stylovyze/infoWaterPro/requestTables/rejected');

/**
 * An action to set the state to loading.  This should only be used for testing.
 * It behaves like requestInfoWaterProTables, but does not trigger the saga.
 */
export const testOnlyInfoWaterProTablesLoading = createAction<{
	dbid: string;
	simulationId: string;
}>('stylovyze/infoWaterPro/requestTables/testOnlyLoading');

/**
 * An action to request the metadata for the given table
 * The metadata provides the object Ids as well as the timesteps
 */
export const requestInfoWaterProMetadata = createAction<{
	dbid: string;
	simulationId: string;
	tableId: string;
}>('stylovyze/infoWaterPro/requestMetadata');

export const requestInfoWaterProMetadataResolved = createAction<{
	dbid: string;
	simulationId: string;
	tableId: string;
	data: DSTableMetadata;
}>('stylovyze/infoWaterPro/requestMetadata/resolved');

export const requestInfoWaterProMetadataRejected = createAction<{
	dbid: string;
	simulationId: string;
	tableId: string;
	error: string;
}>('stylovyze/infoWaterPro/requestMetadata/rejected');

/**
 * An action to set the state to loading.  This should only be used for testing.
 * It behaves like requestInfoWaterProMetadata, but does not trigger the saga.
 */
export const testOnlyInfoWaterProMetadataLoading = createAction<{
	dbid: string;
	simulationId: string;
	tableId: string;
}>('stylovyze/infoWaterPro/requestMetadata/testOnlyLoading');
