import { axiosWithHeaders, getService } from '@innovyze/lib_get_service';
import { formatUrlTrailing } from '@Utils/urlFormatting';
import config, { apiEnv } from './config.api';

const rehabService = axiosWithHeaders({
	service: 'amRehab',
	config,
	axiosCreateOpts: { timeout: 30000 },
});

export const rehabBaseUrl = formatUrlTrailing(
	getService('amRehab', config, apiEnv),
);

export default rehabService;
