import axiosRetry from 'axios-retry';
import { axiosWithHeaders, getService } from '@innovyze/lib_get_service';
import config from './config.api';
import { getApiEnvironment } from '@innovyze/stylovyze';
import { formatUrlTrailing } from '@Utils/urlFormatting';

const assetsUrl = formatUrlTrailing(
	getService('assets', config, getApiEnvironment()),
);

const service = axiosWithHeaders({
	service: 'assets',
	config,
	axiosCreateOpts: {
		baseURL: assetsUrl,
		timeout: 10000,
	},
});

axiosRetry(service, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
});

export default service;
