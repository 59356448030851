import { useDispatch, useSelector } from 'react-redux';
import { SharedStoreState } from '../../types';
import { DataContainer, STATUS_ENUM } from '../../types/reducers.types';
import { Option } from '../../components/SimulationDataFinderDialog/DataFinder';
import { requestInfoWaterProTables } from '../../actions/infoWaterPro.actions';
import { infoWaterProTablesSelector } from './table.selectors';
import { useEffect } from 'react';

/** provide all the output types available in the given dbid, simulation and model element type as an array of Options */
export const infoWaterProOutputTypesSelector = (
	state: SharedStoreState,
	dbid: string,
	simulationId: string,
	modelElementTypeId: string,
): DataContainer<Option[]> => {
	const tables = infoWaterProTablesSelector(state, dbid, simulationId);
	const table = tables.data?.find(({ id }) => id === modelElementTypeId);
	const options = table?.attributes.map((attribute, index) => ({
		id: attribute,
		label: table.names[index] ?? attribute,
	}));
	return { ...tables, data: options ?? [] };
};

/** provide all the output types available in the given dbid, simulation and model element type as an array of Options */
export const useSelectInfoWaterProOutputTypes = (
	dbid: string,
	simulationId: string,
	modelElementTypeId: string,
): DataContainer<Option[]> => {
	const selector = useSelector((state: SharedStoreState) =>
		infoWaterProOutputTypesSelector(
			state,
			dbid,
			simulationId,
			modelElementTypeId,
		),
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(requestInfoWaterProTables({ dbid, simulationId }));
	}, [dbid, simulationId]);
	return selector;
};

/** provide a single output type label by id (attribute) */
export const infoWaterProOutputTypeLabelSelector = (
	state: SharedStoreState,
	dbid: string,
	simulationId: string,
	modelElementTypeId: string,
	attribute: string,
): DataContainer<string> => {
	const tableOptions = infoWaterProOutputTypesSelector(
		state,
		dbid,
		simulationId,
		modelElementTypeId,
	);
	const table = tableOptions?.data?.find(({ id }) => id === attribute);
	return { ...tableOptions, data: table?.label ?? '' };
};

/** provide a single output type label by id (attribute) */
export const useSelectInfoWaterProOutputTypeLabel = (
	dbid: string,
	simulationId: string,
	modelElementTypeId: string,
	attribute: string,
): DataContainer<string> => {
	const selector = useSelector((state: SharedStoreState) =>
		infoWaterProOutputTypeLabelSelector(
			state,
			dbid,
			simulationId,
			modelElementTypeId,
			attribute,
		),
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(requestInfoWaterProTables({ dbid, simulationId }));
	}, [dbid, simulationId]);
	return selector;
};

/** provide the measurement units for a single output type by id (attribute) */
export const infoWaterProOutputTypeUnitsSelector = (
	state: SharedStoreState,
	dbid: string,
	simulationId: string,
	modelElementTypeId: string,
	attribute: string,
): DataContainer<string> => {
	const tables = infoWaterProTablesSelector(state, dbid, simulationId);
	if (tables.status !== 'success') {
		return { ...tables, data: '' };
	}
	const table = tables.data.find(({ id }) => id === modelElementTypeId);
	if (!table) {
		return { initialized: true, status: STATUS_ENUM.ERROR, data: '' };
	}
	const index = table.attributes.indexOf(attribute);
	if (index === -1) {
		return { initialized: true, status: STATUS_ENUM.ERROR, data: '' };
	}
	return { ...tables, data: table.units[index] };
};

/** provide the measurement units for a single output type by id (attribute) */
export const useSelectInfoWaterProOutputTypeUnits = (
	dbid: string,
	simulationId: string,
	modelElementTypeId: string,
	attribute: string,
): DataContainer<string> => {
	const selector = useSelector((state: SharedStoreState) =>
		infoWaterProOutputTypeUnitsSelector(
			state,
			dbid,
			simulationId,
			modelElementTypeId,
			attribute,
		),
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(requestInfoWaterProTables({ dbid, simulationId }));
	}, [dbid, simulationId]);
	return selector;
};
