import { axiosWithHeaders } from '@innovyze/lib_get_service';
import axiosRetry from 'axios-retry';

const measureData = axiosWithHeaders({
  service: 'sensorData',
  config: {},
  axiosCreateOpts: {
    withCredentials: true,
    timeout: 10000,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  },
});

axiosRetry(measureData, {
  retries: 5,
  shouldResetTimeout: true,
  retryDelay: (retryCount) => {
    return retryCount * 500;
  },
  retryCondition: (error) => {
    const is404 = error.response?.status === 404;
    return is404 ? false : true;
  },
});

export default measureData;
