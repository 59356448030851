import { useDispatch } from 'react-redux';

import {
	DSDatabase,
	DSObjectType,
	DSTable,
	DSTableMetadata,
	DSTreeObject,
} from '../types';
import {
	requestDomainServicesDatabasesRejected,
	requestDomainServicesDatabasesResolved,
	testOnlyDomainServicesDatabasesLoading,
	requestInfoWaterProAnalysisGroupsRejected,
	requestInfoWaterProAnalysisGroupsResolved,
	requestInfoWaterProMetadataRejected,
	requestInfoWaterProMetadataResolved,
	requestInfoWaterProSimulationsRejected,
	requestInfoWaterProSimulationsResolved,
	requestInfoWaterProTablesRejected,
	requestInfoWaterProTablesResolved,
	testOnlyInfoWaterProAnalysisGroupsLoading,
	testOnlyInfoWaterProSimulationsLoading,
	testOnlyInfoWaterProTablesLoading,
	testOnlyInfoWaterProMetadataLoading,
} from '../actions';

export enum DATA_STATUS {
	PENDING = 'pending',
	SUCCESS = 'success',
	ERROR = 'error',
	EMPTY = 'empty',
}

/**
 * Control the dummy data for the InfoWater Pro database
 * This allows the component to be tested without a backend
 *
 * @returns a set of controls that can load dummy data and simultate various conditions
 */
export const useInfoWaterProDummyDataControls = () => {
	const dispatch = useDispatch();

	const infoWaterProDatabase: DSDatabase = {
		dbid: 'THEINFOWATERPRODBID',
		dbname: 'InfoWater Pro Database',
	};

	const analysisGroups: DSTreeObject[] = [
		{
			oid: 10,
			otype: DSObjectType.ModelGroup,
			parentoid: 0,
			oname: 'Madrid',
		},
		{
			oid: 20,
			otype: DSObjectType.ModelGroup,
			parentoid: 0,
			oname: 'Tokyo',
		},
	];

	const simulations: DSTreeObject[][] = [
		// simulations for Madrid
		[
			{
				oid: 11,
				otype: DSObjectType.InfoWaterSimulation,
				parentoid: 10,
				oname: 'The Rain in Spain',
			},
			{
				oid: 12,
				otype: DSObjectType.InfoWaterSimulation,
				parentoid: 10,
				oname: 'La lluvia en España',
			},
		],
		// simulations for Tokyo
		[
			{
				oid: 21,
				otype: DSObjectType.InfoWaterSimulation,
				parentoid: 20,
				oname: 'A rainy Day in Tokyo',
			},
			{
				oid: 22,
				otype: DSObjectType.InfoWaterSimulation,
				parentoid: 20,
				oname: '東京の雨の日',
			},
		],
	];

	// define 4 types of tables and just use those for all the simulations
	const table1: DSTable = {
		attributes: ['outflow', 'head', 'pressure', 'emitter'],
		className: 'Junction',
		id: 'iwp_hydqua_result_junction',
		names: ['Demand', 'Head', 'Pressure', 'Emitter'],
		units: ['cfs', 'ft', 'ft', 'cfs'],
	};
	const table2: DSTable = {
		attributes: [
			'flow',
			'velocity',
			'headloss',
			'hl1000',
			'status',
			'friction',
			'forward_flow',
			'reverse_flow',
			'net_flow',
			'flow_rev_count',
		],
		className: 'Pipe',
		id: 'iwp_hydqua_result_pipe',
		names: [
			'Flow',
			'Velocity',
			'Headloss',
			'HL/1000',
			'Status',
			'Friction',
			'Forward Flow Volume',
			'Reverse Flow Volume',
			'Net Flow Volume',
			'Flow Reversal',
		],
		units: [
			'cfs',
			'ft/s',
			'ft',
			'ft/k-ft',
			'',
			'',
			'ft3',
			'ft3',
			'ft3',
			'',
		],
	};
	const table3: DSTable = {
		attributes: ['flow', 'head'],
		className: 'Reservoir',
		id: 'iwp_hydqua_result_reservoir',
		names: ['Flow', 'Head'],
		units: ['cfs', 'ft'],
	};
	const table4: DSTable = {
		attributes: [
			'flow',
			'head',
			'percent_volume',
			'storage_volume',
			'level',
			'step_overflow',
			'total_overflow',
		],
		className: 'Tank',
		id: 'iwp_hydqua_result_tank',
		names: [
			'Flow',
			'Head',
			'% Volume',
			'Storage Volume',
			'Level',
			'Overflow Volume',
			'Total Overflow Volume',
		],
		units: ['cfs', 'ft', '%', 'ft3', 'ft', 'ft3', 'ft3'],
	};

	const tables: DSTable[][][] = [
		[
			// tables for Madrid, The Rain in Spain
			[table1, table2],
			// tables for Madrid, La lluvia en España
			[table2, table3, table4],
		],
		[
			// tables for Tokyo, A rainy Day in Tokyo
			[table2, table4],
			// tables for Tokyo, 東京の雨の日
			[table1, table2, table3, table4],
		],
	];

	// just a couple of lists of simulation metadata
	const metadata1: DSTableMetadata = {
		attributes: ['time', 'flow', 'head', 'pressure', 'emitter'],
		ids: [
			'1001',
			'1002',
			'1003',
			'1004',
			'1005',
			'1006',
			'1007',
			'1008',
			'1009',
			'1010',
			'1011',
			'1012',
			'1013',
			'1014',
			'1015',
			'1016',
			'1017',
			'1018',
			'1019',
			'1020',
		],
		timesteps: [
			'2024-01-01T00:00:00Z',
			'2024-01-01T00:15:00Z',
			'2024-01-01T00:30:00Z',
			'2024-01-01T00:45:00Z',
		],
	};
	const metadata2: DSTableMetadata = {
		attributes: [
			'time',
			'flow',
			'velocity',
			'headloss',
			'hl1000',
			'status',
			'friction',
			'forward_flow',
			'reverse_flow',
			'net_flow',
			'flow_rev_count',
		],
		ids: ['A100', 'B100', 'A101', 'B101', 'B110'],
		timesteps: [
			'2024-01-01T00:00:00Z',
			'2024-01-01T00:15:00Z',
			'2024-01-01T00:30:00Z',
			'2024-01-01T00:45:00Z',
			'2024-01-01T01:00:00Z',
			'2024-01-01T01:15:00Z',
			'2024-01-01T01:30:00Z',
			'2024-01-01T01:45:00Z',
		],
	};

	const metadata: DSTableMetadata[][][] = [
		[
			// metadata for Madrid, The Rain in Spain
			[metadata1, metadata2],
			// metadata for Madrid, La lluvia en España
			[metadata2, metadata1, metadata1],
		],
		[
			// metadata for Tokyo, A rainy Day in Tokyo
			[metadata2, metadata1],
			// metadata for Tokyo, 東京の雨の日
			[metadata1, metadata2, metadata1, metadata1],
		],
	];

	/**
	 * load the redux store with a single dummy InfoWater Pro database
	 */
	const loadDatabase = () => {
		dispatch(
			requestDomainServicesDatabasesResolved([infoWaterProDatabase]),
		);
	};

	/**
	 * load the redux store with the dummy analysis groups
	 */
	const loadAnalysisGroups = () => {
		dispatch(
			requestInfoWaterProAnalysisGroupsResolved({
				dbid: infoWaterProDatabase.dbid,
				data: analysisGroups,
			}),
		);
	};

	/**
	 * load the redux store with the dummy simulations
	 */
	const loadSimulations = () => {
		for (let i = 0; i < analysisGroups.length; i++) {
			dispatch(
				requestInfoWaterProSimulationsResolved({
					dbid: infoWaterProDatabase.dbid,
					analysisGroupId: String(analysisGroups[i].oid),
					data: simulations[i],
				}),
			);
		}
	};

	/**
	 * load the redux store with the dummy tables
	 */
	const loadTables = () => {
		for (let i = 0; i < analysisGroups.length; i++) {
			for (let j = 0; j < simulations[i].length; j++) {
				dispatch(
					requestInfoWaterProTablesResolved({
						dbid: infoWaterProDatabase.dbid,
						simulationId: String(simulations[i][j].oid),
						data: tables[i][j],
					}),
				);
			}
		}
	};

	/**
	 * load the redux store with the dummy metadata
	 */
	const loadMetadata = () => {
		for (let i = 0; i < analysisGroups.length; i++) {
			for (let j = 0; j < simulations[i].length; j++) {
				for (let k = 0; k < tables[i][j].length; k++) {
					dispatch(
						requestInfoWaterProMetadataResolved({
							dbid: infoWaterProDatabase.dbid,
							simulationId: String(simulations[i][j].oid),
							tableId: tables[i][j][k].id,
							data: metadata[i][j][k],
						}),
					);
				}
			}
		}
	};

	/**
	 * convenience method to load all the dummy data
	 */
	const loadAllDummyData = () => {
		loadDatabase();
		loadAnalysisGroups();
		loadSimulations();
		loadTables();
		loadMetadata();
	};

	/**
	 * force the database status to a particular value
	 * @param status the status to force
	 */
	const forceDatabaseStatus = (status: DATA_STATUS) => {
		switch (status) {
			case DATA_STATUS.PENDING:
				dispatch(testOnlyDomainServicesDatabasesLoading());
				break;
			case DATA_STATUS.SUCCESS:
				loadDatabase();
				break;
			case DATA_STATUS.ERROR:
				dispatch(requestDomainServicesDatabasesRejected());
				break;
			case DATA_STATUS.EMPTY:
				dispatch(requestDomainServicesDatabasesResolved([]));
				break;
			default:
				console.error('Unknown status');
		}
	};

	/**
	 * force the analysis groups status to a particular value
	 * @param status the status to force
	 */
	const forceAnalysisGroupsStatus = (status: DATA_STATUS) => {
		switch (status) {
			case DATA_STATUS.PENDING:
				dispatch(
					testOnlyInfoWaterProAnalysisGroupsLoading({
						dbid: infoWaterProDatabase.dbid,
					}),
				);
				break;
			case DATA_STATUS.SUCCESS:
				loadAnalysisGroups();
				break;
			case DATA_STATUS.ERROR:
				dispatch(
					requestInfoWaterProAnalysisGroupsRejected({
						dbid: infoWaterProDatabase.dbid,
						error: 'Error loading analysis groups',
					}),
				);
				break;
			case DATA_STATUS.EMPTY:
				dispatch(
					requestInfoWaterProAnalysisGroupsResolved({
						dbid: infoWaterProDatabase.dbid,
						data: [],
					}),
				);
				break;
			default:
				console.error('Unknown status');
		}
	};

	/**
	 * force the simulations status to a particular value
	 * @param status the status to force
	 */
	const forceSimulationsStatus = (status: DATA_STATUS) => {
		switch (status) {
			case DATA_STATUS.PENDING:
				for (let i = 0; i < analysisGroups.length; i++) {
					dispatch(
						testOnlyInfoWaterProSimulationsLoading({
							dbid: infoWaterProDatabase.dbid,
							analysisGroupId: String(analysisGroups[i].oid),
						}),
					);
				}
				break;
			case DATA_STATUS.SUCCESS:
				loadSimulations();
				break;
			case DATA_STATUS.ERROR:
				for (let i = 0; i < analysisGroups.length; i++) {
					dispatch(
						requestInfoWaterProSimulationsRejected({
							dbid: infoWaterProDatabase.dbid,
							analysisGroupId: String(analysisGroups[i].oid),
							error: 'Error loading simulations',
						}),
					);
				}
				break;
			case DATA_STATUS.EMPTY:
				for (let i = 0; i < analysisGroups.length; i++) {
					dispatch(
						requestInfoWaterProSimulationsResolved({
							dbid: infoWaterProDatabase.dbid,
							analysisGroupId: String(analysisGroups[i].oid),
							data: [],
						}),
					);
				}
				break;
			default:
				console.error('Unknown status');
		}
	};

	/**
	 * force the tables status to a particular value
	 * @param status the status to force
	 */
	const forceTablesStatus = (status: DATA_STATUS) => {
		switch (status) {
			case DATA_STATUS.PENDING:
				for (let i = 0; i < analysisGroups.length; i++) {
					for (let j = 0; j < simulations[i].length; j++) {
						dispatch(
							testOnlyInfoWaterProTablesLoading({
								dbid: infoWaterProDatabase.dbid,
								simulationId: String(simulations[i][j].oid),
							}),
						);
					}
				}
				break;
			case DATA_STATUS.SUCCESS:
				loadTables();
				break;
			case DATA_STATUS.ERROR:
				for (let i = 0; i < analysisGroups.length; i++) {
					for (let j = 0; j < simulations[i].length; j++) {
						dispatch(
							requestInfoWaterProTablesRejected({
								dbid: infoWaterProDatabase.dbid,
								simulationId: String(simulations[i][j].oid),
								error: 'Error loading tables',
							}),
						);
					}
				}
				break;
			case DATA_STATUS.EMPTY:
				for (let i = 0; i < analysisGroups.length; i++) {
					for (let j = 0; j < simulations[i].length; j++) {
						dispatch(
							requestInfoWaterProTablesResolved({
								dbid: infoWaterProDatabase.dbid,
								simulationId: String(simulations[i][j].oid),
								data: [],
							}),
						);
					}
				}
				break;
			default:
				console.error('Unknown status');
		}
	};

	/**
	 * force the metadata status to a particular value
	 * @param status the status to force
	 */
	const forceMetadataStatus = (status: DATA_STATUS) => {
		switch (status) {
			case DATA_STATUS.PENDING:
				for (let i = 0; i < analysisGroups.length; i++) {
					for (let j = 0; j < simulations[i].length; j++) {
						for (let k = 0; k < tables[i][j].length; k++) {
							dispatch(
								testOnlyInfoWaterProMetadataLoading({
									dbid: infoWaterProDatabase.dbid,
									simulationId: String(simulations[i][j].oid),
									tableId: tables[i][j][k].id,
								}),
							);
						}
					}
				}
				break;
			case DATA_STATUS.SUCCESS:
				loadMetadata();
				break;
			case DATA_STATUS.ERROR:
				for (let i = 0; i < analysisGroups.length; i++) {
					for (let j = 0; j < simulations[i].length; j++) {
						for (let k = 0; k < tables[i][j].length; k++) {
							dispatch(
								requestInfoWaterProMetadataRejected({
									dbid: infoWaterProDatabase.dbid,
									simulationId: String(simulations[i][j].oid),
									tableId: tables[i][j][k].id,
									error: 'Error loading metadata',
								}),
							);
						}
					}
				}
				break;
			case DATA_STATUS.EMPTY:
				for (let i = 0; i < analysisGroups.length; i++) {
					for (let j = 0; j < simulations[i].length; j++) {
						for (let k = 0; k < tables[i][j].length; k++) {
							dispatch(
								requestInfoWaterProMetadataResolved({
									dbid: infoWaterProDatabase.dbid,
									simulationId: String(simulations[i][j].oid),
									tableId: tables[i][j][k].id,
									data: {
										attributes: [],
										ids: [],
										timesteps: [],
									},
								}),
							);
						}
					}
				}
				break;
			default:
				console.error('Unknown status');
		}
	};

	return {
		loadAllDummyData,
		loadDatabase,
		loadAnalysisGroups,
		loadSimulations,
		loadTables,
		loadMetadata,
		forceDatabaseStatus,
		forceAnalysisGroupsStatus,
		forceSimulationsStatus,
		forceTablesStatus,
		forceMetadataStatus,
	};
};
