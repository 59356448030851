import workgroupDatastore from '../apis/workgroupDatastore';
import simulationResults from '../apis/simulationResults';
import { AxiosResponse } from 'axios';
import {
	DSDatabase,
	DSTable,
	DSTableMetadata,
	DSTreeObject,
} from '../types/domainServices.types';

/**
 * Retrieve a list of databases available to the caller.
 * See https://wiki.autodesk.com/display/Innovyze/API+walkthrough+for+Info360
 *
 * @returns {Promise<DSDatabase[]>} - A promise that resolves to an array of the databases
 */
export const getDSDatabases = async (): Promise<DSDatabase[]> => {
	const response: AxiosResponse<{ databases: DSDatabase[] }> =
		await workgroupDatastore.get('/v2/databases');
	return response.data.databases;
};

/**
 * A Domain Services database has a tree structure with several different nodes (root nodes) as children of the root object ('0').
 * This method Retrieves the child objects of a parent object in a database.
 *
 * See https://wiki.autodesk.com/display/Innovyze/API+walkthrough+for+Info360
 *
 * For InfoWaterPro, the root objects are the model groups (a.k.a analysis groups)
 * and the children of the model groups are the simulations.
 *
 * For other simulation types, there is a more involved hierarchy that must be navigated to find the simulation objects.
 *
 * @param dbid - the database to query
 * @param oid - the parent object id. passing '0' will return the root objects
 * @returns - A list of tree objects that are children of the parent object
 */
export const getDSChildren = async (
	dbid: string,
	oid: string,
): Promise<DSTreeObject[]> => {
	if (!dbid || !oid) {
		return [];
	}
	const response = await workgroupDatastore.get(
		`/v2/databases/${dbid}/treeobjects/${oid}/children`,
	);
	return response.data.treeobjects;
};

/**
 * Retrieve a list of the tables for a given result set (resultid).
 * see `DSTable` for a description of what is provided in a table.
 *
 * Also see https://wiki.autodesk.com/display/Innovyze/API+walkthrough+for+Info360
 * For InfoWaterPro, a resultid is simply the oid of the simulation object.
 *
 * For other simulation types, the resultid is provided in the leaf node when navigating the tree.
 *
 * @param dbid - the database to query
 * @param resultid - the result set
 * @returns a list of tables for the given result set
 */
export const getDSTables = async (
	dbid: string,
	resultid: string,
): Promise<DSTable[]> => {
	if (!dbid || !resultid) {
		return [];
	}
	const response = await simulationResults.get(
		`/v2/web-results/modeldbs/${dbid}/resultsets/${resultid}/tables`,
	);
	return response.data;
};

export const getDSTableMetadata = async (
	dbid: string,
	resultid: string,
	tableid: string,
): Promise<DSTableMetadata> => {
	if (!dbid || !resultid || !tableid) {
		return {
			attributes: [],
			ids: [],
			timesteps: [],
		};
	}
	const response = await simulationResults.get(
		`/v2/web-results/modeldbs/${dbid}/resultsets/${resultid}/table/${tableid}/metadata`,
	);
	return response.data;
};
