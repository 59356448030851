import { Menu } from '@mui/material';
import React from 'react';
import { useConsumeWeaveTheme } from '../../../theme/WeaveContext';

interface StyledMenuProps {
	readonly menuOpen: boolean;
	readonly menuAnchorRef: React.RefObject<HTMLDivElement>;
	readonly closeMenu: () => void;
	readonly children: JSX.Element[] | JSX.Element | string;
}

export default function OrgSwitcherMenu({
	menuOpen,
	menuAnchorRef,
	closeMenu,
	children,
}: StyledMenuProps): JSX.Element {
	const { resolvedRoles, isWeaveTheme } = useConsumeWeaveTheme();
	const textColor =
		isWeaveTheme && resolvedRoles['colorScheme.text.default']
			? resolvedRoles['colorScheme.text.default']
			: 'white';
	const backgroundColor =
		isWeaveTheme && resolvedRoles['menu.backgroundColor']
			? resolvedRoles['menu.backgroundColor']
			: '#43575d';
	return (
		<Menu
			sx={{ zIndex: 9999 }}
			MenuListProps={{
				style: {
					borderRadius: 0,
					margin: 0,
					backgroundColor,
					color: textColor,
				},
			}}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			PaperProps={{ style: { borderRadius: 0, margin: 0, top: 30 } }}
			anchorEl={menuOpen ? menuAnchorRef.current : null}
			open={menuOpen}
			onClose={closeMenu}
			id={'alt-orgs-menu'}
			keepMounted>
			{children}
		</Menu>
	);
}
