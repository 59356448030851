import {
	AssetType,
	AssetTypeManhole,
	AssetTypePipe,
	AssetTypeUnknown,
	GeneralOptions,
	InspectionStandard,
	SourceOptions,
} from '@Types';
import { inspectionBaseUrl, tilesBaseUrl } from '@Apis';
import { isManholeStandard, isPipeStandard } from '@Utils';

import { Extent } from '@innovyze/shared-utils';
import { SourceType } from '@innovyze/inno-map';
import { mapRiskLayersAddress } from '@Services/index';
import { rehabBaseUrl } from '@Apis/rehab.api';
import { riskBaseUrl } from '@Apis';
import { riskGradePalette } from '@innovyze/stylovyze';

export const dummyToken = 'dummy-token';

export const propertyLookupRiskScore = (hiddenRanges = ''): string => {
	return `${riskBaseUrl}${mapRiskLayersAddress('grades', hiddenRanges)}`;
};

// 2do combine with above
export const propertyLookupRiskScoreGroup = (groupIndex = 0): string =>
	`${riskBaseUrl}risk/score?mapTheme=group&sort=alpha&mapThemeGroup=${groupIndex}`;

export const propertyLookupRiskScoreVersion = (versionId: string): string =>
	`${riskBaseUrl}risk/score?mapTheme=version&mapThemeVersion=${versionId}`;

export const propertyLookupRehabResults = (
	treeId: string,
	lastRun: string,
	assetType: string,
): string =>
	`${rehabBaseUrl}rehabResults/${treeId}?mapTheme=lookup&lastRun=${lastRun}&assetType=
	${assetType}`;

// There is a performance cost to showing geojson surveys, so dont show at too low a zoom
export const zoomShowInspections = 17;
export const zoomShowPipeSurveys = 18;
export const zoomShowManholeSurveys = 17;

// zoomSelection's need to be quite aggressive as used in MapInsert's with limited size
// obviously greater than corresponding zoomShow's
export const zoomSelectionDefault = 17;
export const zoomSelectionPoint = 19;

export const minPositionSearchRadius = 10; // m

// 1e-2 ~ 1km, 1e-3 ~ 111m, 1e-4 ~ 11m, 1e-5 ~ 1m
export const minPositionExtent = 1e-4;

export const tileOptions: SourceOptions = {
	dataServiceId: 'tiles',
	layerName: 'System Type',
	type: SourceType.INFO360_TILES_V2,
	url: tilesBaseUrl,
};

export const otherOptions: SourceOptions = {
	dataServiceId: 'tiles-other',
	layerName: 'Spatial Data',
	type: SourceType.TILES,
	url: `${tilesBaseUrl}/v1/tile-json/amSpatial`,
};

export const inspectionCctv = 'cctv';
export const inspectionCctvObs = 'cctvObs';
export const inspectionManhole = 'manholeSurveys';

export const inspectionsLayerName = 'Inspections';

export const cctv: SourceOptions = {
	dataServiceId: inspectionCctv,
	layerName: 'CCTV',
	type: SourceType.ONU,
	url: `${inspectionBaseUrl}map/inspections`, // 2do should have cctv in name
	minZoom: zoomShowInspections,
};

export const cctvObservations: SourceOptions = {
	dataServiceId: inspectionCctvObs,
	layerName: 'Observations',
	type: SourceType.ONU,
	url: `${inspectionBaseUrl}map/observations`, // 2do should have cctv in name
	minZoom: zoomShowPipeSurveys,
};

export const manholeSurveys: SourceOptions = {
	dataServiceId: inspectionManhole,
	layerName: 'Manhole surveys',
	type: SourceType.ONU,
	url: `${inspectionBaseUrl}map/manhole-surveys`,
	minZoom: zoomShowManholeSurveys,
};

export const defaultInspectionSources = [
	cctv,
	cctvObservations,
	manholeSurveys,
];

export const generalOptionsDefault: GeneralOptions = {
	radiusDefault: 5000, // m
	boundMargin: 1.02, // %
	useBBox: true,
	singlePage: true,
	precisionLonLat: 6,
	precisionRadius: 0,
	noMinZoom: false,
};

// Provided by Sasha/UX directly for inspection ratings
export const ratingColours = [
	'#43575D',
	'#00A3FF',
	'#84E206',
	'#FFBE00',
	'#FF7A00',
	'#ED0000',
];

// These choices are as currently hard coded in app_am_risk, need moving to lib_am_common
// Also colours might need changing wrt map background?
export const riskGradeColours = [
	riskGradePalette.negligible.dark,
	riskGradePalette.low.dark,
	riskGradePalette.medium.main,
	riskGradePalette.high.main,
	riskGradePalette.extreme.dark,
];

export const riskGradeLayerNames = [
	'Negligible',
	'Low',
	'Medium',
	'High',
	'Extreme',
];

// Used in app_am_inspections @AssetSchemaMigration
export const assetType = (standard: InspectionStandard): AssetType => {
	if (isPipeStandard(standard)) {
		return AssetTypePipe;
	} else if (isManholeStandard(standard)) {
		return AssetTypeManhole;
	}
	return AssetTypeUnknown;
};

export const inspectionSources = (
	standard: InspectionStandard,
): SourceOptions[] => {
	if (isPipeStandard(standard)) {
		return [cctv, cctvObservations];
	}
	if (isManholeStandard(standard)) {
		return [manholeSurveys];
	}
	return [];
};

// 2do move to shared_utils
export const toExtent = (arr: number[] | undefined): Extent | undefined =>
	arr?.length === 4 ? [arr[0], arr[1], arr[2], arr[3]] : undefined;

export const rehabColors = [
	'#e41a1c',
	'#377eb8',
	'#4daf4a',
	'#984ea3',
	'#ff7f00',
	'#ffff33',
	'#a65628',
	'#f781bf',
	'#999999',
];
