import { Property, PropertyCard } from '@innovyze/stylovyze';
import { Divider } from '@mui/material';
import { useGlobalization } from '@Translations/useGlobalization';
import { SimulationItem } from '@Utils/types';
import {
	SimulationName,
	ViewChart,
	Wrapper,
} from './SimulationProperties.style';
import React from 'react';

export interface SimulationPropertiesProps {
	/** feature item properties */
	item: SimulationItem;
	/** function for "view chart" button, button is only shown if function passed through */
	viewChart?: (id: string) => void;
}

export const SimulationProperties = ({
	item,
	viewChart,
}: SimulationPropertiesProps): JSX.Element => {
	const { t } = useGlobalization();

	const getSimulationRange = (): string => {
		if (!item.startDateTime) {
			return '-';
		}
		let simulationRange = item.startDateTime;
		if (item.endDateTime) {
			simulationRange += ` - ${item.endDateTime}`;
		}
		return simulationRange;
	};

	const panelProperties: Property[] = [
		{
			title: t('Object ID'),
			value: item.objectId,
			ellipsis: true,
			cy: 'objectId',
			span: 2,
		},
		{
			title: t('Object Type'),
			value: `${t(item.type ?? '-')}`,
			ellipsis: true,
			cy: 'objectType',
			span: 2,
		},
		{
			title: t('Simulation Range'),
			value: getSimulationRange(),
			cy: 'simulationRange',
			span: 7,
		},
	];

	const handelSimulationClick = (id: string) => () => {
		viewChart?.(id);
	};

	return (
		<PropertyCard
			title={item.display_name}
			properties={panelProperties}
			columns={12}
			marginBottom
			additionalContent={
				item.simulations?.length ? (
					<>
						<Divider />
						{item.simulations.map(simulation => {
							return (
								<Wrapper
									data-cy="inspection-properties"
									key={simulation.id}>
									<SimulationName>
										{simulation.name}
									</SimulationName>
									<ViewChart
										size="small"
										onClick={handelSimulationClick(
											simulation.id,
										)}>
										{t('View Chart')}
									</ViewChart>
								</Wrapper>
							);
						})}
					</>
				) : (
					undefined
				)
			}
		/>
	);
};
