import { AssetSchema, AssetSchemaRaw } from '../types/asset.types';
import { Capability, InspectionTypes } from '../types/capabilities.types';
import {
	fromRawSystem,
	getSystemTypeByAssetTypeRaw,
	getSystemTypeRaw,
	getSystemTypesRaw,
} from '../system';

import { AssetSchemaImpl } from './asset.class';
import { SystemTypeRaw } from '../types/system.types';
import { loadAssetFile } from './file';

type AssetSchemaFilter =
	| { capability: Capability }
	| { systemTypeId: string }
	| { inspectionType: InspectionTypes };

export const fromRawAssetSchema = (
	rawSystemType: SystemTypeRaw,
	rawAssetSchema: AssetSchemaRaw,
): AssetSchema => {
	return new AssetSchemaImpl({
		...rawAssetSchema,
		SystemType: fromRawSystem(rawSystemType),
	});
};

export const getAssetSchemasRaw = async (
	filter?: AssetSchemaFilter,
): Promise<[SystemTypeRaw, AssetSchemaRaw][]> => {
	const systemTypes = await getSystemTypesRaw();
	const allAssetSchemas = [];
	if (!filter) {
		for (const systemType of systemTypes) {
			for (const assetType of systemType.AssetTypes) {
				const schema = await getAssetSchemaRaw({
					systemTypeId: systemType.SystemType,
					assetTypeId: assetType,
				});
				if (schema) {
					allAssetSchemas.push(schema);
				}
			}
		}
	} else if ('capability' in filter) {
		for (const systemType of systemTypes) {
			for (const assetTypeId of systemType.AssetTypes) {
				const schema = await getAssetSchemaRaw({
					systemTypeId: systemType.SystemType,
					assetTypeId: assetTypeId,
				});
				if (schema == undefined) {
					continue;
				}
				const [_, assetSchema] = schema;
				if (
					fromRawAssetSchema(systemType, assetSchema)?.hasCapability(
						filter.capability,
					)
				) {
					allAssetSchemas.push(schema);
				}
			}
		}
	} else if ('systemTypeId' in filter) {
		const systemType = systemTypes.find(
			s => s.SystemType === filter.systemTypeId,
		);

		if (systemType) {
			for (const assetTypeId of systemType.AssetTypes) {
				const schema = await getAssetSchemaRaw({
					systemTypeId: systemType.SystemType,
					assetTypeId: assetTypeId,
				});
				if (schema) {
					allAssetSchemas.push(schema);
				}
			}
		}
	} else if ('inspectionType' in filter) {
		for (const systemType of systemTypes) {
			for (const assetTypeId of systemType.AssetTypes) {
				const schema = await getAssetSchemaRaw({
					systemTypeId: systemType.SystemType,
					assetTypeId: assetTypeId,
				});
				if (schema == undefined) {
					continue;
				}
				const [_, assetSchema] = schema;
				if (
					fromRawAssetSchema(
						systemType,
						assetSchema,
					)?.hasInspectionType(filter.inspectionType)
				) {
					allAssetSchemas.push(schema);
				}
			}
		}
	}

	return allAssetSchemas;
};

export const getAssetSchemas = async (
	filter?: AssetSchemaFilter,
): Promise<AssetSchema[]> => {
	return (await getAssetSchemasRaw(filter)).map(([systemType, assetType]) =>
		fromRawAssetSchema(systemType, assetType),
	);
};

export interface GetAssetSchemaParams {
	systemTypeId?: string;
	assetTypeId: string;
}

export const getAssetSchemaRaw = async (
	getAssetSchemaParams: GetAssetSchemaParams,
): Promise<[SystemTypeRaw, AssetSchemaRaw] | undefined> => {
	let systemType;
	if (getAssetSchemaParams.systemTypeId) {
		systemType = await getSystemTypeRaw(getAssetSchemaParams.systemTypeId);
	} else {
		systemType = await getSystemTypeByAssetTypeRaw(
			getAssetSchemaParams.assetTypeId,
		);
	}

	if (!systemType) {
		return undefined;
	}

	const rawAssetSchema = await loadAssetFile(
		systemType.SystemType,
		getAssetSchemaParams.assetTypeId,
	);

	if (!rawAssetSchema) {
		return undefined;
	}

	return [systemType, rawAssetSchema];
};

export const getAssetSchema = async (
	getAssetSchemaParams: GetAssetSchemaParams,
): Promise<AssetSchema | undefined> => {
	const maybeSchema = await getAssetSchemaRaw(getAssetSchemaParams);

	if (!maybeSchema) {
		return undefined;
	}

	return fromRawAssetSchema(maybeSchema[0], maybeSchema[1]);
};
