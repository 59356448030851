import { FeatureItem } from '@innovyze/map-components';
import { SegmentInfo, UserDefined } from '@Types/asset.types';

import { TFunc } from '@Translations';
import { SAASObject } from '@Types/general.types';
import { getSegmentValues } from '@Utils/properties.utils';

export const getFieldMappings = (
	t: TFunc,
	fieldName: string,
): { [key: string]: string } | undefined => {
	switch (fieldName.toLowerCase()) {
		case 'material'.toLowerCase():
		case 'liningMaterial'.toLowerCase():
			return {
				BL: t('Bitumen (lining)'),
				CL: t('Cement Mortar (lining)'),
				CO: t('Concrete'),
				CS: t('Concrete Segments'),
				DI: t('Ductile Iron'),
				EP: t('Epoxy'),
				FC: t('Fibre Cement'),
				GI: t('Grey Cast Iron'),
				MAC: t('Masonry - Regular Courses'),
				MAR: t('Masonry - Randomly Coursed'),
				MX: t('Mixed Material Construction'),
				PF: t('Pitch Fibre'),
				PS: t('Polyester'),
				RC: t('Reinforced Concrete'),
				SPC: t('Sprayed Concrete'),
				ST: t('Steel'),
				VC: t('Vitrified Clay'),
				X: t('Unidentified Material'),
				XI: t('Unidentified Type of Iron or Steel'),
				XP: t('Unidentified Type of Plastics'),
				Z: t('Other'),
				ABS: t('Acrylonitrile Butadiene Styrene'),
				AC: t('Asbestos Cement'),
				BR: t('Brick'),
				CAS: t('Cast Iron'),
				CLC: t('Clay-lined Concrete Pipe'),
				CMP: t('Corrugated Metal Pipe'),
				CP: t('Concrete Pipe (non-reinforced)'),
				CSB: t('Concrete Segments (bolted)'),
				CSU: t('Concrete Segments (unbolted)'),
				CT: t('Clay Tile (not vitrified clay)'),
				DIP: t('Ductile Iron Pipe'),
				FRP: t('Fiberglass Reinforced Pipe'),
				OB: t('Pitch Fiber (Orangeburg)'),
				PCP: t('Polymer Concrete Pipe'),
				PCCP: t('Pre-stressed Concrete Cylinder Pipe'),
				PE: t('Polyethylene'),
				PP: t('Polypropylene'),
				PSC: t('Plastic/Steel Composite'),
				PVC: t('Polyvinyl Chloride'),
				RCP: t('Reinforced Concrete Pipe'),
				RPM: t('Reinforced Plastic Pipe (Truss Pipe)'),
				SB: t('Segmented Block'),
				SP: t('Steel Pipe'),
				VCP: t('Vitrified Clay Pipe'),
				WD: t('Wood'),
				XXX: t('Not Known'),
				ZZZ: t('Other'),
			};
		case 'liningType'.toLowerCase():
			return {
				M: t('Lining Inserted During Manufacture'),
				SP: t('Sprayed Lining/Segmented Pipe'),
				SEG: t('Segmental Linings'),
				DP: t('Lining with Discrete Pipes'),
				CP: t('Lining with Continuous Pipes'),
				CF: t('Close Fit Lining'),
				Z: t('Other'),
				CIP: t('Cured-In-Place Pipe'),
				FF: t('Fold and Form or Deform/Reform'),
				FP: t('Formed-In-Place Liner'),
				GP: t('Grout-In-Place Liner'),
				GRC: t('Glass Reinforced Cement'),
				N: t('None'),
				SC: t('Continuous Slip Liner'),
				SE: t('Sectional Slip Liner'),
				SL: t('Spray Liner'),
				SN: t('Segmented Panel'),
				SW: t('Spiral Wound'),
				XX: t('Not Known'),
				ZZ: t('Other'),
			};
		case 'pipeShape'.toLowerCase():
			return {
				A: t('Arched'),
				B: t('Barrel'),
				C: t('Circular'),
				E: t('Egg-shaped'),
				H: t('Horseshoe'),
				O: t('Oval'),
				R: t('Rectangular'),
				T: t('Trapezoidal'),
				U: t('U-shaped with Flat Top'),
				K: t('Kerb Block'),
				CSC: t('Circular with Smaller Channel'),
				RSC: t('Rectangular with Smaller Channel'),
				Z: t('Other'),
				S: t('Square'),
			};
		case 'systemType'.toLowerCase():
			return {
				CB: t('Combined Pipe'),
				DP: t('Dam Pipe'),
				FM: t('Force Main'),
				LG: t('Levee Gravity Pipe'),
				LP: t('Levee Pressure Pipe'),
				PR: t('Process Pipe'),
				SS: t('Sanitary Sewage Pipe'),
				SW: t('Stormwater Pipe'),
				XX: t('Not Known'),
				ZZ: t('Other'),
				F: t('Foul'),
				S: t('Surface'),
				C: t('Combined'),
				T: t('Trade Effluent'),
				O: t('Overflow'),
				W: t('Culverted Watercourse'),
				LD: t('Subsoil or Field Drainage'),
				U: t('Unspecified'),
				Z: t('Other'),
			};
		case 'accessAction'.toLowerCase():
			return {
				POL: t('Police Assistance'),
				PARK: t('No Parking'),
				KEYS: t('Keys Needed'),
				O: t('Other'),
			};
		case 'accessTime'.toLowerCase():
			return {
				A: t('Outside Working Hours'),
				B: t('Outside Normal Hours'),
				C: t('Outside Weekend Hours'),
				D: t('Other'),
			};
		case 'coverDuty'.toLowerCase():
			return {
				L: t('Light'),
				M: t('Medium'),
				H: t('Heavy'),
				U: t('Unspecified'),
			};
		case 'coverShape'.toLowerCase():
			return {
				S: t('Square'),
				R: t('Rectangular'),
				T: t('Triangular'),
				D: t('Double Triangle'),
				C: t('Circular'),
				O: t('Oval'),
				L: t('Clover Leaf'),
				HX: t('Hexagonal'),
				OC: t('Octagonal'),
				U: t('Unspecified'),
				Z: t('Other'),
			};
		case 'gasType'.toLowerCase():
			return {
				A: t('CH4'),
				B: t('H2S'),
				C: t('Explosive'),
				D: t('Other'),
			};
		case 'coneType'.toLowerCase():
			return {
				CC: t('Conical Centered'),
				CO: t('Conical Off Centered'),
				FT: t('Flat Top'),
				N: t('Not Present'),
				ZZ: t('Other'),
			};
		case 'inletType'.toLowerCase():
			return {
				ContCO: t('Continuous Curb-Opening'),
				ContG: t('Continuous Grate'),
				SagC: t('Sag Combination'),
				SagCO: t('Sag Curb-Opening'),
				SagG: t('Sag Grate'),
			};
		case 'locationCode'.toLowerCase():
			return {
				M: t('Continuous Curb-Opening'),
				H: t('Continuous Grate'),
				J: t('Sag Combination'),
				K: t('Sag Curb-Opening'),
				O: t('Sag Grate'),
				I: t('Ditch'),
				D: t('Easement/Right of Way'),
				P: t('Levee Pump Station'),
				N: t('Levee/Floodwall'),
				C: t('Local Rural Streets'),
				Z: t('Other'),
				G: t('Parking Lot'),
				A: t('Primary Major Arterial Roads'),
				L: t('Railway'),
				B: t('Secondary Roads'),
				F: t('Sidewalk'),
				E: t('Woods'),
				Y: t('Yard'),
			};
		case 'status'.toLowerCase():
			return {
				PU: t('Public'),
				PR: t('Private'),
				24: t('Section 24'),
				HD: t('Highway Drain'),
				WC: t('Watercourse'),
				TR: t('Trunk'),
				17: t('Section 17'),
				18: t('Section 18'),
				AB: t('Abandoned'),
				TC: t('To Be Constructed'),
				HC: t('Housing Committee'),
				U: t('Unspecified'),
			};
		case 'stepMaterial'.toLowerCase():
			return {
				AL: t('Aluminium'),
				GI: t('Galvanised Iron'),
				IR: t('Iron/Steel'),
				PL: t('Plastic'),
				PM: t('Metal Encapsulated in Plastic'),
				SS: t('Stainless Steel'),
				Z: t('Other'),
			};
		case 'owner'.toLowerCase():
			return {
				PR: t('Private'),
				PU: t('Public'),
				X: t('Not Known'),
			};
		case 'security'.toLowerCase():
			return {
				A: t('Vulnerable'),
				B: t('Moderate Risk'),
				C: t('Secure'),
			};
		default:
			return;
	}
};

export const getValueFromShortCode = (
	t: TFunc,
	fieldName: string,
	shortCode: string,
): string => {
	const valueMap = getFieldMappings(t, fieldName);
	const shortCodeValue = valueMap?.[shortCode];
	return shortCode + (shortCodeValue ? ` - ${shortCodeValue}` : '');
};

export const extractValue = (
	value: string | SAASObject,
): string | undefined => {
	if (typeof value === 'object') {
		if (value?.type === 'date') {
			// Years are also just marked as date :(
			if (value?.displayValue.length === 4) return value?.displayValue;
			else return value?.value.toString();
		} else if (value?.type === 'datetime' || value?.type === 'currency') {
			return value?.value.toString();
		}
		return value?.displayValue;
	}
	return value != null ? `${value}` : undefined;
};

export const extractFieldType = (value: string | SAASObject): string => {
	return typeof value === 'object' && value?.type !== 'time'
		? value.type
		: 'string';
};

export const getValue = (
	t: TFunc,
	item: FeatureItem | FeatureItem['risk'],
	prop: string,
	unit?: string,
): string | undefined => {
	const itemValue =
		(item?.segments as FeatureItem[])?.[0][prop] || item?.[prop];
	const value = extractValue(itemValue as SAASObject);
	if (value == null) return undefined;
	return (
		getValueFromShortCode(t, prop, value) +
		(unit && unit !== '$' ? unit : '')
	);
};

export const mapSegments = (
	segments: SegmentInfo[],
	t: TFunc,
): SegmentInfo[] => {
	return segments.map(segment =>
		getSegmentValues(t, segment, getFieldMappings),
	);
};

export const mapUserTables = (userDefined: UserDefined) => {
	return Object.entries(userDefined).map(([section, fieldsKVP]) => {
		const fields = Object.entries(fieldsKVP)
			.map(([name, value]) => ({
				name,
				value,
			}))
			.filter(({ name }) => name !== 'uploadId' && name !== 'tableName');
		return { section, fields };
	});
};
